import React, { Fragment, useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useRouteMatch, useParams, useHistory } from 'react-router-dom';
import ErrorMessage from '../../components/errormessage';
import { formatDate } from '../../utils/helpers';
import PackageTable from '../../components/super/packages';
import AddPackage from '../../components/super/add-package';
import AddManager from '../../components/super/add-manager';
import ContentGroupAccess from '../../components/super/content-group-access';
import Clipboard from 'react-clipboard.js';
import moment from 'moment/moment';
import { enforceSlash } from '../../utils/helpers';
import ThemedSpinner from '../../components/themed/ThemedSpinner';
import Countdown from 'react-countdown';
import SettingsTable from '../../components/super/settings';
import AddSetting from '../../components/super/add-setting';
import SendTestEmail from '../../components/super/send-test-email';
import { useStores } from '../../hooks/use-stores';
import SimpleTable from '../../components/super/SimpleTable';
//import SetExternalIdModal from '../../components/super/set-external-id-modal';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import EditIcon from '@material-ui/icons/Edit';
//import SettingsIcon from '@material-ui/icons/Settings';
import ReplayIcon from '@material-ui/icons/Replay';
import { TextField } from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import { Link } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import DeleteIcon from '@material-ui/icons/Delete';
import PersonIcon from '@material-ui/icons/Person';
import SendIcon from '@material-ui/icons/Send';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import TimerIcon from '@material-ui/icons/Timer';

function CompanyView() {
    const TOKEN_TIMER = 1 * 60 * 1000;
    let { companyId } = useParams();
    const { superStore, commonStore, authStore } = useStores();
    let history = useHistory();
    const [views, setViews] = useState(100);
    const [notes, setNotes] = useState('');
    //const [showingExternalIdModal, setShowingExternalIdModal] = useState(false);
    const {
        loadingCompany,
        creatingAclLine,
        removingAclLines,
        addingPackage,
        addingManager,
        addingCompanySetting,
        removingPackages,
        removingManagers,
        removingCompanySettings,
        loadingAclRulesForPrincipal,
        savingPPV,
        savingPPVApiKey,
        freemiums,
        aclGroups,
        aclRulesForPrincipal,
        managerTokensIndex,
        sendingTestEmail,
        //savingCompany,
        editingCompany: company,
        editingPartner: partner,
        error,
        subscriptions,
    } = superStore;
    let match = useRouteMatch();

    const loadData = async () => {
        await superStore.listFreemiums();
        await superStore.listSubscriptions(companyId);
        await superStore.loadCompany(companyId);
        await superStore.loadAclGroups();
    };

    const loadAclRulesForCompany = async () => {
        if (company && company.company_uid) {
            await superStore.loadAclRulesForPrincipal(company.company_uid);
        }
    };

    const loadPartner = async () => {
        if (company && company.partnerId) {
            await superStore.loadPartner(company.partnerId);
        }
    };

    const deleteAclRule = async (id) => {
        await superStore.removeAclLine(id);
        await loadAclRulesForCompany();
    };

    const createAclRule = async (principal_unique_id, unique_id, rule = 1) => {
        await superStore.createAclLine({
            rule,
            principal_unique_id,
            object_unique_id: unique_id,
        });
        await loadAclRulesForCompany();
    };

    useEffect(() => {
        loadData();
    }, [companyId]);

    useEffect(() => {
        loadAclRulesForCompany();
        loadPartner();
    }, [company]);

    /*const updateExternalId = async (externalId) => {
        await superStore.update(company.company_id, { externalId });
        setShowingExternalIdModal(false);
    };*/

    const sendTestEmail = async (email, type) => {
        const data = { email, type };
        if (
            (await superStore.sendCompanyTestEmail(companyId, data)) &&
            !sendingTestEmail
        )
            commonStore.success('Email sent!');
    };

    const removeSetting = (settingId) => {
        superStore.removeCompanySetting(companyId, settingId);
    };

    const addSetting = (name, value) => {
        const data = { name, value };
        superStore.addCompanySetting(companyId, data);
    };

    const onChangeSetting = (id, value) => {
        superStore.updateCompanySetting(companyId, id, value);
    };

    const addPackage = (
        packageUsers,
        valid_from,
        valid_to,
        packageTrial,
        freemiumCode,
        subscription
    ) => {
        const data = {};
        data.users = packageUsers;
        data.validFrom = valid_from;
        data.validTo = valid_to;
        data.trial = packageTrial;
        data.company_id = companyId;
        data.freemiumCode = freemiumCode;
        data.subscription = subscription;
        superStore.addPackage(companyId, data);
    };

    const removePackage = (packageId) => {
        superStore.removePackage(companyId, packageId);
    };

    /*const onChangePackageType = (packageId, type) => {
        superStore.updatePackage(companyId, packageId, { full_package: type });
    };*/

    const addManager = (name, email, role, sendWelcome) => {
        superStore.addManager(companyId, { name, email, role, sendWelcome });
    };

    const removeManager = (email) => {
        superStore.removeManager(companyId, email);
    };

    const createPPV = () => {
        superStore.createPPV(companyId);
    };

    const newPPVApiKey = () => {
        superStore.newPPVApiKey(companyId);
    };

    const impersonate = (manager) => {
        if (manager.timer) {
            clearTimeout(manager.timer);
            manager.timer = null;
        }
        let link = superStore.impersonate(manager.email, companyId);
        if (link) {
            manager.timer = setTimeout(() => {
                manager.timer = null;
                superStore.removeToken(manager.email);
            }, TOKEN_TIMER);
        }
    };

    const sendLoginInfo = async (manager) => {
        commonStore.showConfirm(
            `Do you want to send an email to ${manager.email} with a login link for the LMS?`,
            'Send email',
            'Send login email',
            async () => {
                const result = await authStore.signin(
                    manager.email,
                    companyId,
                    null,
                    null,
                    '/',
                    'login-info'
                );
                if (result) commonStore.success('Login email sent!');
            }
        );
    };

    const deleteCompany = async () => {
        commonStore.showDeleteWithInputConfirm(
            <p>
                Are you sure you want to delete the company{' '}
                <strong>{company.company_name}</strong>?<br />
                This action will delete:
                <br />
                *All training programs
                <br />
                *All assessments
                <br />
                *All employee lists
                <br />
                *All employee progress
                <br />
                Along with any other data related to this company
            </p>,
            'Delete company and all data',
            'Delete company',
            'delete',
            async () => {
                const result = await superStore.deleteCompany(
                    company.partnerId,
                    companyId
                );
                if (result) {
                    history.push('/companies');
                    commonStore.success('Company was deleted');
                }
            }
        );
    };

    const addViews = () => {
        superStore.addViews(companyId, Number(views) || 0, notes);
    };

    const removeViews = () => {
        superStore.addViews(companyId, -1 * (Number(views) || 0), notes);
    };

    const columns = [
        {
            Header: 'Created',
            accessor: 'created',
            Cell: (x) => formatDate(x.value),
        },
        {
            Header: 'Email',
            accessor: 'email',
        },
        {
            id: 'send',
            accessor: (x) => (
                <Grid container direction="row" alignItems="center" spacing={1}>
                    <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                sendLoginInfo(x);
                            }}
                            startIcon={<SendIcon />}
                        >
                            Send login info
                        </Button>
                    </Grid>
                </Grid>
            ),
        },
        {
            Header: 'Name',
            accessor: 'name',
        },
        {
            Header: 'Role',
            accessor: 'role_name',
        },
        {
            id: 'impersonate',
            accessor: (x) => (
                <Grid container direction="row" alignItems="center" spacing={1}>
                    <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                impersonate(x);
                            }}
                            startIcon={<PersonIcon />}
                        >
                            Impersonate
                        </Button>
                    </Grid>
                    {managerTokensIndex[x.email] &&
                        managerTokensIndex[x.email].access_link && (
                            <Fragment>
                                <Grid item>
                                    <Clipboard
                                        className="copy-to-clipboard-link"
                                        data-clipboard-text={
                                            managerTokensIndex[x.email]
                                                .access_link
                                        }
                                    >
                                        <Button
                                            variant="contained"
                                            startIcon={<FileCopyIcon />}
                                        >
                                            Copy sign in link
                                        </Button>
                                    </Clipboard>
                                </Grid>
                                <Grid item>
                                    <TimerIcon color="primary" />
                                </Grid>
                                <Grid item>
                                    <Countdown
                                        date={Date.now() + TOKEN_TIMER}
                                        renderer={(props) => (
                                            <Fragment>
                                                {props.minutes
                                                    .toString()
                                                    .padStart(2, '0') +
                                                    ':' +
                                                    props.seconds
                                                        .toString()
                                                        .padStart(2, '0')}
                                            </Fragment>
                                        )}
                                    />
                                </Grid>
                            </Fragment>
                        )}
                </Grid>
            ),
        },
        {
            id: 'deleteManager',
            accessor: (x) =>
                removingManagers.includes(x.email) ? (
                    <ThemedSpinner />
                ) : (
                    <IconButton
                        variant="outlined"
                        color="secondary"
                        onClick={() => {
                            removeManager(x.email);
                        }}
                    >
                        <DeleteIcon fontSize="large" />
                    </IconButton>
                ),
        },
    ];

    return loadingCompany && !company ? (
        <ThemedSpinner />
    ) : (
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            align="center"
            spacing={2}
        >
            {company && (
                <Fragment>
                    {error && (
                        <Grid item xs={12}>
                            <ErrorMessage error={error} errorType="error" />
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <Box mt={4}>
                            <Typography variant="h2" gutterBottom>
                                Company{company && `: ${company.company_name}`}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={6} md={2}>
                        <Button
                            variant="contained"
                            color="primary"
                            href={`${enforceSlash(match.url)}edit`}
                            startIcon={<EditIcon />}
                        >
                            Edit
                        </Button>
                    </Grid>
                    <Grid item xs={6} md={2}>
                        <Button
                            variant="contained"
                            color="default"
                            href={`${
                                import.meta.env.REACT_APP_LMS_URL
                            }/super/companies/${companyId}/automations`}
                        >
                            View Automations
                        </Button>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="h4" paragraph>
                            Partner
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={2}>
                        {partner && `${partner.name} [${partner.id}]`}
                    </Grid>
                    <Grid item xs={6} md={2}>
                        <Button
                            variant="contained"
                            color="primary"
                            href={partner && `/partners/${partner.id}`}
                        >
                            View partner
                        </Button>
                    </Grid>

                    {/*<Grid item xs={12}>
                        <Typography variant="h4" gutterBottom>
                            External ID
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={2}>
                        {company.external_id
                            ? company.external_id
                            : 'External ID not yet set'}
                    </Grid>
                    <Grid item xs={6} md={2}>
                        <Button
                            variant="contained"
                            color="default"
                            startIcon={<SettingsIcon />}
                            onClick={() => setShowingExternalIdModal(true)}
                        >
                            Set external ID
                        </Button>
                    </Grid>

                    <SetExternalIdModal
                        onClose={() => setShowingExternalIdModal(false)}
                        companyName={company.company_name}
                        externalId={company.external_id}
                        updateExternalId={updateExternalId}
                        showLoader={savingCompany}
                        open={showingExternalIdModal}
                        />*/}

                    <Grid item xs={12}>
                        <Typography variant="h4" gutterBottom>
                            Emails
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <SendTestEmail
                            sendEmail={sendTestEmail}
                            sendingEmail={sendingTestEmail}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="h4" gutterBottom>
                            Settings
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            color="primary"
                            href={`/companies/settings`}
                        >
                            View available settings
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <AddSetting
                            saveSetting={addSetting}
                            addingSetting={addingCompanySetting}
                        />
                    </Grid>
                    {company.settings && company.settings.length > 0 && (
                        <Grid item xs={10} md={6}>
                            <SettingsTable
                                settings={company.settings}
                                removeSetting={removeSetting}
                                removingSettings={removingCompanySettings}
                                onChangeSettingValue={onChangeSetting}
                            />
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <Box mt={2}>
                            <Typography variant="h5" gutterBottom>
                                PPV package
                            </Typography>
                        </Box>
                    </Grid>
                    {company && !company.ppv_package ? (
                        savingPPV ? (
                            <ThemedSpinner />
                        ) : (
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={createPPV}
                                startIcon={<AddIcon />}
                            >
                                Create a PPV package
                            </Button>
                        )
                    ) : (
                        <Fragment>
                            <Grid item xs={12}>
                                <Grid
                                    container
                                    direction="row"
                                    justify="center"
                                    alignItems="center"
                                    spacing={2}
                                >
                                    <Grid item>
                                        <Typography
                                            display="inline"
                                            color="primary"
                                        >
                                            Created{' '}
                                            {moment(
                                                company.ppv_package.created
                                            ).format('LL')}
                                        </Typography>
                                        <Typography display="inline">
                                            ,{' '}
                                            {moment(
                                                company.ppv_package.created
                                            ).fromNow()}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography>ApiKey: </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Clipboard
                                            className="copy-to-clipboard-link"
                                            data-clipboard-text={
                                                company.ppv_package.apiKey
                                            }
                                        >
                                            <Button
                                                variant="contained"
                                                startIcon={<FileCopyIcon />}
                                            >
                                                {company.ppv_package.apiKey}
                                            </Button>
                                        </Clipboard>
                                    </Grid>
                                    <Grid item>
                                        {savingPPVApiKey ? (
                                            <ThemedSpinner />
                                        ) : (
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={newPPVApiKey}
                                                startIcon={<ReplayIcon />}
                                            >
                                                Regenerate
                                            </Button>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid
                                    container
                                    direction="row"
                                    justify="center"
                                    align="center"
                                    spacing={2}
                                >
                                    <Grid item>
                                        <Typography
                                            variant="body1"
                                            gutterBottom
                                        >
                                            Views Purchased:{' '}
                                            <strong>
                                                {
                                                    company.ppv_package
                                                        .viewsPurchased
                                                }
                                            </strong>
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography
                                            variant="body1"
                                            gutterBottom
                                        >
                                            Views used:{' '}
                                            <strong>
                                                {company.ppv_package.viewsUsed}
                                            </strong>{' '}
                                            (
                                            {isNaN(
                                                (company.ppv_package.viewsUsed /
                                                    company.ppv_package
                                                        .viewsPurchased) *
                                                    100
                                            )
                                                ? 0
                                                : (
                                                      (company.ppv_package
                                                          .viewsUsed /
                                                          company.ppv_package
                                                              .viewsPurchased) *
                                                      100
                                                  ).toFixed(2)}
                                            %)
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid
                                    container
                                    direction="row"
                                    justify="center"
                                    alignItems="center"
                                    spacing={2}
                                >
                                    <Grid item>
                                        <TextField
                                            type="number"
                                            name="views"
                                            label="Views"
                                            id="views"
                                            onChange={(e) => {
                                                setViews(e.target.value);
                                            }}
                                            value={views}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item>
                                        <TextField
                                            type="text"
                                            name="views"
                                            id="notes"
                                            onChange={(e) => {
                                                setNotes(e.target.value);
                                            }}
                                            value={notes}
                                            label="Notes"
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item>
                                        {savingPPVApiKey ? (
                                            <ThemedSpinner />
                                        ) : (
                                            <Fragment>
                                                <IconButton
                                                    variant="outlined"
                                                    color="primary"
                                                    onClick={addViews}
                                                >
                                                    <AddIcon fontSize="large" />
                                                </IconButton>
                                                <IconButton
                                                    variant="outlined"
                                                    color="secondary"
                                                    onClick={removeViews}
                                                >
                                                    <RemoveIcon fontSize="large" />
                                                </IconButton>
                                            </Fragment>
                                        )}
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid
                                        container
                                        direction="row"
                                        justify="center"
                                        alignItems="center"
                                        spacing={2}
                                    >
                                        <Grid item>
                                            <Link
                                                color="primary"
                                                href={`${enforceSlash(
                                                    match.url
                                                )}ppv_transactions`}
                                                variant="body1"
                                            >
                                                Transactions
                                            </Link>
                                        </Grid>
                                        <Grid item>
                                            <Link
                                                color="primary"
                                                href={`${enforceSlash(
                                                    match.url
                                                )}ppv_log`}
                                                variant="body1"
                                            >
                                                Views Usage
                                            </Link>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Fragment>
                    )}
                    <Grid item xs={12}>
                        <Box mt={2}>
                            <Typography variant="h5" gutterBottom>
                                Subscription packages
                            </Typography>
                        </Box>
                    </Grid>
                    {company &&
                        company.packages &&
                        company.packages.length > 0 && (
                            <Grid item xs={12}>
                                <PackageTable
                                    company={company}
                                    packages={company.packages}
                                    removePackage={removePackage}
                                    removingPackages={removingPackages}
                                    editPath={enforceSlash(match.url)}
                                />
                            </Grid>
                        )}
                    {company &&
                        company.packages &&
                        !company.packages.find((p) => p.valid === 1) && (
                            <Grid item xs={12}>
                                <AddPackage
                                    savePackage={addPackage}
                                    addingPackage={addingPackage}
                                    freemiums={freemiums}
                                    subscriptions={subscriptions}
                                    partnerId={company.partnerId}
                                />
                            </Grid>
                        )}

                    <Grid item xs={12}>
                        <Typography variant="h5">Managers</Typography>
                    </Grid>
                    {company.managers && (
                        <Grid item xs={12}>
                            <SimpleTable
                                columns={columns}
                                data={company.managers}
                                needsFilter={company.managers.length > 5}
                            />
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <AddManager
                            addingManager={addingManager}
                            addManager={addManager}
                        />
                    </Grid>

                    {company && aclGroups.length > 0 && (
                        <>
                            <Grid item xs={12}>
                                <Typography variant="h5">
                                    Content Group Access
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <ContentGroupAccess
                                    aclGroups={aclGroups}
                                    aclRules={aclRulesForPrincipal}
                                    principalUniqueId={company.company_uid}
                                    addToGroup={createAclRule}
                                    removeFromGroup={deleteAclRule}
                                    showLoader={
                                        creatingAclLine ||
                                        removingAclLines.length > 0 ||
                                        loadingAclRulesForPrincipal
                                    }
                                />
                            </Grid>
                        </>
                    )}
                    <Grid item xs={6} md={2}>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => {
                                deleteCompany();
                            }}
                            startIcon={<DeleteIcon />}
                        >
                            Delete company and all data
                        </Button>
                    </Grid>
                </Fragment>
            )}
        </Grid>
    );
}

export default observer(CompanyView);
