import React, { useEffect } from 'react';
import Waiting from '../components/waiting';
import { querystring } from '../utils/helpers';
import { useStores } from '../hooks/use-stores';
import { useHistory, useRouteMatch } from 'react-router';
import { observer } from 'mobx-react';

function EmailSignIn() {
    const { authStore } = useStores();
    let match = useRouteMatch();
    let history = useHistory();

    useEffect(() => {
        let { token } = match.params;
        authStore.signinFromEmail(token).then((result) => {
            let redirect = querystring('redirect') || '/';
            if (!redirect.startsWith('/')) redirect = `/${redirect}`;
            history.push(result ? redirect : '/auth/error/email');
        });
    }, []);

    return <Waiting waitingActive={true} fullScreen={true} />;
}

export default observer(EmailSignIn);
