import React, { Fragment, useEffect } from 'react';
import { observer } from 'mobx-react';
import ErrorMessage from '../../components/errormessage';
import ThemedSpinner from '../../components/themed/ThemedSpinner';
import { useStores } from '../../hooks/use-stores';
import ACLEditor from './ACLEditor';
import { Link, useParams, useRouteMatch } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import SimpleTable from '../../components/super/SimpleTable';
import EditIcon from '@material-ui/icons/Edit';
import Button from '@material-ui/core/Button';
import { enforceSlash } from '../../utils/helpers';

function Category() {
    const { superStore } = useStores();
    let { categoryId } = useParams();
    const {
        loadingCategory,
        editingCategory,
        categoriesErrorError,
        editingCategorySubjects,
    } = superStore;

    const match = useRouteMatch();

    const loadData = async () => {
        await superStore.loadCategory(categoryId);
        await superStore.loadCategorySubjects(categoryId);
    };

    useEffect(() => {
        loadData();
    }, [categoryId]);

    const columns = React.useMemo(
        () => [
            {
                Header: 'ID',
                accessor: 'subject_id',
                Cell: (x) => (
                    <Link to={`/content/subjects/${x.value}`}>{x.value}</Link>
                ),
            },
            {
                Header: 'Title',
                accessor: 'title',
                Cell: (x) => (
                    <Link to={`/content/subjects/${x.row.original.subject_id}`}>
                        {x.value}
                    </Link>
                ),
            },
            {
                Header: 'Episode number',
                accessor: 'episode_number',
            },
        ],
        []
    );

    return (
        <Grid
            container
            direction="row"
            justify="center"
            align="center"
            spacing={2}
        >
            {loadingCategory || !editingCategory ? (
                <ThemedSpinner />
            ) : (
                <Fragment>
                    {categoriesErrorError && (
                        <Grid item>
                            <ErrorMessage error={categoriesErrorError} />
                        </Grid>
                    )}
                    {editingCategory && (
                        <Fragment>
                            <Grid item xs={12}>
                                <Box mt={4}>
                                    <Typography variant="h2">
                                        Category{`: ${editingCategory.name}`}
                                    </Typography>
                                </Box>
                            </Grid>
                            {!(
                                editingCategorySubjects &&
                                editingCategorySubjects.length > 0
                            ) ? (
                                <ThemedSpinner />
                            ) : (
                                <Fragment>
                                    <Grid item xs={12}>
                                        <Typography variant="h4" gutterBottom>
                                            Subjects
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            href={`${enforceSlash(
                                                match.url
                                            )}subjects`}
                                            startIcon={<EditIcon />}
                                        >
                                            Edit Subjects
                                        </Button>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <SimpleTable
                                            columns={columns}
                                            data={editingCategorySubjects}
                                            needsFilter={true}
                                        />
                                    </Grid>
                                </Fragment>
                            )}
                            <Grid item xs={12}>
                                <Box mt={4}>
                                    <Typography variant="h4" gutterBottom>
                                        Access control
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <ACLEditor
                                    acl={editingCategory.acl}
                                    unique_id={editingCategory.unique_id}
                                />
                            </Grid>
                        </Fragment>
                    )}
                </Fragment>
            )}
        </Grid>
    );
}

export default observer(Category);
