import React, { useState } from 'react';
import { observer } from 'mobx-react';
import ThemedSpinner from '../themed/ThemedSpinner';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { TextField } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import AddIcon from '@material-ui/icons/Add';
import ErrorMessage from '../errormessage';

function AddSubscription({ addingSubscription, addSubscription }) {
    const [code, setCode] = useState('');
    const [codeError, setCodeError] = useState('');
    const [type, setType] = useState(0);
    const [trainingSubjects, setTrainingSubjects] = useState('');
    const [assessmentQuestions, setAssessmentQuestions] = useState('');
    const [integrations, setIntegrations] = useState('');
    const [maxUsers, setMaxUsers] = useState(0);
    const [error, setError] = useState('');

    const changeCode = (e) => {
        setCode(e.target.value.toLowerCase().replace(' ', '-'));
        setError('');
        if (code.length < 3) {
            // too short
            setCodeError('Name needs to be at least 4 characters.');
        } else {
            setCodeError('');
        }
    };

    const save = () => {
        if (!codeError)
            addSubscription(
                code,
                type,
                trainingSubjects,
                assessmentQuestions,
                integrations,
                maxUsers
            );
    };

    return (
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={2}
        >
            <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                    Add new subscription plan
                </Typography>
            </Grid>
            <Grid item>
                <TextField
                    variant="filled"
                    name="subscriptionCode"
                    label="Code"
                    value={code}
                    onChange={(e) => changeCode(e)}
                    type="text"
                />
            </Grid>
            <Grid item>
                <FormControl variant="filled">
                    <InputLabel>Type</InputLabel>
                    <Select
                        variant="filled"
                        name="type"
                        value={type}
                        onChange={(e) => {
                            setType(e.target.value);
                        }}
                    >
                        <MenuItem value="0">Free/Trial</MenuItem>
                        <MenuItem value="1">Training</MenuItem>
                        <MenuItem value="2">Assessment</MenuItem>
                        <MenuItem value="3">Complete</MenuItem>
                        <MenuItem value="99">Enterprize</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item>
                <TextField
                    variant="filled"
                    name="trainingSubjects"
                    label="Training subjects"
                    value={trainingSubjects}
                    onChange={(e) => setTrainingSubjects(e.target.value)}
                    type="text"
                    placeholder="Type 'all' for all subjects"
                />
            </Grid>
            <Grid item>
                <TextField
                    variant="filled"
                    name="assessmentQuestions"
                    label="Assessment questions"
                    value={assessmentQuestions}
                    onChange={(e) => setAssessmentQuestions(e.target.value)}
                    type="text"
                    placeholder="Type 'all' for all questions"
                />
            </Grid>
            <Grid item>
                <TextField
                    variant="filled"
                    name="integrations"
                    label="Integrations"
                    value={integrations}
                    onChange={(e) => setIntegrations(e.target.value)}
                    type="text"
                    placeholder="Available: ad,google,teams,slack,sso"
                />
            </Grid>
            <Grid item>
                <TextField
                    variant="filled"
                    name="maxUsers"
                    label="Max users"
                    value={maxUsers}
                    onChange={(e) => setMaxUsers(e.target.value)}
                    type="number"
                />
            </Grid>
            <Grid item>
                {addingSubscription ? (
                    <ThemedSpinner />
                ) : (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={save}
                        startIcon={<AddIcon />}
                        type="submit"
                    >
                        Create
                    </Button>
                )}
            </Grid>
            {error && (
                <Grid item>
                    <ErrorMessage error={error} />
                </Grid>
            )}
        </Grid>
    );
}

export default observer(AddSubscription);
