import React, { useState } from 'react';
import ThemedSpinner from '../themed/ThemedSpinner';
import Button from '@material-ui/core/Button';
import { TextField } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

function AddSetting({
    saveSetting,
    addingSetting,
    nameField = 'Name',
    valueField = 'Value',
}) {
    const [name, setName] = useState('');
    const [value, setValue] = useState('');

    const save = async () => {
        let inputOk = true;
        if (inputOk) {
            saveSetting(name, value);
        }
    };

    return (
        <Grid
            container
            direction="column"
            justify="center"
            align="center"
            spacing={2}
        >
            <Grid item>
                <Typography variant="h5" gutterBottom>
                    Add new setting
                </Typography>
            </Grid>
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                spacing={2}
            >
                <Grid item>
                    <TextField
                        type="text"
                        label={nameField}
                        name={nameField}
                        className="bigInput"
                        value={name}
                        variant="filled"
                        onChange={(e) => {
                            setName(e.target.value);
                        }}
                    />
                </Grid>
                <Grid item>
                    <TextField
                        type="text"
                        label={valueField}
                        name={valueField}
                        className="bigInput"
                        value={value}
                        variant="filled"
                        onChange={(e) => {
                            setValue(e.target.value);
                        }}
                    />
                </Grid>
                <Grid item>
                    {addingSetting ? (
                        <ThemedSpinner />
                    ) : (
                        <Button
                            onClick={save}
                            color="primary"
                            variant="contained"
                            startIcon={<AddIcon />}
                        >
                            Add
                        </Button>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
}

export default AddSetting;
