import React, { Fragment, useEffect } from 'react';
import { observer } from 'mobx-react';
import ErrorMessage from '../../components/errormessage';
import ThemedSpinner from '../../components/themed/ThemedSpinner';
import { useStores } from '../../hooks/use-stores';
import { useParams } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import AddRemoveList from '../../components/super/add-remove-list';

function CategorySubjects() {
    const { superStore } = useStores();
    let { categoryId } = useParams();
    const {
        loadingCategory,
        editingCategory,
        categoriesErrorError,
        loadingSubjects,
        subjects,
        //loadCategorySubjects,
        editingCategorySubjects,
        addCategorySubject,
        deleteCategorySubject,
    } = superStore;

    const loadData = async () => {
        await superStore.loadSubjects();
        await superStore.loadCategory(categoryId);
        await superStore.loadCategorySubjects(categoryId);
    };

    useEffect(() => {
        loadData();
    }, [categoryId]);

    /*const mapSubjects = async (subjects) => {
        return await subjects.map((item) => {
            item.name = `[${item.episode_number}] ${item.title}`;
        });
    };*/

    return (
        <Grid
            container
            direction="row"
            justify="center"
            align="center"
            spacing={2}
        >
            {loadingCategory || loadingSubjects || !editingCategorySubjects ? (
                <ThemedSpinner />
            ) : (
                <Fragment>
                    {categoriesErrorError && (
                        <Grid item>
                            <ErrorMessage error={categoriesErrorError} />
                        </Grid>
                    )}
                    {editingCategory && editingCategorySubjects && (
                        <Fragment>
                            <Grid item xs={12}>
                                <Box mt={4}>
                                    <Typography variant="h2">
                                        Category{`: ${editingCategory.name}`}
                                    </Typography>
                                </Box>
                            </Grid>
                            {!(
                                editingCategorySubjects &&
                                editingCategorySubjects.length > 0
                            ) ? (
                                <ThemedSpinner />
                            ) : (
                                <Fragment>
                                    <Grid item xs={12}>
                                        <Typography variant="h4" gutterBottom>
                                            Subjects
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <AddRemoveList
                                            addItem={async (subjectId) => {
                                                await addCategorySubject(
                                                    categoryId,
                                                    subjectId
                                                );
                                                await superStore.loadCategorySubjects(
                                                    categoryId
                                                );
                                            }}
                                            removeItem={async (subjectId) => {
                                                await deleteCategorySubject(
                                                    categoryId,
                                                    subjectId
                                                );
                                                await superStore.loadCategorySubjects(
                                                    categoryId
                                                );
                                            }}
                                            itemList={subjects}
                                            activeItemList={
                                                editingCategorySubjects
                                            }
                                            idColumn={'subject_id'}
                                            nameColumn={'episode_number'}
                                        />
                                    </Grid>
                                </Fragment>
                            )}
                        </Fragment>
                    )}
                </Fragment>
            )}
        </Grid>
    );
}

export default observer(CategorySubjects);
