import React, { useState } from 'react';
import Autosuggest from 'react-autosuggest';
import services from '../../services';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
import SearchIcon from '@material-ui/icons/Search';
import {makeStyles} from "@material-ui/core";

function getSuggestionValue(suggestion) {
    return suggestion.name;
}

function renderSuggestion(suggestion) {
    return (
        <MenuItem component="div">
            <div>
                <span>
                    {suggestion.type === 'company' ? 'Company: ' : 'Partner: '}[
                    {suggestion.id}] {suggestion.name}
                </span>
            </div>
        </MenuItem>
    );
}

function renderSuggestionsContainer(options) {
    const { containerProps, children } = options;

    return (
        <Paper {...containerProps} square>
            {children}
        </Paper>
    );
}

function renderInput(inputProps) {
    const { ref, ...other } = inputProps;

    return (
        <TextField
            label="Partner/company"
            color="primary"
            variant="outlined"
            InputProps={{
                inputRef: ref,
                ...other,
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon color="primary" />
                    </InputAdornment>
                ),
            }}
        />
    );
}

const useStyles = makeStyles( (theme) => ({
    suggestionsContainerOpen: {
        position: 'absolute',
        zIndex: 1,
        marginTop: theme.spacing.unit,
        left: 0,
        right: 0,
        bottom: 0,
    },
    suggestion: {
        display: 'block',
    },
    suggestionsList: {
        margin: 0,
        padding: 0,
        listStyleType: 'none',
    },
}));

function PrincipalChooser({ value, onChange, onSuggestionSelected }) {
    const classes = useStyles();
    const [suggestions, setSuggestions] = useState([]);

    const loadSuggestions = async (value) => {
        let values = [];
        if (value.length > 2) {
            values = await services.ACL.findPrincipal(value);
        }
        setSuggestions(values);
    };

    const onSuggestionsFetchRequested = ({ value }) => {
        loadSuggestions(value);
    };

    const onSuggestionsClearRequested = () => {
        setSuggestions([]);
    };

    return (
        <Autosuggest
            theme={{
                suggestionsContainerOpen: classes.suggestionsContainerOpen,
                suggestionsList: classes.suggestionsList,
                suggestion: classes.suggestion,
            }}
            suggestions={suggestions}
            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
            onSuggestionsClearRequested={onSuggestionsClearRequested}
            onSuggestionSelected={onSuggestionSelected}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
            renderSuggestionsContainer={renderSuggestionsContainer}
            renderInputComponent={renderInput}
            inputProps={{
                value: value || '',
                onChange: onChange,
            }}
        />
    );
}

export default PrincipalChooser;
