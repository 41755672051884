import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import ErrorMessage from '../../components/errormessage';
import CompanyForm from '../../components/super/companyEdit';
import ThemedSpinner from '../../components/themed/ThemedSpinner';
import { useStores } from '../../hooks/use-stores';
import { observer } from 'mobx-react';
import { useParams, useRouteMatch } from 'react-router';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

function CompanyEdit() {
    let { companyId } = useParams();
    const { superStore } = useStores();
    let history = useHistory();
    const { savingCompany, error, companyEditForm: companyForm } = superStore;
    let match = useRouteMatch();

    const loadData = async () => {
        if (companyId) {
            await superStore.loadCompany(companyId);
        }
    };

    useEffect(() => {
        superStore.startEditCompany(companyId, (responseCompanyId) => {
            if (responseCompanyId)
                return history.push(`/companies/${responseCompanyId}`);
            else return history.push('/companies');
        });
        loadData();
    }, [companyId]);

    return (
        <Grid
            container
            direction="row"
            justify="center"
            align="center"
            spacing={2}
        >
            <Grid item xs={12}>
                <Box mt={4}>
                    <Typography variant="h2" gutterBottom>
                        {match.url.includes('create') ? 'Create' : 'Edit'}{' '}
                        company
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={12}>
                {companyForm && <CompanyForm form={companyForm} />}
            </Grid>
            <Grid item xs={12}>
                {savingCompany ? (
                    <ThemedSpinner />
                ) : (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={(e) => companyForm.onSubmit(e)}
                    >
                        Save
                    </Button>
                )}
            </Grid>
            {error && (
                <Grid item xs={12}>
                    <ErrorMessage error={error} />
                </Grid>
            )}
        </Grid>
    );
}

export default observer(CompanyEdit);
