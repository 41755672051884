import React, {useEffect, useMemo, useState} from 'react';
import { observer } from 'mobx-react';
import ThemedSpinner from '../../components/themed/ThemedSpinner';
import { useStores } from '../../hooks/use-stores';
import { Link, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { enforceSlash, formatDate, formatStatus } from '../../utils/helpers';
import SimpleTable from '../../components/super/SimpleTable';
import moment from 'moment';
import commonStore from '../../stores/commonStore';
import { CircularProgress } from '@material-ui/core';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

function ScheduledTask() {
    let { serviceTaskId } = useParams();
    const { commonStore, serviceTasksStore } = useStores();
    const {
        loadingServiceTasks,
        editingServiceTask,
        editingTaskArguments,
        updatingTasks,
        creatingTask,
        runningReport,
        taskTypes,
    } = serviceTasksStore;

    const [nameError, setNameError] = useState(false);
    const [taskType, setTaskType] = useState("");
    const [argumentsError, setArgumentsError] = useState(false);
    let history = useHistory();
    let match = useRouteMatch();

    const loadData = async () => {
        await serviceTasksStore.loadingServiceTaskTypes();
        serviceTaskId
            ? await serviceTasksStore.loadServiceTask(serviceTaskId)
            : (serviceTasksStore.editingServiceTask = {
                  name: '',
                  arguments: '',
              });
    };

    useEffect(() => {
        loadData();
    }, [serviceTaskId]);

    const handleChangeArguments = (e) => {
        serviceTasksStore.setArguments(e.target.value);
    };


    const handleSave = async () => {
        if (!serviceTasksStore.editingServiceTask.name) {
            setNameError(true);
            return;
        }
        setNameError(false);
        try {
            JSON.parse(editingTaskArguments);
            setArgumentsError(false);
        } catch (e) {
            setArgumentsError(true);
        }


        let result;
        serviceTaskId
            ? (result = await serviceTasksStore.updateTask(
                serviceTasksStore.editingServiceTask.id,
                  {
                      ...serviceTasksStore.editingServiceTask,
                      arguments: editingTaskArguments
                  }
              ))
            : (result = await serviceTasksStore.createTask({
                  ...serviceTasksStore.editingServiceTask,
                  arguments: editingTaskArguments,
                taskType
              }));
        if (result) {
            serviceTaskId
                ? history.push(`/serviceTasks/${serviceTaskId}/edit`)
                : history.push('/serviceTasks');
        }
    };
    const runReport = (x) => {
        serviceTasksStore.runReport(x.value);
    };
    const showDetails = (x) => {
        commonStore.showConfirm(
            <pre>
                {typeof x.value === 'string'
                    ? x.value
                    : JSON.stringify(x.value, null, 2)}
            </pre>,
            'Close',
            'Error Details',
            null,
            false
        );
    };

    const selectedType =  !taskType || !taskTypes || taskTypes.length===0
        ?  null
        : taskTypes.find(x=> x.code === taskType)


    return loadingServiceTasks || !editingServiceTask ? (
        <ThemedSpinner />
    ) : (
        editingServiceTask && (
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                align="center"
                spacing={2}
            >
                <Grid item xs={12}>
                    <Box mt={4}>
                        <Typography variant="h2" gutterBottom>
                            Task: {editingServiceTask.name}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={10}>
                    <TextField
                        type="text"
                        name="taskName"
                        value={editingServiceTask.name || ''}
                        onChange={(e) => {
                            editingServiceTask.name = e.target.value;
                        }}
                        error={nameError}
                        helperText={nameError ? 'Name is required' : 'Please use only chars and numbers, no space'}
                        variant="outlined"
                        label="Task name"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={10}>
                    <Select
                        variant="filled"
                        name="subscription"
                        fullWidth
                        value={taskType}
                        onChange={(e) => setTaskType(e.target.value)}
                    >
                        {taskTypes &&
                            taskTypes.map((s) => (
                                <MenuItem key={s.code} value={s.code}>
                                    {s.name}
                                </MenuItem>
                            ))}
                    </Select>
                    {
                        selectedType && selectedType.description
                    }
                </Grid>
                <Grid item xs={10}>
                    <TextField
                        type="textarea"
                        multiline
                        rows={15}
                        cols={70}
                        name="arguments( optional, JSON )"
                        value={editingTaskArguments || ''}
                        onChange={handleChangeArguments}
                        error={argumentsError}
                        helperText={
                            argumentsError
                                ? 'Should be a valid JSON: ' + argumentsError
                                : ''
                        }
                        variant="outlined"
                        label="Arguments"
                        fullWidth
                    />
                </Grid>

                <Grid item xs={12}>
                    <Box mb={match.path.includes('edit') ? 0 : 4}>
                        {creatingTask ||
                        (serviceTaskId &&
                            updatingTasks.includes(serviceTaskId)) ? (
                            <ThemedSpinner />
                        ) : (
                            <Button
                                onClick={handleSave}
                                color="primary"
                                variant="contained"
                            >
                                Save
                            </Button>
                        )}
                    </Box>
                </Grid>
            </Grid>
        )
    );
}

export default observer(ScheduledTask);
