import BaseService from "./BaseService";

class IdLessRestService extends BaseService {

    constructor(baseUrl) {
        super();
        this.baseUrl = baseUrl;
    }


    create(entity, options) {
        return this.post(this.baseUrl, entity, options);
    }

    fetch(suffix) {
        if (suffix)
            return this.get(`${this.baseUrl}/${suffix}`);
        return this.get(`${this.baseUrl}`);
    }

    list(params) {
        return this.get(`${this.baseUrl}`, params);
    }

    update(entity, suffix) {
        if (suffix)
            return this.put(`${this.baseUrl}/${suffix}`, entity);
        return this.put(`${this.baseUrl}`, entity);
    }

    delete(params) {
        return this.del(`${this.baseUrl}`, params);
    }


}

export default IdLessRestService;