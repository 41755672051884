import services from '../services';
import { backOffDelay } from '../utils/helpers';
import { makeAutoObservable } from 'mobx';

// do not rewrite this into a functional component, this is not a React component, it will always be a class, Barbora!!

class CommonStore {
    constructor() {
        makeAutoObservable(this);
    }

    message = null;
    appLoaded = false;
    messageType = null;
    messageShown = false;
    confirmationRequired = false;
    confirmationDescription = '';
    confirmationPasscode = '';
    confirmationAction = '';
    confirmationOnConfirmAction;
    confirmationShowCancel = true;
    confirmationTitle = '';
    languagesLoaded = false;
    languages = [];
    messageTimeout = null;

    hideMessage() {
        this.message = null;
        this.messageShown = false;
        this.messageShown = false;
        try {
            clearTimeout(this.messageTimeout);
            // eslint-disable-next-line no-empty
        } catch (e) {}
        this.messageTimeout = null;
    }

    setAppLoaded() {
        this.appLoaded = true;
    }

    success(message) {
        this.showMessage(message, 'success');
    }

    warn(message) {
        this.showMessage(message, 'warning');
    }

    error(message) {
        this.showMessage(message, 'error');
    }

    showMessage(message, type = 'info', delay = 5000) {
        this.hideMessage();
        this.message = message;
        this.messageShown = true;
        this.messageType = type;
        this.messageTimeout = setTimeout(() => {
            this.hideMessage();
        }, delay);
    }

    getEntityName(entityType, entity) {
        switch (entityType) {
            case 'Request':
                return entity.location;
            case 'User':
                return entity.name || entity.email;
            case 'Customer':
                return entity.name;
            default:
                return entityType;
        }
    }

    showDeleteConfirm(entityType, entity, callback) {
        this.showConfirm(
            `Do you really want to delete ${this.getEntityName(
                entityType,
                entity
            )}`,
            'Delete',
            `Delete ${entityType}`,
            callback
        );
    }

    showConfirm(
        confirmationDescription,
        confirmationAction,
        confirmationTitle,
        confirmationOnConfirmAction,
        confirmationShowCancel = true
    ) {
        this.confirmationDescription = confirmationDescription;
        this.confirmationAction = confirmationAction;
        this.confirmationTitle = confirmationTitle;
        this.confirmationOnConfirmAction = confirmationOnConfirmAction;
        this.confirmationShowCancel = confirmationShowCancel;
        this.confirmationRequired = true;
    }

    showDeleteWithInputConfirm(
        confirmationDescription,
        confirmationAction,
        confirmationTitle,
        confirmationPasscode,
        confirmationOnConfirmAction,
        confirmationShowCancel = true
    ) {
        this.confirmationDescription = confirmationDescription;
        this.confirmationAction = confirmationAction;
        this.confirmationTitle = confirmationTitle;
        this.confirmationPasscode = confirmationPasscode;
        this.confirmationOnConfirmAction = confirmationOnConfirmAction;
        this.confirmationShowCancel = confirmationShowCancel;
        this.confirmationRequired = true;
    }

    cancelConfirm() {
        this.confirmationDescription = null;
        this.confirmationAction = null;
        this.confirmationTitle = null;
        this.confirmationOnConfirmAction = null;
        this.confirmationRequired = false;
    }

    doConfirm(arg, passcodeValue = '') {
        if (this.confirmationOnConfirmAction) {
            if (this.confirmationPasscode !== '') {
                if (this.confirmationPasscode === passcodeValue)
                    this.confirmationOnConfirmAction();
                else this.cancelConfirm();
            } else this.confirmationOnConfirmAction();
        }

        this.cancelConfirm();
    }

    async loadLanguages(counter = 0) {
        this.languagesLoaded = false;

        try {
            let result = await services.Languages.list();
            this.languages.replace(result);
            this.languagesLoaded = true;
        } catch (e) {
            if (counter < 10)
                setTimeout(async () => {
                    await this.loadLanguages(counter + 1);
                }, backOffDelay(1500, counter));
        }
    }
}

export default CommonStore;
