import React, { useEffect } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import ErrorMessage from '../../components/errormessage';
import ThemedSpinner from '../../components/themed/ThemedSpinner';
import { enforceSlash } from '../../utils/helpers';
import { useStores } from '../../hooks/use-stores';
import { observer } from 'mobx-react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import SimpleTable from '../../components/super/SimpleTable';
import { Fab, Tooltip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    fab: {
        position: 'fixed',
        bottom: theme.spacing(4),
        right: theme.spacing(4),
    },
}));
function Materials() {
    const { superStore } = useStores();
    const { loadingMaterials, materials, materialsError } = superStore;
    let match = useRouteMatch();
    const classes = useStyles();

    const loadData = async () => {
        superStore.loadMaterials();
    };

    useEffect(() => {
        loadData();
    }, []);

    const columns = React.useMemo(
        () => [
            {
                Header: 'Id',
                accessor: 'id',
                Cell: (x) => (
                    <Link to={`${enforceSlash(match.url)}${x.value}/edit`}>
                        {x.value}
                    </Link>
                ),
            },
            {
                Header: 'Name',
                accessor: 'title',
                Cell: (x) => (
                    <Link
                        to={`${enforceSlash(match.url)}${
                            x.row.original.id
                        }/edit`}
                    >
                        {x.value}
                    </Link>
                ),
            },
            {
                Header: '# of Languages',
                accessor: (row) => {
                    return row.languages.length;
                },
            },
            {
                Header: 'Order',
                accessor: 'order_number',
            },
        ],
        [match.url]
    );

    return (
        <Grid
            container
            direction="row"
            justify="center"
            align="center"
            spacing={2}
        >
            <Grid item xs={12}>
                <Box mt={4}>
                    <Typography variant="h2">Materials</Typography>
                </Box>
            </Grid>
            <Tooltip title="Create question" aria-label="add" placement="left">
                <Fab
                    color="primary"
                    href="/content/materials/create"
                    className={classes.fab}
                >
                    <AddIcon />
                </Fab>
            </Tooltip>
            {loadingMaterials ? (
                <ThemedSpinner />
            ) : materials && materials.length > 0 ? (
                <Grid item xs={12}>
                    <SimpleTable
                        columns={columns}
                        data={materials}
                        needsFilter={true}
                    />
                </Grid>
            ) : (
                <Grid item xs={12}>
                    <Typography>No materials found :(</Typography>
                </Grid>
            )}
            {materialsError && (
                <Grid item>
                    <ErrorMessage error={materialsError} />
                </Grid>
            )}
        </Grid>
    );
}

export default observer(Materials);
