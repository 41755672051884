import React, { Fragment, useState } from 'react';
import { observer } from 'mobx-react';
import ErrorMessage from '../../components/errormessage';
import ThemedSpinner from '../../components/themed/ThemedSpinner';
import { useStores } from '../../hooks/use-stores';
import PrincipalChooser from './PrincipalChooser';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import SimpleTable from '../../components/super/SimpleTable';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MuiSelect from '@material-ui/core/Select';
import MuiMenuItem from '@material-ui/core/MenuItem';

function ACLEditor({ acl, unique_id }) {
    const { superStore, commonStore } = useStores();
    const [target, setTarget] = useState(null);
    const [targetName, setTargetName] = useState(null);
    const [requiredError, setRequiredError] = useState(null);
    const [ruleType, setRuleType] = useState(1);

    const { subjectsError, removingAclLines, creatingAclLine } = superStore;

    const removeAclLine = (aclLine) => {
        commonStore.showConfirm(
            `Remove access for ${aclLine.companyName || aclLine.partnerName}`,
            'Delete',
            'Delete access',
            async () => {
                await superStore.removeAclLine(aclLine.id, () => {
                    acl.records.remove(aclLine);
                });
            }
        );
    };
    const createAclLine = () => {
        if (!target) {
            setRequiredError('Target Is Required');
            return;
        } else {
            setRequiredError(null);
        }

        commonStore.showConfirm(
            `Set ${
                ruleType === 1 ? "'Allow'" : "'Deny'"
            } permission for ${targetName}`,
            'Set',
            `Set permission `,
            async () => {
                let result = await superStore.createAclLine({
                    object_unique_id: unique_id,
                    principal_unique_id: target.unique_id,
                    rule: ruleType,
                });
                if (result) {
                    acl.records.push({
                        ...result,
                        companyName: target.type === 'company' && target.name,
                        partnerName: target.type === 'partner' && target.name,
                    });
                }
            }
        );
    };

    const removePublicAccess = () => {
        commonStore.showConfirm(
            `Remove Public access?`,
            'Revoke',
            'Revoke public access',
            async () => {
                await superStore.removeAclLine(acl.publicRecordId, () => {
                    acl.isPublic = false;
                    acl.publicRecordId = null;
                });
            }
        );
    };

    const grantPublicAccess = () => {
        commonStore.showConfirm(
            `Grant Public access?`,
            'Grant',
            'Grant public access',
            async () => {
                let result = await superStore.createAclLine({
                    object_unique_id: unique_id,
                    principal_unique_id: 'public',
                    rule: 1,
                });
                acl.isPublic = true;
                acl.publicRecordId = result.id;
            }
        );
    };

    const columns = React.useMemo(
        () => [
            {
                Header: 'Id',
                accessor: 'id',
            },
            {
                Header: 'Target',
                accessor: (x) => (
                    <Fragment>
                        <strong>
                            {x.companyName && 'Company: '}
                            {x.partnerName && 'Partner: '}
                        </strong>
                        [{x.companyId || x.partnerId}]{' '}
                        {x.companyName || x.partnerName}
                    </Fragment>
                ),
            },
            {
                Header: 'Access',
                accessor: 'rule',
                Cell: (x) =>
                    x.value === 1 ? (
                        <DoneIcon color="primary" fontSize="large" />
                    ) : (
                        <ClearIcon color="secondary" fontSize="large" />
                    ),
            },
            {
                id: 'action',
                accessor: (x) =>
                    removingAclLines.includes(x.id) ? (
                        <ThemedSpinner />
                    ) : (
                        <Button
                            onClick={() => removeAclLine(x)}
                            color="secondary"
                            variant="contained"
                        >
                            Delete
                        </Button>
                    ),
            },
        ],
        []
    );

    return (
        <Grid
            container
            direction="row"
            justify="center"
            align="center"
            spacing={4}
        >
            <ErrorMessage error={subjectsError} />
            {acl && (
                <Fragment>
                    <Grid item xs={12}>
                        <Grid
                            container
                            direction="row"
                            align="center"
                            justify="center"
                            spacing={2}
                        >
                            <Grid item>
                                <Typography
                                    variant="h6"
                                    gutterBottom
                                    display="inline"
                                >
                                    Public access:
                                </Typography>
                            </Grid>
                            <Grid item>
                                {acl.isPublic ? (
                                    <DoneIcon
                                        color="primary"
                                        fontSize="large"
                                    />
                                ) : (
                                    <ClearIcon
                                        color="secondary"
                                        fontSize="large"
                                    />
                                )}
                            </Grid>
                            <Grid item>
                                {acl.isPublic ? (
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        onClick={removePublicAccess}
                                    >
                                        Revoke public access
                                    </Button>
                                ) : (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={grantPublicAccess}
                                    >
                                        Grant public access
                                    </Button>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Box mb={acl.records.length > 0 ? 0 : 4}>
                            <Grid
                                container
                                direction="row"
                                alignItems="center"
                                justify="center"
                                spacing={2}
                            >
                                <Grid item>
                                    <Typography variant="h6" gutterBottom>
                                        Add new rule:
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <PrincipalChooser
                                        value={targetName}
                                        onChange={(event, { newValue }) => {
                                            setTargetName(newValue);
                                        }}
                                        onSuggestionSelected={(
                                            event,
                                            { suggestion }
                                        ) => {
                                            setTarget(suggestion);
                                        }}
                                    />
                                </Grid>
                                {requiredError && (
                                    <Grid item>
                                        <Typography
                                            variant="body1"
                                            gutterBottom
                                        >
                                            {requiredError}
                                        </Typography>
                                    </Grid>
                                )}
                                <Grid item>
                                    <FormControl variant="filled">
                                        <InputLabel>Type</InputLabel>
                                        <MuiSelect
                                            variant="filled"
                                            name="ruleType"
                                            value={ruleType}
                                            onChange={(e) =>
                                                setRuleType(e.target.value)
                                            }
                                        >
                                            <MuiMenuItem value={1}>
                                                Allow
                                            </MuiMenuItem>
                                            <MuiMenuItem value={0}>
                                                Deny
                                            </MuiMenuItem>
                                        </MuiSelect>
                                    </FormControl>
                                </Grid>
                                <Grid item>
                                    {creatingAclLine ? (
                                        <ThemedSpinner />
                                    ) : (
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            type="submit"
                                            onClick={createAclLine}
                                        >
                                            Add
                                        </Button>
                                    )}
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    {acl.records.length > 0 && (
                        <Grid item xs={8}>
                            <SimpleTable
                                columns={columns}
                                data={acl.records}
                                needsFilter={acl.records.length > 10}
                            />
                        </Grid>
                    )}
                </Fragment>
            )}
        </Grid>
    );
}

export default observer(ACLEditor);
