import React, { useEffect, useMemo } from 'react';
import _ from 'lodash';
import { observer } from 'mobx-react';
import { useStores } from '../../hooks/use-stores';
import ThemedSpinner from '../../components/themed/ThemedSpinner';
import {
    CircularProgress,
    IconButton,
    MenuItem,
    Select,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ErrorMessage from '../../components/errormessage';
import { Autocomplete } from '@material-ui/lab';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import SimpleTable from '../../components/super/SimpleTable';

function TagsEditor({ editingItem, targetType }) {
    const [tagType, setTagType] = React.useState('');
    const [tagValue, setTagValue] = React.useState(null);

    const { superStore, tagsStore, threatAreasStore } = useStores();

    const { categories } = superStore;
    const { threatAreas, loadingThreatAreas } = threatAreasStore;
    const { loadingTags, tags, tagsError, removingTags } = tagsStore;

    const loadData = async () => {
        await threatAreasStore.loadThreatAreas();
        await superStore.loadCategories();

        await tagsStore.loadTags(editingItem.unique_id);
    };

    useEffect(() => {
        void loadData();
    }, []);

    const handleSelectTagType = async (e) => {
        setTagType(e.target.value);
        setTagValue(null);
    };

    const handleSelectTagValue = async (event, newValue) => {
        setTagValue(newValue);
    };

    const removeTag = async (id) => {
        await tagsStore.removeTag(id);
        await tagsStore.loadTags(editingItem.unique_id);
    };

    const tagColumns = useMemo(
        () => [
            {
                Header: 'Id',
                accessor: 'id',
            },
            {
                Header: 'Type',
                accessor: 'type',
            },
            {
                Header: 'TagValue',
                accessor: (row) => {
                    if ( row.keyBehaviour  )
                        return `[${row.keyBehaviour.threatArea.threatAreaName }] ${row.keyBehaviour.kbName}( ${row.keyBehaviour.kbCode} )`
                    if ( row.category )
                        return row.category.name
                }
            },
            {
                id: 'delete',
                accessor: 'id',
                Cell: observer((x) =>
                    removingTags.includes(x.value) ? (
                        <ThemedSpinner size={45} />
                    ) : (
                        <IconButton
                            color="secondary"
                            variant="contained"
                            onClick={() => removeTag(x.value)}
                            fontSize="large"
                        >
                            <DeleteIcon />
                        </IconButton>
                    )
                ),
            },
        ],
        []
    );

    const tagOptions = useMemo(() => {
        if (tagType === 'takb') {
            const options = _.flatten(
                threatAreas.map((x) =>
                    x.keyBehaviours.map((y) => ({
                        ...y,
                        threatAreaCode: x.threatAreaCode,
                        threatAreaName: x.threatAreaName,
                    }))
                )
            );
            options.sort(
                (a, b) => -b.threatAreaCode.localeCompare(a.threatAreaCode)
            );
            return options.map((x) => ({
                ...x,
                label: `[${x.kbCode}] ${x.kbName}`,
            }));
        }
        if (tagType === 'category') {
            return categories.map((x) => ({ ...x, label: x.name }));
        }
        return [];
    }, [tagType, threatAreas, loadingThreatAreas]);

    const tagGrouping = useMemo(() => {
        if (tagType === 'takb') {
            return (option) => option.threatAreaName;
        }

        return null;
    }, [tagType]);

    const addTag = async () => {
        await tagsStore.addTag({
            targetId: editingItem.unique_id,
            tagType: tagType,
            tagValue: tagValue.unique_id,
            targetType
        });
        await tagsStore.loadTags(editingItem.unique_id);
    };

    return (
        <>
            {loadingTags ? (
                <CircularProgress />
            ) : (
                <>
                    <Grid item xs={12}>
                        <Typography variant="h4" gutterBottom>
                            Tags
                        </Typography>
                        <ErrorMessage errorType="error" error={tagsError} />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <div>
                            <Select
                                name={'Type'}
                                label={'Type'}
                                onChange={handleSelectTagType}
                                value={tagType}
                            >
                                <MenuItem value="">Select Type</MenuItem>
                                <MenuItem value="category">Category</MenuItem>
                                <MenuItem value="template">Template</MenuItem>
                                <MenuItem value="takb">TA & KB</MenuItem>
                            </Select>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <div>
                            <Autocomplete
                                id="grouped-demo"
                                options={tagOptions}
                                groupBy={tagGrouping}
                                value={tagValue}
                                onChange={handleSelectTagValue}
                                getOptionLabel={(option) => option.label}
                                style={{ width: 300 }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Tag Value"
                                        variant="outlined"
                                    />
                                )}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={addTag}
                        >
                            Add
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <SimpleTable
                            columns={tagColumns}
                            data={tags.slice()}
                            needsFilter={false}
                        />
                    </Grid>
                </>
            )}
        </>
    );
}

export default observer(TagsEditor);
