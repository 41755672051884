import RestService from './RestService';

export default class NotificationsService extends RestService {
    constructor(parentService, parentId) {
        if (parentService && parentId) {
            super(`${parentService.baseUrl}/${parentId}/targets`);
        } else super('/targets');
    }

    resend(targetId) {
        this.post(`${this.baseUrl}/${targetId}/resend`);
    }
}
