import RestService from './RestService';

export default class Schedule extends RestService {
    constructor() {
        super('/scheduledTasks');
    }

    async run(id) {
        return this.post(`${this.baseUrl}/execution`, { id });
    }

    async scheduleNext(id) {
        return this.post(`${this.baseUrl}/${id}/scheduleNext`);
    }
}
