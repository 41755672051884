import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { useStores } from '../hooks/use-stores';
import { observer } from 'mobx-react';

function ConfirmationModal() {
    const { commonStore } = useStores();
    let {
        confirmationRequired,
        confirmationDescription,
        confirmationAction,
        confirmationTitle,
        confirmationPasscode,
        confirmationShowCancel,
    } = commonStore;

    const [passcodeValue, setPasscodeValue] = useState('');

    console.log(
        confirmationRequired,
        confirmationDescription,
        confirmationAction,
        confirmationTitle,
        confirmationPasscode,
        confirmationShowCancel,
        passcodeValue
    );

    const onConfirm = async (arg) => {
        const passOnPasscodeValue = passcodeValue;
        setPasscodeValue('');
        return commonStore.doConfirm(arg, passOnPasscodeValue);
    };

    const renderAction = (confirmationAction) => {
        if (Array.isArray(confirmationAction)) {
            return confirmationAction.map((action, i) => (
                <Button
                    color="primary"
                    // variant="contained"
                    key={i}
                    onClick={() => onConfirm(action.key)}
                    // style={{marginTop:"0.5rem"}}
                >
                    {action.value}
                </Button>
            ));
        } else {
            return (
                <Button
                    color="primary"
                    // variant="contained"
                    onClick={onConfirm}
                    // style={{marginTop:"0.5rem"}}
                >
                    {confirmationAction}
                </Button>
            );
        }
    };

    const handleClose = () => {
        commonStore.cancelConfirm();
    };
    if (!confirmationRequired) return null;

    return (
        confirmationRequired && (
            <Dialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={true}
            >
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    {confirmationTitle}
                </DialogTitle>
                <DialogContent dividers>
                    {confirmationDescription}
                    {confirmationPasscode !== '' && (
                        <p>
                            Please write the word '{confirmationPasscode}' to
                            confirm this action:{' '}
                            <Input
                                name="passcode"
                                type="text"
                                value={passcodeValue}
                                onChange={(e) => {
                                    setPasscodeValue(e.target.value);
                                }}
                            />
                        </p>
                    )}
                </DialogContent>
                <DialogActions>
                    {confirmationShowCancel && (
                        <Button
                            onClick={handleClose}
                            color="secondary"
                            // variant="contained"
                        >
                            Cancel
                        </Button>
                    )}
                    {renderAction(confirmationAction)}{' '}
                </DialogActions>
            </Dialog>
        )
    );
}

export default observer(ConfirmationModal);
