import BaseService from './BaseService';

class Auth extends BaseService {
    current() {
        return this.get('/auth/user');
    }

    signin(
        email,
        company_id,
        campaign_id,
        subject_id,
        redirect,
        scope = 'super'
    ) {
        return this.post('/auth/email/manager_signin', {
            email,
            company_id,
            scope,
            subject_id,
            redirect,
        });
    }

    emailsignin(token) {
        return this.post(`/auth/email/signin/${token}`);
    }

    checkEmail(email) {
        return this.post('/auth/checkEmail', { email });
    }

    registerTrial(data, simple) {
        return simple
            ? this.post('/trial', data)
            : this.post('/auth/manager/trial/new', data);
    }

    registerFreemium(data) {
        return this.post('/freemium/test3', data);
    }
}

export default Auth;
