import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import ErrorMessage from '../../components/errormessage';
import AddSubscription from '../../components/super/add-subscription';
import moment from 'moment';
import ThemedSpinner from '../../components/themed/ThemedSpinner';
import { useStores } from '../../hooks/use-stores';
import SimpleTable from '../../components/super/SimpleTable';
import { Grid } from '@material-ui/core';
import { Box } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import Button from '@material-ui/core/Button';

function SubscriptionList() {
    const { superStore } = useStores();
    const { subscriptions, error, addingSubscription, updatingSubscriptions } =
        superStore;

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        superStore.listSubscriptions();
    };

    const addSubscription = (
        code,
        type,
        trainingSubjects,
        assessmentQuestions,
        integrations,
        maxUsers
    ) => {
        superStore.addSubscription(
            code,
            type,
            trainingSubjects,
            assessmentQuestions,
            integrations,
            maxUsers
        );
    };

    const updateSubscription = (code, active) => {
        superStore.updateSubscription(code, active);
    };

    const columns = [
        {
            Header: 'Code',
            accessor: 'code',
        },
        {
            Header: 'Created',
            accessor: 'created',
            Cell: (x) => moment(x).format('dd D MMM YYYY HH:mm'),
        },
        {
            Header: 'Type',
            accessor: 'type',
        },
        {
            Header: 'Training Subjects',
            accessor: 'trainingSubjects',
        },
        {
            Header: 'Assessment Questions',
            accessor: 'assessmentQuestions',
        },
        {
            Header: 'Integrations',
            accessor: 'integrations',
        },
        {
            Header: 'Max Users',
            accessor: 'maxUsers',
        },
        {
            Header: 'Active',
            accessor: (x) =>
                x.active ? (
                    <DoneIcon color="primary" fontSize="large" />
                ) : (
                    <ClearIcon color="secondary" fontSize="large" />
                ),
        },
        {
            Header: 'End of life date',
            accessor: 'endOfLifeDate',
        },
        {
            Header: 'Disable/Re-activate',
            id: 'action',
            accessor: (x) =>
                updatingSubscriptions.includes(x.code) ? (
                    <ThemedSpinner />
                ) : x.active ? (
                    <Button
                        onClick={() => updateSubscription(x.code, false)}
                        color="secondary"
                        variant="contained"
                    >
                        Disable
                    </Button>
                ) : (
                    <Button
                        onClick={() => updateSubscription(x.code, true)}
                        color="secondary"
                        variant="contained"
                    >
                        Re-activate
                    </Button>
                ),
        },
    ];

    return (
        <Grid
            container
            direction="row"
            justify="center"
            align="center"
            spacing={2}
        >
            <Grid item xs={12}>
                <Box mt={4}>
                    <Typography variant="h2">
                        Available Subscription Plans
                    </Typography>
                </Box>
            </Grid>
            {subscriptions && subscriptions.length === 0 ? (
                <ThemedSpinner />
            ) : (
                <Grid item xs={12}>
                    <SimpleTable columns={columns} data={subscriptions} />
                </Grid>
            )}
            {error && (
                <Grid item xs={12}>
                    <ErrorMessage error={error} />
                </Grid>
            )}
            <Grid item xs={12}>
                <Box mb={4}>
                    <AddSubscription
                        addingSubscription={addingSubscription}
                        addSubscription={addSubscription}
                    />
                </Box>
            </Grid>
        </Grid>
    );
}

export default observer(SubscriptionList);
