import React, { Fragment, useEffect } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { observer } from 'mobx-react';
import ErrorMessage from '../../components/errormessage';
import ThemedSpinner from '../../components/themed/ThemedSpinner';
import moment from 'moment';
import { useStores } from '../../hooks/use-stores';
import SimpleTable from '../../components/super/SimpleTable';
import { TextField } from '@material-ui/core';
import { Avatar } from '@material-ui/core';
import { Box } from '@material-ui/core';
import { Alert } from 'reactstrap';
import { Grid } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import { Fab } from '@material-ui/core';
import { Tooltip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core/styles';
import { enforceSlash } from '../../utils/helpers';

const useStyles = makeStyles((theme) => ({
    fab: {
        position: 'fixed',
        bottom: theme.spacing(4),
        right: theme.spacing(4),
    },
}));

function Languages() {
    const { superStore } = useStores();
    const {
        loadingLanguages,
        sortedlanguages: languages,
        languageError,
    } = superStore;
    let match = useRouteMatch();
    const classes = useStyles();

    const loadData = async () => {
        superStore.loadLanguages();
    };

    const changeOrder = async (lang, value) => {
        let newOrder = Number(value);

        if (value !== '' && !isNaN(newOrder)) {
            await superStore.updateLanguage(lang.id, { order: newOrder });
        } else {
            lang.order = value;
        }
    };

    useEffect(() => {
        loadData();
    }, []);

    const columns = React.useMemo(
        () => [
            {
                Header: 'Code',
                Cell: (x) => (
                    <Link to={`${enforceSlash(match.url)}${x.row.original.id}`}>
                        {x.value}
                    </Link>
                ),
                accessor: 'code',
            },
            {
                Header: 'Native name',
                accessor: 'name',
            },
            {
                Header: 'English Name',
                accessor: 'english_name',
            },
            {
                Header: 'Version',
                accessor: 'version',
            },
            {
                Header: 'Icon',
                accessor: 'icon',
            },
            {
                Header: 'Flag',
                accessor: (x) => (
                    <Avatar alt="country flag" src={`${x.flag_image}`} />
                ),
            },
            {
                Header: 'Published',
                accessor: (x) =>
                    x.published ? (
                        <DoneIcon color="primary" fontSize="large" />
                    ) : (
                        <ClearIcon color="secondary" fontSize="large" />
                    ),
            },
            {
                Header: 'Resources',
                accessor: 'resourcesCount',
            },
            {
                Header: 'Last Uploaded',
                accessor: (x) => moment(x.lastImported).format('L'),
            },
            {
                Header: 'Last PO Edited',
                accessor: (x) =>
                    x.lastUpdatedInPOEditor &&
                    moment(x.lastUpdatedInPOEditor).format('L'),
            },
            {
                Header: 'Subjects',
                accessor: 'subjectsCount',
            },
            {
                Header: 'Notes',
                accessor: (x) =>
                    x.note && <Alert color={x.noteType}>{x.note}</Alert>,
            },
            {
                Header: 'Reorder',
                accessor: (x) => (
                    <TextField
                        style={{ width: '50px' }}
                        type="number"
                        onChange={(e) => {
                            changeOrder(x, e.target.value);
                        }}
                        value={x.order}
                        color="primary"
                    />
                ),
            },
        ],
        []
    );

    return (
        <Grid
            container
            direction="row"
            justify="center"
            align="center"
            spacing={4}
        >
            <Grid item xs={12}>
                <Box mt={4}>
                    <Typography variant="h2">Languages</Typography>
                </Box>
            </Grid>
            {loadingLanguages ? (
                <ThemedSpinner />
            ) : (
                <Fragment>
                    <Tooltip
                        title="Create language"
                        aria-label="add"
                        placement="left"
                    >
                        <Fab
                            color="primary"
                            href="/languages/create"
                            className={classes.fab}
                        >
                            <AddIcon />
                        </Fab>
                    </Tooltip>
                    {languageError && (
                        <Grid item xs={6}>
                            <ErrorMessage error={languageError} />
                        </Grid>
                    )}
                    {languages && languages.length > 0 && (
                        <Grid item xs={12}>
                            <SimpleTable
                                columns={columns}
                                data={languages}
                                needsFilter={true}
                            />
                        </Grid>
                    )}
                </Fragment>
            )}
        </Grid>
    );
}

export default observer(Languages);
