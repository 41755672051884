import React, { Fragment, useEffect } from 'react';
import { Row, Col, Button } from 'reactstrap';
import ErrorMessage from '../../components/errormessage';
import ThemedSpinner from '../../components/themed/ThemedSpinner';
import { useStores } from '../../hooks/use-stores';
import { observer } from 'mobx-react';

function MailHooks() {
    const { superStore } = useStores();
    const { loadingMailHooks, mailHooks } = superStore;

    const loadData = async () => {
        await superStore.loadMailHooks();
    };

    const createHooks = async () => {
        await superStore.createMailHooks();
    };

    useEffect(() => {
        loadData();
    }, []);

    return loadingMailHooks ? (
        <ThemedSpinner />
    ) : (
        <Fragment>
            <Row>
                {mailHooks && mailHooks.length > 0 && (
                    <Col md="12" xs="12" className="leftMenu">
                        <h3> Hooks is up</h3>
                    </Col>
                )}
                {mailHooks && mailHooks.length === 0 && (
                    <Col md="12" xs="12" className="leftMenu">
                        <ErrorMessage error="No hooks found" errorType="info" />
                        <Button
                            onClick={createHooks}
                            className="btn btn-primary"
                        >
                            Create Hooks
                        </Button>
                    </Col>
                )}
            </Row>
        </Fragment>
    );
}

export default observer(MailHooks);
