import React, { Fragment, useState, useEffect } from 'react';
import RTE from 'react-rte';
const RichTextEditor = RTE.default ? RTE.default : RTE; // Hacky fix for rollup bug in Vite: https://github.com/vitejs/vite/issues/2139
import { observer } from 'mobx-react';
import ErrorMessage from '../errormessage';

const HtmlField = observer(
    ({ fieldValue, initialValue, onChange, id, error, ...rest }) => {
        const [value, setValue] = useState(
            RichTextEditor.createValueFromString(initialValue || '', 'html') ||
                RichTextEditor.createEmptyValue()
        );

        useEffect(() => {
            setValue(
                RichTextEditor.createValueFromString(initialValue || '', 'html')
            );
        }, [initialValue]);

        const toolbarConfig = {
            // Optionally specify the groups to display (displayed in the order listed).
            display: [
                'INLINE_STYLE_BUTTONS',
                'BLOCK_TYPE_BUTTONS',
                'LINK_BUTTONS',
                'IMAGE_BUTTON',
                'BLOCK_TYPE_DROPDOWN',
                'HISTORY_BUTTONS',
            ],
            INLINE_STYLE_BUTTONS: [
                { label: 'Bold', style: 'BOLD' },
                { label: 'Italic', style: 'ITALIC' },
                { label: 'Strikethrough', style: 'STRIKETHROUGH' },
                { label: 'Monospace', style: 'CODE' },
                { label: 'Underline', style: 'UNDERLINE' },
            ],
            BLOCK_TYPE_DROPDOWN: [
                { label: 'Normal', style: 'unstyled' },
                { label: 'Heading Large', style: 'header-one' },
                { label: 'Heading Medium', style: 'header-two' },
                { label: 'Heading Small', style: 'header-three' },
                { label: 'Code Block', style: 'code-block' },
            ],
            BLOCK_TYPE_BUTTONS: [
                { label: 'UL', style: 'unordered-list-item' },
                { label: 'OL', style: 'ordered-list-item' },
                { label: 'Blockquote', style: 'blockquote' },
            ],
        };

        const onChangeRTE = (value) => {
            setValue(value);
            if (onChange) {
                let html = value.toString('html');
                onChange(html);
            }
        };

        return (
            <Fragment>
                {error && <ErrorMessage error={error} />}
                <RichTextEditor
                    value={value}
                    id={id}
                    onChange={onChangeRTE}
                    toolbarConfig={toolbarConfig}
                    {...rest}
                />
            </Fragment>
        );
    }
);
export default HtmlField;
