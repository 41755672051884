import * as validatorjs from 'validatorjs';
import MobxReactForm from 'mobx-react-form';

const plugins = {
    dvr: {
        package: validatorjs,
        extend: ($validator) => {
            var messages = $validator.getMessages('en');
            messages.required = 'You need to enter some information to save';
            $validator.setMessages('en', messages);
        },
    },
};

const fields = {
    name: {
        label: 'Name',
        rules: 'string|required|between:4,150',
        placeholder: 'Native name',
    },
    english_name: {
        label: 'English Name',
        rules: 'string|required|between:4,150',
    },
    code: {
        label: 'Code',
        rules: 'string|required',
        placeholder: 'Language code',
    },
    version: {
        label: 'Version',
        rules: 'required',
        value: 'latest',
    },
    icon: {
        label: 'Icon (css class)',
        rules: 'string',
        placeholder: 'Language icon (css)',
    },
    flag_image: {
        label: 'Flag image',
        rules: 'string',
    },
};

export default function create(hooks) {
    return new MobxReactForm({ fields }, { plugins, hooks });
}
