import RestService from './RestService';
import NotificationTargetsService from './NotificationTargetsService';

class NotificationsStatusService extends RestService {
    constructor(parentService) {
        if (parentService) {
            super(`${parentService.baseUrl}/status`);
        } else throw new Error('Can be only nested service');
    }
}

export default class NotificationsService extends RestService {
    constructor(parentService, parentId) {
        if (parentService && parentId) {
            super(`${parentService.baseUrl}/${parentId}/notifications`);
        } else super('/notifications');
    }

    targetsService(reasonId) {
        return new NotificationTargetsService(this, reasonId);
    }

    statusService() {
        return new NotificationsStatusService(this);
    }
}
