import * as validatorjs from 'validatorjs';
import MobxReactForm from 'mobx-react-form';

const plugins = {
    dvr: {
        package: validatorjs,
        extend: ($validator) => {
            var messages = $validator.getMessages('en');
            messages.required = 'You need to enter some information to save';
            $validator.setMessages('en', messages);
        },
    },
};

const fields = {
    set_external_id: {
        label: 'Link Chargebee subscription ID',
        rules: 'string',
        placeholder: 'Chargebee subscription ID',
    },
    external_id: {
        label: 'Subscription plan',
        rules: 'string|required',
        placeholder: 'Subscription plan',
    },
    package_name: {
        label: 'Subscription plan',
        rules: 'string|required',
        placeholder: 'Subscription plan',
    },
    users: {
        label: 'Users',
        rules: 'numeric|required',
        placeholder: 'Users',
    },
    valid_to: {
        label: 'Valid to',
        rules: 'date|required',
        placeholder: 'Valid to',
    },
    trial: {
        label: 'Trial',
        rules: 'boolean|required',
        placeholder: 'Trial',
    },
};

export default function create(hooks) {
    return new MobxReactForm({ fields }, { plugins, hooks });
}
