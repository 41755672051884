import React from 'react';
import { Route, Redirect } from 'react-router';
import { useStores } from '../hooks/use-stores';

function PrivateRoute({ component: Component, notLoggedUrl, ...rest }) {
    const { authStore } = useStores();

    return (
        <Route
            {...rest}
            render={(props) => {
                if (!notLoggedUrl)
                    notLoggedUrl = `/auth/signin?redirect=${props.location.pathname}${props.location.search}`;

                return authStore.isAuthenticated === true &&
                    authStore.token != null ? (
                    <Component {...props} {...rest} />
                ) : authStore.inProgress ? (
                    ''
                ) : (
                    <Redirect to={notLoggedUrl} />
                );
            }}
        />
    );
}

export default PrivateRoute;
