import RestService from './RestService';
import KeyBehaviourService from './KeyBehaviourService';

export default class Tags extends RestService {
    constructor() {
        super('/threatAreas');
    }

    KeyBehaviorService(threatAreaId) {
        return new KeyBehaviourService(this, threatAreaId);
    }
}
