import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import { Box } from '@material-ui/core';

function NotFound() {
    return (
        <Grid container align="center" justify="center">
            <Grid item>
                <Box mt={4}>
                    <Typography variant="h3">Sorry, page not found!</Typography>
                </Box>
            </Grid>
        </Grid>
    );
}

export default NotFound;
