import RestService from './RestService';

export default class Subjects extends RestService {
    constructor(parentService, parentId) {
        if (parentService && parentId) {
            super(`${parentService.baseUrl}/${parentId}/internal_data`);
        } else super('/internal_data');
    }

    allPartnerSeats() {
        return this.get(`${this.baseUrl}/partner/seats`);
    }
}
