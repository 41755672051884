import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import ErrorMessage from '../../components/errormessage';
import EditPackage from '../../components/super/packageEdit';
import ThemedSpinner from '../../components/themed/ThemedSpinner';
import { useStores } from '../../hooks/use-stores';
import { observer } from 'mobx-react';
import { useParams } from 'react-router';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

function PackageEdit() {
    let { companyId, packageId } = useParams();
    const { superStore } = useStores();
    let history = useHistory();
    const {
        updatingPackages,
        error,
        packageEditForm: editPackage,
        subscriptions,
    } = superStore;

    const loadData = async () => {
        if (companyId) {
            await superStore.loadCompany(companyId);
            await superStore.loadPackage(packageId);
            await superStore.listSubscriptions(companyId);
        }
    };

    useEffect(() => {
        superStore.startEditPackage(
            companyId,
            packageId,
            (responseCompanyId) => {
                if (responseCompanyId)
                    return history.push(`/companies/${responseCompanyId}`);
                else return history.push('/companies');
            }
        );
        loadData();
    }, [companyId]);

    return (
        <Grid
            container
            direction="row"
            justify="center"
            align="center"
            spacing={2}
        >
            <Grid item xs={12}>
                <Box mt={4}>
                    <Typography variant="h2" gutterBottom>
                        Edit package
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={12}>
                {editPackage && (
                    <EditPackage
                        form={editPackage}
                        subscriptions={subscriptions}
                    />
                )}
            </Grid>
            <Grid item xs={12}>
                {updatingPackages.length > 0 ? (
                    <ThemedSpinner />
                ) : (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={(e) => editPackage.onSubmit(e)}
                    >
                        Save
                    </Button>
                )}
            </Grid>
            {error && (
                <Grid item xs={12}>
                    <ErrorMessage error={error} />
                </Grid>
            )}
        </Grid>
    );
}

export default observer(PackageEdit);
