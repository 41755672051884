import React, { useState } from 'react';
import ThemedSpinner from '../themed/ThemedSpinner';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import { TextField } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';

function SendTestEmail({ sendEmail, sendingEmail }) {
    const [email, setEmail] = useState('');
    const [type, setType] = useState('all');

    const send = async () => {
        let inputOk = true;
        if (inputOk) {
            sendEmail(email, type);
        }
    };

    return (
        <Grid
            container
            direction="column"
            justify="center"
            align="center"
            spacing={2}
        >
            <Grid item>
                <Typography variant="h5" gutterBottom>
                    Send test email
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Send the specified type of email for this company to the
                    provided email address.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    The email should mimick what emails from this company would
                    look like with the current settings.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <strong>Note:</strong> if the email receiving the test
                    exists in our system, its preferred language settings can
                    affect the test
                </Typography>
            </Grid>
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                spacing={2}
            >
                <Grid item>
                    <TextField
                        type="text"
                        label="Email"
                        name="Email"
                        className="bigInput"
                        value={email}
                        variant="filled"
                        onChange={(e) => {
                            setEmail(e.target.value);
                        }}
                    />
                </Grid>

                <Grid item>
                    <FormControl variant="filled">
                        <InputLabel>Email type</InputLabel>
                        <Select
                            variant="filled"
                            name="type"
                            value={type}
                            onChange={(e) => {
                                setType(e.target.value);
                            }}
                        >
                            <MenuItem value="all">All emails</MenuItem>
                            <MenuItem value="manual_reminder">
                                Manual reminder
                            </MenuItem>
                            <MenuItem value="auto_reminder">
                                Auto reminder
                            </MenuItem>
                            <MenuItem value="training_schedule">
                                Training schedule
                            </MenuItem>
                            <MenuItem value="introductory_email">
                                Introductory email
                            </MenuItem>
                            <MenuItem value="new_employee_enrolment_email">
                                New employee enrolment email
                            </MenuItem>
                            <MenuItem value="certificate">Certificate</MenuItem>
                            <MenuItem value="assessment_schedule">
                                Assessment schedule
                            </MenuItem>
                            <MenuItem value="manual_assessment_reminder">
                                Assessment reminder
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item>
                    {sendingEmail ? (
                        <ThemedSpinner />
                    ) : (
                        <Button
                            onClick={send}
                            color="primary"
                            variant="contained"
                            startIcon={<SendIcon />}
                        >
                            Send email
                        </Button>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
}

export default SendTestEmail;
