import React, { Fragment, useEffect, useMemo } from 'react';
import { useStores } from '../../../hooks/use-stores';
import { Observer, observer } from 'mobx-react';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Clipboard from 'react-clipboard.js';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Input from '@material-ui/core/Input';
import superStore from '../../../stores/superStore';
import ThemedSpinner from '../../../components/themed/ThemedSpinner';
import SimpleTable from '../../../components/super/SimpleTable';
import { formatDate } from '../../../utils/helpers';
import Button from '@material-ui/core/Button';
import SendIcon from '@material-ui/icons/Send';
import PersonIcon from '@material-ui/icons/Person';
import TimerIcon from '@material-ui/icons/Timer';
import Countdown from 'react-countdown';
import DeleteIcon from '@material-ui/icons/Delete';
import AddManager from '../../../components/super/add-manager';

const useStyles = makeStyles(() => ({
    label: {
        fontSize: 12,
        fontWeight: 800,
        paddingRight: 2,
    },

    keyValue: {
        whiteSpace: 'nowrap',
        flexWrap: 'nowrap',
    },

    value: {
        fontSize: 12,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        fontFamily: 'monospace',
    },
    divider: {
        marginTop: 10,
        marginBottom: 10,
    },
}));

function Builds() {
    const TOKEN_TIMER = 1 * 60 * 1000;
    const { superStore, commonStore } = useStores();
    const classes = useStyles();
    const {
        admins,
        loadingAdmins,
        adminsError: error,
        removingAdmins,
        addingSuperAdmin,
        managerTokensIndex,
    } = superStore;

    const addManager = (name, email) => {
        superStore.addSuperAdmin({ name, email });
    };

    const impersonate = (manager) => {
        if (manager.timer) {
            clearTimeout(manager.timer);
            manager.timer = null;
        }
        let link = superStore.impersonate(manager.email, 'super');
        if (link) {
            manager.timer = setTimeout(() => {
                manager.timer = null;
                superStore.removeToken(manager.email);
            }, TOKEN_TIMER);
        }
    };

    const removeManager = (email) => {
        superStore.removeAdmin(email);
    };

    const columns = useMemo(() => {
        return [
            {
                Header: 'Created',
                accessor: 'created',
                Cell: (x) => formatDate(x.value),
            },
            {
                Header: 'Email',
                accessor: 'email',
            },
            {
                Header: 'Name',
                accessor: 'name',
            },
            {
                id: 'impersonate',
                accessor: (x) => (
                    <Observer>
                        {() => {
                            return (
                                <Grid
                                    container
                                    direction="row"
                                    alignItems="center"
                                    spacing={1}
                                >
                                    <Grid item>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => {
                                                impersonate(x);
                                            }}
                                            startIcon={<PersonIcon />}
                                        >
                                            Impersonate
                                        </Button>
                                    </Grid>
                                    {managerTokensIndex[x.email] &&
                                        managerTokensIndex[x.email]
                                            .access_link && (
                                            <Fragment>
                                                <Grid item>
                                                    <Clipboard
                                                        className="copy-to-clipboard-link"
                                                        data-clipboard-text={
                                                            managerTokensIndex[
                                                                x.email
                                                            ].access_link
                                                        }
                                                    >
                                                        <Button
                                                            variant="contained"
                                                            startIcon={
                                                                <FileCopyIcon />
                                                            }
                                                        >
                                                            Copy sign in link
                                                        </Button>
                                                    </Clipboard>
                                                </Grid>
                                                <Grid item>
                                                    <TimerIcon color="primary" />
                                                </Grid>
                                                <Grid item>
                                                    <Countdown
                                                        date={
                                                            Date.now() +
                                                            TOKEN_TIMER
                                                        }
                                                        renderer={(props) => (
                                                            <Fragment>
                                                                {props.minutes
                                                                    .toString()
                                                                    .padStart(
                                                                        2,
                                                                        '0'
                                                                    ) +
                                                                    ':' +
                                                                    props.seconds
                                                                        .toString()
                                                                        .padStart(
                                                                            2,
                                                                            '0'
                                                                        )}
                                                            </Fragment>
                                                        )}
                                                    />
                                                </Grid>
                                            </Fragment>
                                        )}
                                </Grid>
                            );
                        }}
                    </Observer>
                ),
            },
            {
                id: 'deleteManager',
                accessor: (x) => (
                    <Observer>
                        {() => {
                            return (
                                <>
                                    {removingAdmins.includes(x.email) ? (
                                        <ThemedSpinner />
                                    ) : (
                                        <IconButton
                                            variant="outlined"
                                            color="secondary"
                                            onClick={() => {
                                                removeManager(x.email);
                                            }}
                                        >
                                            <DeleteIcon fontSize="large" />
                                        </IconButton>
                                    )}
                                </>
                            );
                        }}
                    </Observer>
                ),
            },
        ];
    }, []);

    useEffect(() => {
        superStore.loadAdmins();
    }, []);

    if (loadingAdmins) {
        return <ThemedSpinner />;
    }

    return (
        <Grid container direction="row" spacing={2}>
            <Grid container item xs={12}>
                {' '}
                {error && (
                    <Alert variant={'filled'} color={'error'}>
                        {error}
                    </Alert>
                )}
            </Grid>
            <Grid container justify={'center'} item xs={12}>
                <Typography variant="h5">Super Admins</Typography>
            </Grid>
            <Grid item xs={12}>
                <AddManager
                    addingManager={addingSuperAdmin}
                    onlyEmail={true}
                    addManager={addManager}
                />
            </Grid>

            <Grid item xs={12}>
                <SimpleTable
                    columns={columns}
                    data={admins.slice(0)}
                    needsFilter={false}
                />
            </Grid>
        </Grid>
    );
}

export default observer(Builds);
