import RestService from './RestService';

export default class Schedule extends RestService {
    constructor() {
        super('/serviceTasks');
    }

    async types() {
        return this.get(`${this.baseUrl}/types`);
    }

    async prepare(id) {
        return this.post(`${this.baseUrl}/prepare`, { id });
    }

    async execute(id) {
        return this.post(`${this.baseUrl}/execute`, { id });
    }

    async abandon(id) {
        return this.post(`${this.baseUrl}/abandon`, { id });
    }

    async reset(id) {
        return this.post(`${this.baseUrl}/reset`, { id });
    }

    async reRunFailed(id) {
        return this.post(`${this.baseUrl}/rerunfailed`, { id });
    }

    async loadFailedList(id) {
        return this.get(`${this.baseUrl}/${id}/failed`);
    }
}
