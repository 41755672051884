import React, { useEffect } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import services from '../../services';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { TextField } from '@material-ui/core';
import { InputLabel } from '@material-ui/core';
import { Select } from '@material-ui/core';
import { MenuItem } from '@material-ui/core';
import { FormControl } from '@material-ui/core';
import Table from '../../components/super/Table';
import { useStores } from '../../hooks/use-stores';
import CircularProgress from '@material-ui/core/CircularProgress';
import { observer } from 'mobx-react';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

function SelectColumnFilter({ column: { filterValue, setFilter } }) {
    // Render a multi-select box
    return (
        <FormControl
            variant="filled"
            style={{ minWidth: 150, marginBottom: 'auto' }}
            size="small"
        >
            <InputLabel>Reason:</InputLabel>
            <Select
                name="reason"
                value={filterValue || ''}
                onChange={(e) => {
                    setFilter(e.target.value);
                }}
            >
                <MenuItem value="">All</MenuItem>
                <MenuItem value="certificate">Certificate</MenuItem>
                <MenuItem value="manual_reminder">Reminder</MenuItem>
                <MenuItem value="manual_assessment_reminder">
                    Manual Assessment Reminder
                </MenuItem>
                <MenuItem value="assessment_schedule">
                    Assessment Schedule
                </MenuItem>
                <MenuItem value="auto_reminder">Auto Reminder</MenuItem>
                <MenuItem value="auto_reminder2">Auto Reminder 2</MenuItem>
                <MenuItem value="my_login">MY Login</MenuItem>
                <MenuItem value="lms_login">LMS Login</MenuItem>
                <MenuItem value="event_preview">Preview</MenuItem>
                <MenuItem value="training_schedule">Schedule</MenuItem>
                <MenuItem value="introductory_email">
                    Introductory E-mail
                </MenuItem>
                <MenuItem value="new_employee_enrolment_email">
                    New Employee
                </MenuItem>
            </Select>
        </FormControl>
    );
}

function CompanyColumnFilter({ column: { filterValue, setFilter } }) {
    return (
        <FormControl size="small">
            <TextField
                type="text"
                label="Company"
                variant="filled"
                value={filterValue || ''}
                size="small"
                placeholder="Company ID"
                onChange={(e) => {
                    setFilter(Number(e.target.value) || undefined);
                }}
            />
        </FormControl>
    );
}

function Notifications() {
    const columns = React.useMemo(
        () => [
            {
                Header: 'Created',
                accessor: (x) => {
                    return (
                        <Link to={`/notifications/${x.reason_id}`}>
                            {moment(x.created).format('dd D. MMM YY hh:mm')}
                        </Link>
                    );
                },
            },
            {
                Header: ' ',
                id: 'reason',
                accessor: 'reason',
                Filter: SelectColumnFilter,
            },
            {
                Header: 'Initiated By',
                accessor: 'initiator',
            },
            {
                Header: ' ',
                id: 'company_id',
                accessor: (x) => `[${x.company_id}]-${x.company_name}`,
                Filter: CompanyColumnFilter,
            },
            {
                Header: 'Account',
                accessor: (x) => {
                    return `[${x.account_id}]-${x.account_name}`;
                },
            },
            {
                Header: 'Total targets',
                accessor: 'notifications_count',
            },
            {
                Header: 'Sent',
                accessor: 'sent',
            },
            {
                Header: 'Delivered',
                accessor: 'delivered',
            },
            {
                Header: 'Opened',
                accessor: 'opened',
            },
            {
                Header: 'Duplicates',
                accessor: 'warn',
            },
        ],
        []
    );

    const { notificationsStore } = useStores();
    const { loadingStatus, status } = notificationsStore;

    useEffect(() => {
        notificationsStore.loadStatus();
    }, []);

    const handleChange = (e, key) => {
        notificationsStore.updateStatus({ key, enabled: e.target.checked });
    };

    // We'll start our table without any data
    const [data, setData] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [pageCount, setPageCount] = React.useState(0);
    const fetchIdRef = React.useRef(0);

    const fetchData = React.useCallback(
        async ({ pageSize, pageIndex, filters }) => {
            // This will get called when the table needs new data
            // You could fetch your data from literally anywhere,
            // even a server. But for this example, we'll just fake it.

            // Give this fetch an ID
            const fetchId = ++fetchIdRef.current;
            setLoading(true);
            let filtersObject = {};
            filters.forEach((x) => {
                filtersObject[x.id] = x.value;
            });

            let result = await services.Notifications.list({
                p: pageIndex,
                n: pageSize,
                s: null,
                f: null,
                fetchId,
                ...filtersObject,
            });

            if (fetchId === fetchIdRef.current) {
                setData(result.rows);

                setPageCount(result.pages);

                setLoading(false);
            }
        },
        []
    );

    return (
        <Grid
            container
            direction="row"
            justify="center"
            align="center"
            spacing={2}
        >
            <Grid item xs={12}>
                <Box mt={4}>
                    <Typography variant="h2" gutterBottom>
                        Notifications
                    </Typography>
                </Box>
            </Grid>

            <Grid item xs={12}>
                <Box mt={4}>
                    {loadingStatus && <CircularProgress />}
                    {status && !loadingStatus && (
                        <>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={
                                            status.automaticRemindersEnabled
                                        }
                                        onChange={(e) => {
                                            handleChange(
                                                e,
                                                'automaticRemindersEnabled'
                                            );
                                        }}
                                        name="automaticRemindersEnabled"
                                        color="primary"
                                    />
                                }
                                label="Automatic Reminders Enabled"
                            />
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={status.trainingScheduleEnabled}
                                        onChange={(e) => {
                                            handleChange(
                                                e,
                                                'trainingScheduleEnabled'
                                            );
                                        }}
                                        name="trainingScheduleEnabled"
                                        color="primary"
                                    />
                                }
                                label="Training Schedule  Enabled"
                            />
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={
                                            status.assessmentScheduleEnabled
                                        }
                                        onChange={(e) => {
                                            handleChange(
                                                e,
                                                'assessmentScheduleEnabled'
                                            );
                                        }}
                                        name="assessmentScheduleEnabled"
                                        color="primary"
                                    />
                                }
                                label="Assessment Schedule Enabled"
                            />
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={status.slackRemindersEnabled}
                                        onChange={(e) => {
                                            handleChange(
                                                e,
                                                'slackRemindersEnabled'
                                            );
                                        }}
                                        name="slackRemindersEnabled"
                                        color="primary"
                                    />
                                }
                                label="Slack Reminders Enabled"
                            />
                        </>
                    )}
                </Box>
            </Grid>

            <Grid item xs={12}>
                <Table
                    columns={columns}
                    data={data}
                    fetchData={fetchData}
                    loading={loading}
                    pageCount={pageCount}
                />
            </Grid>
        </Grid>
    );
}

export default observer(Notifications);
