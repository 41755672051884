import React from 'react';
import PropTypes from 'prop-types';
import ThemedSpinner from '../themed/ThemedSpinner';
import { observer } from 'mobx-react';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

function SettingsTable({
    removingSettings,
    removeSetting,
    settings,
    onChangeSettingValue,
    onChangeSettingKey,
    additionalSection,
    keyField = 'name',
    valueField = 'value',
    editableKey = false,
}) {
    if (!settings) {
        return null;
    } else {
        if (settings.length > 0) {
            return (
                <table className="simple-table">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Value</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {settings.map((s) => (
                            <>
                                <tr key={s.id}>
                                    {editableKey ? (
                                        <td>
                                            <input
                                                size={30}
                                                value={s[keyField]}
                                                onChange={(e) => {
                                                    onChangeSettingKey(
                                                        s.id,
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                        </td>
                                    ) : (
                                        <td>{s[keyField]} </td>
                                    )}

                                    <td>
                                        <input
                                            size={100}
                                            value={s[valueField]}
                                            onChange={(e) => {
                                                onChangeSettingValue(
                                                    s.id,
                                                    e.target.value
                                                );
                                            }}
                                        />
                                    </td>

                                    <td className="text-center">
                                        {removingSettings.includes(s.id) ? (
                                            <ThemedSpinner size={25} />
                                        ) : (
                                            <IconButton
                                                aria-label="delete"
                                                onClick={() =>
                                                    removeSetting(s.id)
                                                }
                                                color="secondary"
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        )}
                                    </td>
                                </tr>
                                {additionalSection && (
                                    <tr>
                                        <td colSpan={2}>
                                            {additionalSection(s)}
                                        </td>
                                    </tr>
                                )}
                            </>
                        ))}
                    </tbody>
                </table>
            );
        } else {
            return null;
        }
    }
}

SettingsTable.propTypes = {
    removeSetting: PropTypes.func,
};

export default observer(SettingsTable);
