import { makeAutoObservable } from 'mobx';
import services from '../services';

import { generateLoadEntity } from '../utils/mobx';
import Product from './models/product';

class BuildsStore {
    constructor() {
        makeAutoObservable(this);
    }

    rawResult = null;
    loadingBuilds = false;
    error = null;

    setError(type, error) {
        this.error = error;
    }

    get builds() {
        if (!this.rawResult) return [];
        return this.rawResult.builds;
    }

    get my() {
        if (!this.rawResult) return null;
        return new Product('my', this.rawResult.my_ver, this.builds);
    }

    get lms() {
        if (!this.rawResult) return null;
        return new Product('lms', this.rawResult.lms_ver, this.builds);
    }

    get api() {
        if (!this.rawResult) return null;
        return new Product('api', this.rawResult.api_ver, this.builds);
    }

    loadBuilds = generateLoadEntity(
        'builds',
        this,
        'loadingBuilds',
        async (options) => {
            return services.Builds.list(options);
        },
        'rawResult'
    );
}

export default BuildsStore;
