import React, { useEffect } from 'react';
import { formatDate } from '../../utils/helpers';
import ThemedSpinner from '../../components/themed/ThemedSpinner';
import { useStores } from '../../hooks/use-stores';
import { useParams } from 'react-router';
import { observer } from 'mobx-react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import SimpleTable from '../../components/super/SimpleTable';

function CompanyPPVLogView() {
    const { superStore, authStore } = useStores();
    let { companyId } = useParams();
    const { loadingPPVLog, ppvLog } = superStore;
    const { token } = authStore;
    const csvUrl = `${import.meta.env.REACT_APP_API_URL}/companies/${companyId}/ppv/export/?token=${token}`;

    const loadData = async () => {
        await superStore.loadPPVLog(companyId);
    };

    useEffect(() => {
        loadData();
    }, []);

    const eventType = (t) => {
        switch (t) {
            case 1:
                return 'Signature';
            case 2:
                return 'View';
            case 3:
                return 'Error';
            default:
                return 'Other';
        }
    };

    const columns = React.useMemo(
        () => [
            {
                Header: 'Created',
                accessor: 'timestamp',
                Cell: (x) => formatDate(x.value),
            },
            {
                Header: 'Event Type',
                accessor: 'event_type',
                Cell: (x) => eventType(x.value),
            },
            {
                Header: 'Subject',
                accessor: 'subject_title',
            },
            {
                Header: "Client's IP",
                accessor: 'client_ip',
            },
            {
                Header: 'JW Video ID',
                accessor: 'js_video_id',
            },
            {
                Header: 'Location',
                accessor: 'locaction',
            },
            {
                Header: 'Uid',
                accessor: 'uid',
            },
            {
                Header: 'ApiKey',
                accessor: 'api_key',
            },
        ],
        []
    );

    return (
        <Grid
            container
            direction="row"
            justify="center"
            align="center"
            spacing={2}
        >
            <Grid item xs={12}>
                <Box mt={4}>
                    <Typography variant="h2">PPV views usage</Typography>
                </Box>
            </Grid>
            {ppvLog.length > 0 && (
                <Grid item xs={12}>
                    <Box mb={2}>
                        <Button
                            variant="contained"
                            color="primary"
                            href={csvUrl}
                            as={'a'}
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            Export
                        </Button>
                    </Box>
                </Grid>
            )}
            {loadingPPVLog ? (
                <ThemedSpinner />
            ) : (
                <Grid item xs={12}>
                    {ppvLog && ppvLog.length > 0 ? (
                        <SimpleTable
                            columns={columns}
                            data={ppvLog}
                            needsFilter={true}
                        />
                    ) : (
                        <Typography variant="body1" gutterBottom>
                            No logs
                        </Typography>
                    )}
                </Grid>
            )}
        </Grid>
    );
}

export default observer(CompanyPPVLogView);
