import React, { useEffect } from 'react';
import { formatDate } from '../../utils/helpers';
import ThemedSpinner from '../../components/themed/ThemedSpinner';
import { useStores } from '../../hooks/use-stores';
import { useParams } from 'react-router';
import { observer } from 'mobx-react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import SimpleTable from '../../components/super/SimpleTable';

function CompanyPPVTransactionsView() {
    const { superStore } = useStores();
    let { companyId } = useParams();
    const { loadingPPVTransactions, ppvTransactions } = superStore;

    useEffect(() => {
        loadData();
    }, [companyId]);

    const loadData = async () => {
        await superStore.loadPPVTransactions(companyId);
    };

    const columns = React.useMemo(
        () => [
            {
                Header: 'Created',
                accessor: 'created_at',
                Cell: (x) => formatDate(x.value),
            },
            {
                Header: 'Amount',
                accessor: 'amount',
            },
            {
                Header: 'Source',
                accessor: 'source',
            },
            {
                Header: 'Transaction ID',
                accessor: 'transaction_id',
            },
            {
                Header: 'Notes',
                accessor: 'notes',
            },
        ],
        []
    );

    return (
        <Grid
            container
            direction="row"
            justify="center"
            align="center"
            spacing={2}
        >
            <Grid item xs={12}>
                <Box mt={4}>
                    <Typography variant="h2">PPV transactions</Typography>
                </Box>
            </Grid>
            {loadingPPVTransactions ? (
                <ThemedSpinner />
            ) : (
                <Grid item xs={12}>
                    {ppvTransactions && ppvTransactions.length > 0 ? (
                        <SimpleTable
                            columns={columns}
                            data={ppvTransactions}
                            needsFilter={true}
                        />
                    ) : (
                        <Typography variant="body1">No transactions</Typography>
                    )}
                </Grid>
            )}
        </Grid>
    );
}

export default observer(CompanyPPVTransactionsView);
