import React, { useEffect } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import ErrorMessage from '../../components/errormessage';
import ThemedSpinner from '../../components/themed/ThemedSpinner';
import { enforceSlash } from '../../utils/helpers';
import { useStores } from '../../hooks/use-stores';
import { observer } from 'mobx-react';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import { Box } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import SimpleTable from '../../components/super/SimpleTable';

function Categories() {
    const { superStore } = useStores();
    let match = useRouteMatch();
    const { loadingCategories, categories, categoriesError } = superStore;

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        superStore.loadCategories();
    };

    const columns = React.useMemo(
        () => [
            {
                Header: 'Native Name',
                Cell: (x) => (
                    <Link to={`${enforceSlash(match.url)}${x.row.original.id}`}>
                        {x.value}
                    </Link>
                ),
                accessor: 'name',
            },
            {
                Header: '# of Rules',
                accessor: 'rulesCount',
            },
            {
                Header: 'Public',
                accessor: 'isPublic',
                Cell: (x) =>
                    x.value > 0 ? (
                        <DoneIcon color="primary" />
                    ) : (
                        <ClearIcon color="secondary" />
                    ),
            },
            {
                Header: '# of Subjects',
                accessor: 'subjects',
            },
            {
                Header: 'Id',
                accessor: 'id',
                Cell: (x) => (
                    <Link to={`${enforceSlash(match.url)}${x.value}`}>
                        {x.value}
                    </Link>
                ),
            },
            {
                Header: 'Order',
                accessor: 'order',
            },
        ],
        []
    );

    return (
        <Grid
            container
            direction="row"
            justify="center"
            align="center"
            spacing={2}
        >
            <Grid item xs={12}>
                <Box mt={4}>
                    <Typography variant="h2">Categories</Typography>
                </Box>
            </Grid>
            {loadingCategories && categories && categories.length > 0 ? (
                <ThemedSpinner />
            ) : (
                <Grid item xs={12}>
                    <SimpleTable
                        columns={columns}
                        data={categories}
                        needsFilter={true}
                    />
                </Grid>
            )}
            {categoriesError && (
                <Grid item>
                    <ErrorMessage error={categoriesError} />
                </Grid>
            )}
        </Grid>
    );
}

export default observer(Categories);
