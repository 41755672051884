import React, { useState } from 'react';
import moment from 'moment';
import ThemedSpinner from '../themed/ThemedSpinner';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import { TextField } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

function AddPackage({ addingPackage, savePackage, subscriptions }) {
    const [packageUsers, setPackageUsers] = useState(100);
    const [packageValidUntil, setPackageValidUntil] = useState(0);
    const [packageValidFrom, setPackageValidFrom] = useState(
        moment(new Date()).format('YYYY-MM-DD')
    );
    const [packageValidTo, setPackageValidTo] = useState(
        moment(new Date()).add(2, 'weeks').format('YYYY-MM-DD')
    );
    const [packageTrial, setPackageTrial] = useState(0);
    const [freemiumPackage] = useState('');
    const [subscription, setPackageSubscription] = useState('partnerTraining');

    const onChangeUsers = (e) => {
        setPackageUsers(e.target.value);
    };

    const changePackageValidFrom = (e) => {
        setPackageValidFrom(e.target.value);
        setPackageValidUntil('-1');
    };

    const changePackageValidTo = (e) => {
        setPackageValidTo(e.target.value);
        setPackageValidUntil('-1');
    };

    const onChangeValidUntil = (e) => {
        setPackageValidUntil(e.target.value);
        if (e.target.value.length > 0) {
            if (e.target.value !== '-1') {
                let valid_from = new Date();
                let valid_to;
                if (e.target.value === '0') {
                    valid_to = moment(valid_from).add(2, 'weeks');
                } else if (e.target.value === '1200') {
                    //Special 'Eternal' case where we don't expect this package to expire
                    valid_to = new Date('2200-1-1');
                } else {
                    valid_to = moment(valid_from).add(
                        parseInt(e.target.value),
                        'months'
                    );
                }
                setPackageValidFrom(moment(valid_from).format('YYYY-MM-DD'));
                setPackageValidTo(moment(valid_to).format('YYYY-MM-DD'));
            }
        }
    };

    const save = async () => {
        let valid_from = new Date(packageValidFrom);
        let valid_to = new Date(packageValidTo);
        savePackage(
            packageUsers,
            valid_from,
            valid_to,
            packageTrial,
            freemiumPackage,
            subscription
        );
    };

    return (
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={2}
        >
            <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                    Add new package
                </Typography>
            </Grid>
            <Grid item>
                <FormControl variant="filled">
                    <InputLabel>Subscription plan</InputLabel>
                    <Select
                        variant="filled"
                        name="subscription"
                        value={subscription}
                        onChange={(e) => setPackageSubscription(e.target.value)}
                    >
                        {subscriptions &&
                            subscriptions.map((s) => (
                                <MenuItem key={s.code} value={s.code}>
                                    {s.code}
                                </MenuItem>
                            ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item>
                <TextField
                    variant="filled"
                    name="packageUsers"
                    label="Max # of users"
                    value={packageUsers}
                    onChange={(e) => onChangeUsers(e)}
                    type="number"
                />
            </Grid>
            <Grid item>
                <FormControl variant="filled">
                    <InputLabel>Trial</InputLabel>
                    <Select
                        variant="filled"
                        name="packageTrial"
                        value={packageTrial}
                        onChange={(e) => {
                            setPackageTrial(e.target.value);
                        }}
                    >
                        <MenuItem value="0">No</MenuItem>
                        <MenuItem value="1">Yes</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            {/* {freemiums && freemiums.length > 0
                && <Grid item xs="2" xl="1">
                    <FormControl
                        variant="filled"
                        fullWidth
                    >
                        <InputLabel>Freemium</InputLabel>
                        <Select
                            variant="filled"
                            name="freemiumPackage"
                            value={freemiumPackage}
                            onChange={(e) => {setFreemiumPackage(e.target.value)}}
                        >
                            <MenuItem value="">No</MenuItem>
                            {freemiums.map((f, i) =>
                                <MenuItem
                                    key={i}
                                    value={f.code}
                                >
                                    {f.code}
                                </MenuItem>
                            )}
                        </Select>
                    </FormControl>
                </Grid>
            } */}
            <Grid item>
                <FormControl variant="filled">
                    <InputLabel>Valid</InputLabel>
                    <Select
                        variant="filled"
                        name="packageValidUntil"
                        value={packageValidUntil}
                        onChange={(e) => onChangeValidUntil(e)}
                    >
                        <MenuItem value="0">2 weeks</MenuItem>
                        <MenuItem value="1">1 month</MenuItem>
                        <MenuItem value="3">3 months</MenuItem>
                        <MenuItem value="6">6 months</MenuItem>
                        <MenuItem value="12">1 year</MenuItem>
                        <MenuItem value="18">18 months</MenuItem>
                        <MenuItem value="24">2 years</MenuItem>
                        <MenuItem value="60">5 years</MenuItem>
                        <MenuItem value="120">10 years</MenuItem>
                        <MenuItem value="1200">Eternal</MenuItem>
                        <MenuItem value="-1">Custom</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item>
                <TextField
                    variant="filled"
                    name="validFrom"
                    label="Valid from"
                    value={packageValidFrom}
                    onChange={changePackageValidFrom}
                    type="date"
                />
            </Grid>
            <Grid item>
                <TextField
                    variant="filled"
                    name="validTo"
                    label="Valid to"
                    value={packageValidTo}
                    onChange={changePackageValidTo}
                    type="date"
                />
            </Grid>
            <Grid item>
                {addingPackage ? (
                    <ThemedSpinner />
                ) : (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={save}
                        startIcon={<AddIcon />}
                    >
                        Add
                    </Button>
                )}
            </Grid>
        </Grid>
    );
}

export default AddPackage;
