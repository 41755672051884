import React, { useEffect } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import ErrorMessage from '../../components/errormessage';
import ThemedSpinner from '../../components/themed/ThemedSpinner';
import { enforceSlash } from '../../utils/helpers';
import { useStores } from '../../hooks/use-stores';
import { observer } from 'mobx-react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import SimpleTable from '../../components/super/SimpleTable';
import { Fab } from '@material-ui/core';
import { Tooltip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
    fab: {
        position: 'fixed',
        bottom: theme.spacing(4),
        right: theme.spacing(4),
    },
}));

function Questions() {
    const { superStore, commonStore } = useStores();
    const {
        loadingQuestions,
        questions,
        questionsError,
        updatingQuestionsMaxScore,
        questionsMaxScoreUpdated,
    } = superStore;
    let match = useRouteMatch();
    const classes = useStyles();

    const loadData = async () => {
        superStore.loadQuestions();
    };

    const updateQuestionsMaxScore = async () => {
        await superStore.updateQuestionsMaxScore({});
        if (questionsMaxScoreUpdated) {
            commonStore.success('Max scores were updated successfully');
        }
    };

    useEffect(() => {
        loadData();
    }, []);

    const columns = React.useMemo(
        () => [
            {
                Header: 'Id',
                accessor: 'id',
                Cell: (x) => (
                    <Link to={`${enforceSlash(match.url)}${x.value}/edit`}>
                        {x.value}
                    </Link>
                ),
            },
            {
                Header: 'Native Name',
                accessor: 'name',
                Cell: (x) => (
                    <Link
                        to={`${enforceSlash(match.url)}${
                            x.row.original.id
                        }/edit`}
                    >
                        {x.value}
                    </Link>
                ),
            },
            {
                Header: 'Public',
                accessor: 'isPublic',
                Cell: (x) =>
                    x.value > 0 ? (
                        <DoneIcon color="primary" />
                    ) : (
                        <ClearIcon color="secondary" />
                    ),
            },
            {
                Header: 'Code',
                accessor: 'external_id',
            },
            {
                Header: 'Max Score',
                accessor: 'max_score',
            },
            {
                Header: 'Difficulty',
                accessor: 'difficulty',
            },
            {
                Header: 'Order',
                accessor: 'order_number',
            },
        ],
        []
    );

    return (
        <Grid
            container
            direction="row"
            justify="center"
            align="center"
            spacing={2}
        >
            <Grid item xs={12}>
                <Box mt={4}>
                    <Typography variant="h2">Questions</Typography>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box mb={4}>
                    {updatingQuestionsMaxScore ? (
                        <ThemedSpinner />
                    ) : (
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={updateQuestionsMaxScore}
                        >
                            Update questions max score
                        </Button>
                    )}
                </Box>
            </Grid>
            <Tooltip title="Create question" aria-label="add" placement="left">
                <Fab
                    color="primary"
                    href="/content/questions/create"
                    className={classes.fab}
                >
                    <AddIcon />
                </Fab>
            </Tooltip>
            {loadingQuestions ? (
                <ThemedSpinner />
            ) : questions && questions.length > 0 ? (
                <Grid item xs={12}>
                    <SimpleTable
                        columns={columns}
                        data={questions}
                        needsFilter={true}
                    />
                </Grid>
            ) : (
                <Grid item xs={12}>
                    No questions found :(
                </Grid>
            )}
            {questionsError && (
                <Grid item>
                    <ErrorMessage error={questionsError} />
                </Grid>
            )}
        </Grid>
    );
}

export default observer(Questions);
