import RestService from './RestService';
import Users from './UsersService';

export default class Campaigns extends RestService {
    constructor(parentService, parentId) {
        if (parentService && parentId) {
            super(`${parentService.baseUrl}/${parentId}/campaigns`);
        } else super('/campaigns');
    }

    sendPreview(campaignId, data) {
        return this.post(`${this.baseUrl}/${campaignId}/sendPreview`, data);
    }

    loadByUID(uid) {
        return this.get(`${this.baseUrl}/by-uid/${uid}`);
    }

    send(campaignId, data) {
        return this.post(`${this.baseUrl}/${campaignId}/send`, data);
    }

    addUser(campaignId, data) {
        return this.post(`${this.baseUrl}/${campaignId}/addUser`, data);
    }

    deleteUser(campaignId, userEmail) {
        return this.del(
            `${this.baseUrl}/${campaignId}/user/${encodeURIComponent(
                userEmail
            )}`
        );
    }

    usersService(uid) {
        return new Users(this, uid);
    }
}
