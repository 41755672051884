import { makeAutoObservable, runInAction } from 'mobx';
import services from '../services';
import {
    generateCreateEntity,
    generateLoadEntity,
    generateLoadList,
    generateUpdateEntity,
} from '../utils/mobx';

class VideosStore {
    constructor() {
        makeAutoObservable(this);
    }

    scheduledTasks = [];
    loadingTasks = false;
    loadingScheduledTask = false;
    creatingTask = false;
    runningReport = false;
    updatingTasks = [];
    error = null;
    editingScheduledTask = null;
    editingTaskDefinition = null;
    editingTaskRrule = null;

    setError(type, error) {
        this.error = error;
    }

    setDefinition(v) {
        this.editingTaskDefinition = v;
    }

    setRrule(v) {
        this.editingTaskRrule = v;
    }

    runReport = generateCreateEntity(
        'scheduledTasks',
        this,
        'runningReport',
        async (id) => {
            return services.ScheduledTasks.run(id);
        }
    );

    scheduleNext = generateCreateEntity(
        'scheduledTasks',
        this,
        'runningReport',
        async (id) => {
            return services.ScheduledTasks.scheduleNext(id);
        }
    );

    loadScheduledTasks = generateLoadList(
        'scheduledTasks',
        this,
        'loadingTasks',
        async () => {
            return services.ScheduledTasks.list();
        },
        'scheduledTasks'
    );

    loadScheduledTask = generateLoadEntity(
        'scheduledTasks',
        this,
        'loadingScheduledTask',
        async (id) => {
            let q = await services.ScheduledTasks.fetch(id);
            runInAction(() => {
                this.editingTaskDefinition =
                    typeof q.definition === 'string'
                        ? q.definition
                        : JSON.stringify(q.definition, null, 2);

                this.editingTaskRrule = q.rrule;
            });
            return q;
        },
        'editingScheduledTask'
    );

    updateTask = generateUpdateEntity(
        'scheduledTasks',
        this,
        'updatingTasks',
        async (id, data) => {
            return services.ScheduledTasks.update(data, id);
        }
    );
    createTask = generateCreateEntity(
        'scheduledTasks',
        this,
        'creatingTask',
        async (data) => {
            return services.ScheduledTasks.create(data);
        }
    );
}

export default VideosStore;
