import React, { useEffect, Fragment } from 'react';
import { observer } from 'mobx-react';
import { Link, useParams } from 'react-router-dom';
import ThemedSpinner from '../../components/themed/ThemedSpinner';
import { useStores } from '../../hooks/use-stores';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import SimpleTable from '../../components/super/SimpleTable';

function VideosList() {
    const { videosStore } = useStores();
    let { tags } = useParams();
    const { loadingVideos, videos } = videosStore;

    const loadData = async () => {
        videosStore.loadVideos(tags);
    };

    useEffect(() => {
        loadData();
    }, [tags]);

    const columns = React.useMemo(
        () => [
            {
                Header: 'Video',
                accessor: 'title',
            },
            {
                Header: 'Tags',
                accessor: 'tags',
            },
            {
                Header: 'Key',
                accessor: 'key',
            },
            {
                Header: 'Subtitles',
                accessor: function SubtitlesCell (x) {
                    return (
                        <Fragment>
                            {x.subtitles
                                .splice(0, x.subtitles.length - 1)
                                .map((c, i, arr) => (
                                    <Fragment key={c}>
                                        {/* don't generate a comma after last language */}
                                        {c}
                                        {i !== arr.length - 1 ? ', ' : ''}
                                    </Fragment>
                                ))}
                        </Fragment>
                    );
                },
            },
            {
                Header: 'Link',
                accessor: 'link',
                Cell:  function LinkCell (x) {
                    return <a href={x.value} target={'_blank'} rel="noreferrer">
                        Go
                    </a>
                },
            },
        ],
        []
    );

    return (
        <Grid
            container
            direction="row"
            justify="center"
            align="center"
            spacing={2}
        >
            <Grid item xs={12}>
                <Box mt={4}>
                    <Typography variant="h2" gutterBottom>
                        Video assets
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body1" gutterBottom>
                    To search you just change the last item in the path up there
                    in the browser&apos;s address bar.
                    <br />
                    You can search for any tag used for our videos, using comma
                    to separate tags.
                    <br />
                    Examples: <Link to={'/videos/english'}>
                        /english
                    </Link> | <Link to={'/videos/en-uk'}>/en-uk</Link> |{' '}
                    <Link to={'/videos/íslenska'}>/íslenska</Link> |{' '}
                    <Link to={'/videos/S01E01'}>/S01E01</Link> |{' '}
                    <Link to={'/videos/S01E01,norwegian'}>
                        /S01E01,norwegian
                    </Link>
                </Typography>
            </Grid>
            {loadingVideos ? (
                <ThemedSpinner />
            ) : videos && videos.length > 0 ? (
                <Fragment>
                    <Grid item xs={12}>
                        <SimpleTable
                            columns={columns}
                            data={videos}
                            needsFilter={videos.length > 5}
                        />
                    </Grid>
                </Fragment>
            ) : (
                <Grid item xs={12}>
                    <Typography>No videos found :(</Typography>
                </Grid>
            )}
        </Grid>
    );
}

export default observer(VideosList);
