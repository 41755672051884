import { makeAutoObservable } from 'mobx';
import uniq from 'lodash/uniq';
import orderBy from 'lodash/orderBy';

class Product {
    constructor(name, version, builds) {
        this.name = name;
        this.version = version;
        this.builds = orderBy(
            builds.filter((x) => x.product === name),
            ['created'],
            ['desc']
        );
        makeAutoObservable(this);
    }

    name = null;
    version = null;
    builds = [];
    selectedBranch = null;
    selectedCommit = null;

    setError(type, error) {
        this.error = error;
    }

    setBranch(value) {
        this.selectedBranch = value;
        this.selectedCommit = null;
    }

    setCommit(value) {
        this.selectedCommit = value;
    }

    get currentBranch() {
        return this.version.split(':')[2];
    }

    get currentCommit() {
        return this.version.split(':')[1];
    }

    get currentDescription() {
        let b = this.builds.find(
            (x) =>
                x.commit === this.currentCommit &&
                x.branch === this.currentBranch
        );
        return b && b.description;
    }

    get deployCommand() {
        if (!this.selectedBranch || !this.selectedCommit) return null;
        let separator = this.name === 'api' ? '.' : ':';
        let label = `${this.name}${separator}${this.selectedCommit}${separator}${this.selectedBranch}${separator}beta`;
        return `/deploy ${this.name} ${label} beta`;
    }

    get branches() {
        return uniq(this.builds.map((x) => x.branch));
    }

    get buildsByBranch() {
        return this.builds.filter((x) => x.branch === this.selectedBranch);
    }
}

export default Product;
