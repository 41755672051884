import React, { Fragment } from 'react';
import { Link, Route, Switch, useRouteMatch } from 'react-router-dom';
import Subjects from './Subjects';
import Templates from './Templates';
import Questions from './Questions';
import Categories from './Categories';
import { enforceSlash } from '../../utils/helpers';
import Subject from './Subject';
import Template from './Template';
import Question from './Question';
import Category from './Category';
import CategorySubjects from './CategorySubjects';
import TemplateSubjects from './TemplateSubjects';
import SubjectCategories from './SubjectCategories';
import SubjectTemplates from './SubjectTemplates';
import { observer } from 'mobx-react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { firebaseTabsStylesHook } from '@mui-treasury/styles/tabs';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Material from './Material';
import Materials from './Materials';
import ErrorBoundary from '../../hocs/ErrorBoundary';
import Tags from './Tags';
import ThreatAreas from './ThreatAreas';
import ThreatArea from './ThreatArea';

function Content() {
    let match = useRouteMatch();
    const [tabIndex, setTabIndex] = React.useState(null);
    const tabsStyles = firebaseTabsStylesHook.useTabs();
    const tabItemStyles = firebaseTabsStylesHook.useTabItem();

    return (
        <Fragment>
            <AppBar position="static">
                <Tabs
                    classes={tabsStyles}
                    value={tabIndex}
                    onChange={(e, index) => setTabIndex(index)}
                    variant="scrollable"
                    scrollButtons="auto"
                >
                    <Tab
                        classes={tabItemStyles}
                        label={'Subjects'}
                        component={Link}
                        to={`${enforceSlash(match.url)}subjects`}
                    />
                    <Tab
                        classes={tabItemStyles}
                        label={'Templates'}
                        component={Link}
                        to={`${enforceSlash(match.url)}templates`}
                    />
                    <Tab
                        classes={tabItemStyles}
                        label={'Categories'}
                        component={Link}
                        to={`${enforceSlash(match.url)}categories`}
                    />
                    <Tab
                        classes={tabItemStyles}
                        label={'Questions'}
                        component={Link}
                        to={`${enforceSlash(match.url)}questions`}
                    />
                    <Tab
                        classes={tabItemStyles}
                        label={'Materials'}
                        component={Link}
                        to={`${enforceSlash(match.url)}materials`}
                    />
                    <Tab
                        classes={tabItemStyles}
                        label={'Threat Areas'}
                        component={Link}
                        to={`${enforceSlash(match.url)}threatareas`}
                    />
                </Tabs>
            </AppBar>
            <Switch>
                <Route path={`${enforceSlash(match.url)}`} exact>
                    <Box ml={4}>
                        <KeyboardArrowUpIcon color="primary" />
                        <Typography>Choose a tab</Typography>
                    </Box>
                </Route>
                <Route
                    path={`${enforceSlash(
                        match.url
                    )}subjects/:subjectId/categories`}
                    component={SubjectCategories}
                />
                <Route
                    path={`${enforceSlash(
                        match.url
                    )}subjects/:subjectId/templates`}
                    component={SubjectTemplates}
                />
                <Route
                    path={`${enforceSlash(
                        match.url
                    )}subjects/:subjectId/:languageId?`}
                    render={(props) => {
                        return (
                            <ErrorBoundary>
                                <Subject
                                    subjectId={props.match.params.subjectId}
                                    languageId={props.match.params.languageId}
                                />
                            </ErrorBoundary>
                        );
                    }}
                    component={Subject}
                />
                <Route
                    path={`${enforceSlash(match.url)}subjects`}
                    component={Subjects}
                />
                <Route
                    path={`${enforceSlash(match.url)}questions/create`}
                    component={Question}
                />
                <Route
                    path={`${enforceSlash(
                        match.url
                    )}questions/:questionId/edit`}
                    component={Question}
                />
                <Route
                    path={`${enforceSlash(match.url)}questions`}
                    component={Questions}
                />
                <Route
                    path={`${enforceSlash(
                        match.url
                    )}categories/:categoryId/subjects`}
                    component={CategorySubjects}
                />
                <Route
                    path={`${enforceSlash(match.url)}categories/:categoryId`}
                    component={Category}
                />
                <Route
                    path={`${enforceSlash(match.url)}categories`}
                    component={Categories}
                />
                <Route
                    path={`${enforceSlash(
                        match.url
                    )}templates/:templateId/subjects`}
                    component={TemplateSubjects}
                />
                <Route
                    path={`${enforceSlash(match.url)}templates/:templateId`}
                    component={Template}
                />
                <Route
                    path={`${enforceSlash(match.url)}templates`}
                    component={Templates}
                />

                <Route
                    path={`${enforceSlash(match.url)}materials/create`}
                    component={Material}
                />
                <Route
                    path={`${enforceSlash(
                        match.url
                    )}materials/:materialId/edit`}
                    component={Material}
                />
                <Route
                    path={`${enforceSlash(match.url)}materials`}
                    component={Materials}
                />

                <Route
                    path={`${enforceSlash(match.url)}tags`}
                    component={Tags}
                />

                <Route
                    path={`${enforceSlash(match.url)}threatareas/:id/edit`}
                    component={ThreatArea}
                />

                <Route
                    path={`${enforceSlash(match.url)}threatareas/create`}
                    component={ThreatArea}
                />

                <Route
                    path={`${enforceSlash(match.url)}threatareas`}
                    component={ThreatAreas}
                />
            </Switch>
        </Fragment>
    );
}

export default observer(Content);
