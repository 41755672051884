import React, { useEffect, useState } from 'react';
import { querystring } from '../utils/helpers';
import { Helmet } from 'react-helmet';
import { useStores } from '../hooks/use-stores';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { TextField } from '@material-ui/core';

function SignIn() {
    const [email, setEmail] = useState('');
    const { authStore } = useStores();
    let history = useHistory();

    useEffect(() => {
        authStore.suggestTrial = false;
    }, []);

    const handleEmailChange = (event) => {
        setEmail(event.target.value.trim());
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        let redirect = querystring('redirect') || '/';
        const result = await authStore.signin(email, redirect);
        if (result === 'redirect') return;
        if (result) history.push('/auth/check-email');
    };

    return (
        <Grid
            container
            direction="row"
            justify="center"
            align="center"
            spacing={2}
        >
            <Helmet>
                <title>Sign in</title>
            </Helmet>
            <Grid item xs={12}>
                <Box mt={4}>
                    <Typography variant="h2" gutterBottom>
                        Sign in
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body1" gutterBottom>
                    You will receive an email with a sign in link.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <form onSubmit={handleSubmit}>
                    <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="center"
                        spacing={2}
                    >
                        <Grid item>
                            <TextField
                                name="email"
                                label="Work e-mail"
                                color="primary"
                                variant="filled"
                                type="email"
                                id="email"
                                aria-describedby="emailHelp"
                                value={email}
                                onChange={handleEmailChange}
                            />
                        </Grid>
                        <Grid item>
                            <Button
                                color="primary"
                                variant="contained"
                                type="submit"
                                href="/auth/email/signin"
                                onClick={handleSubmit}
                            >
                                Continue
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Grid>
            {authStore.loginError && (
                <Grid item>
                    <Typography variant="body1" gutterBottom>
                        {authStore.loginError}
                    </Typography>
                </Grid>
            )}
        </Grid>
    );
}

export default observer(SignIn);
