import RestService from './RestService';

export default class Schedule extends RestService {
    constructor(parentService, parentId) {
        if (parentService && parentId)
            super(`${parentService.baseUrl}/${parentId}/schedule`);
        else super('/schedule');
    }

    cleanNotifications(eventId) {
        return this.del(`${this.baseUrl}/${eventId}/notifications`);
    }

    runSchedule() {
        return this.post(this.baseUrl, { action: 'runSchedule' });
    }

    runReminders() {
        return this.post(this.baseUrl, { action: 'runReminders' });
    }
}
