import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import ErrorMessage from '../../components/errormessage';
import AddFreemium from '../../components/super/add-freemium';
import moment from 'moment';
import ThemedSpinner from '../../components/themed/ThemedSpinner';
import { useStores } from '../../hooks/use-stores';
import SimpleTable from '../../components/super/SimpleTable';
import { IconButton } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { Box } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import RefreshIcon from '@material-ui/icons/Refresh';

function FreemiumList() {
    const { superStore } = useStores();
    const { freemiums, error, addingFreemium, removingFreemiums } = superStore;

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        superStore.listFreemiums();
    };

    const addFreemium = (code, validity, limit, users) => {
        superStore.addFreemium(code, validity, limit, users);
    };

    const removeFreemium = (code) => {
        superStore.removeFreemium(code);
    };

    const resetCounter = (code) => {
        superStore.resetCounter(code);
    };

    const columns = [
        {
            Header: 'Code',
            accessor: 'code',
        },
        {
            Header: 'Valid until',
            accessor: 'validity',
            Cell: (x) => moment().add(x.value, 'days').format('LL'),
        },
        {
            Header: 'Max # of registered companies',
            accessor: 'limit',
            Cell: (x) => x.value || 'Unlimited',
        },
        {
            Header: 'Users per registration',
            accessor: 'users',
        },
        {
            Header: 'Registrations',
            accessor: 'count',
        },
        {
            Header: 'Videos',
            accessor: (x) => `${(x.videos || []).length} videos`,
        },
        {
            Header: 'Reset registrations',
            accessor: (x) =>
                removingFreemiums.includes(x.code) ? (
                    <ThemedSpinner size={45} />
                ) : (
                    <IconButton
                        variant="contained"
                        color="default"
                        onClick={() => resetCounter(x.code)}
                    >
                        <RefreshIcon />
                    </IconButton>
                ),
        },
        {
            id: 'delete',
            accessor: (x) =>
                removingFreemiums.includes(x.code) ? (
                    <ThemedSpinner size={45} />
                ) : (
                    <IconButton
                        color="secondary"
                        variant="contained"
                        onClick={() => removeFreemium(x.code)}
                        fontSize="large"
                    >
                        <DeleteIcon />
                    </IconButton>
                ),
        },
    ];

    return (
        <Grid
            container
            direction="row"
            justify="center"
            align="center"
            spacing={2}
        >
            <Grid item xs={12}>
                <Box mt={4}>
                    <Typography variant="h2">Freemiums</Typography>
                </Box>
            </Grid>
            {freemiums && freemiums.length === 0 ? (
                <ThemedSpinner />
            ) : (
                <Grid item xs={12}>
                    <SimpleTable columns={columns} data={freemiums} />
                </Grid>
            )}
            {error && (
                <Grid item xs={12}>
                    <ErrorMessage error={error} />
                </Grid>
            )}
            <Grid item xs={12}>
                <Box mb={4}>
                    <AddFreemium
                        addingFreemium={addingFreemium}
                        addFreemium={addFreemium}
                    />
                </Box>
            </Grid>
        </Grid>
    );
}

export default observer(FreemiumList);
