import React from 'react';
import { observer } from 'mobx-react';
import { Info } from '@material-ui/icons';
import { Popover, Box, Grid2 as Grid } from '@mui/material';
import { Typography } from '@material-ui/core';

function PartnerInfoWithPopover({ partnerId, partnerInfo }) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <>
            <Info onClick={handleClick} />
            <Popover
                id={partnerId}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Box display={'flex'} padding={3} width={500}>
                    <Box
                        width={'50%'}
                        display={'flex'}
                        flexDirection={'column'}
                    >
                        <Typography variant={'caption'}>LMS Domain</Typography>
                        <Typography variant={'subtitle1'} gutterBottom>
                            {partnerInfo.lms_domain}
                        </Typography>
                        <Typography variant={'caption'}>My Domain</Typography>
                        <Typography variant={'subtitle1'} gutterBottom>
                            {partnerInfo.my_domain}
                        </Typography>
                        <Typography variant={'caption'}>
                            Mailgun Domain
                        </Typography>
                        <Typography variant={'subtitle1'}>
                            {partnerInfo.mailgun_domain}
                        </Typography>
                    </Box>
                    <Box
                        width={'50%'}
                        display={'flex'}
                        flexDirection={'column'}
                        gap={1}
                    >
                        <Typography variant={'subtitle1'}>Managers</Typography>
                        {partnerInfo.managers
                            .filter((x) => !x.includes('awarego'))
                            .map((manager) => (
                                <Typography key={manager} variant={'caption'}>
                                    {manager}
                                </Typography>
                            ))}
                    </Box>
                </Box>
            </Popover>
        </>
    );
}

export default observer(PartnerInfoWithPopover);
