import React, { Fragment } from 'react';
import ThemedSpinner from '../themed/ThemedSpinner';
import SimpleTable from './SimpleTable';
import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import {formatDate} from '../../utils/helpers';

function Packages({
    company,
    removingPackages,
    packages,
    removePackage,
    editPath,
}) {
    const columns = [
        {
            Header: 'Name',
            accessor: (x) =>
                (x.external_id && x.external_id.startsWith('partner')) ||
                (x.external_id && x.external_id.startsWith('company'))
                    ? x.external_id
                    : x.package_name,
        },
        {
            Header: 'Chargebee ID',
            accessor: (x) =>
                (x.external_id && x.external_id.startsWith('partner')) ||
                (x.external_id && x.external_id.startsWith('company'))
                    ? 'Not linked'
                    : x.external_id,
        },
        {
            Header: 'Created',
            accessor: 'created',
            Cell: (x) => formatDate(x.value),
        },
        {
            Header: 'Valid from',
            accessor: 'valid_from',
            Cell: (x) => formatDate(x.value),
        },
        {
            Header: 'Valid to',
            accessor: 'valid_to',
            Cell: (x) => formatDate(x.value),
        },
        {
            Header: 'User count',
            accessor: 'users',
        },
        /*{
            Header: 'Package Type',
            accessor: 'full_package',
            Cell: (x) => (x.value === 1 ? 'Full' : 'Partial'),
        },
        {
            Header: 'Videos',
            accessor: (x) =>
                x.full_package ? (
                    'All'
                ) : (
                    <Fragment>{(x.videos || []).length} videos</Fragment>
                ),
        },*/
        {
            Header: 'In Trial',
            accessor: (x) => {
                if (x.trial) {
                    return <strong>TRIAL</strong>;
                }
                if (!x.trial) {
                    return <strong>NO</strong>;
                }
            },
        },
        {
            Header: 'Status',
            accessor: (x) => {
                if (x.valid) {
                    return <strong>VALID</strong>;
                }
                if (!x.valid) {
                    return <strong>EXPIRED</strong>;
                }
            },
        },
        {
            id: 'editPackage',
            accessor: (x) => {
                if (x.valid) {
                    if (x.external_id && x.external_id.startsWith('company')) {
                        return (
                            <Button
                                variant="contained"
                                color="primary"
                                href={`${editPath}editPackage/${x.package_id}`}
                                startIcon={<EditIcon />}
                            >
                                Edit
                            </Button>
                        );
                    } else if (
                        x.external_id &&
                        x.external_id.startsWith('partner')
                    ) {
                        return (
                            <Button
                                variant="contained"
                                color="primary"
                                href={`${import.meta.env.REACT_APP_LMS_URL}/partner/${company.partnerId}/companies/${company.company_id}`}
                                startIcon={<EditIcon />}
                            >
                                Partner portal
                            </Button>
                        );
                    } else {
                        return (
                            <Button
                                variant="contained"
                                color="primary"
                                href={`https://awarego.chargebee.com/`}
                                startIcon={<EditIcon />}
                            >
                                Chargebee
                            </Button>
                        );
                    }
                }
            },
        },
        {
            id: 'removePackage',
            accessor: (x) =>
                removingPackages.includes(x.package_id) ? (
                    <ThemedSpinner />
                ) : (
                    <IconButton
                        aria-label="delete"
                        onClick={() => removePackage(x.package_id)}
                        color="secondary"
                    >
                        <DeleteIcon />
                    </IconButton>
                ),
        },
    ];

    return !packages ? (
        <ThemedSpinner />
    ) : (
        <Fragment>
            {packages.length > 0 && (
                <SimpleTable columns={columns} data={packages} />
            )}
        </Fragment>
    );
}

export default Packages;
