import React from 'react';
import { Fragment } from 'react';
import { useStores } from '../../hooks/use-stores';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { formatDate } from '../../utils/helpers';
import services from '../../services';
import Clipboard from 'react-clipboard.js';
import Countdown from 'react-countdown';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Table from '../../components/super/Table';
import PersonIcon from '@material-ui/icons/Person';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import TimerIcon from '@material-ui/icons/Timer';
//import { makeStyles } from '@material-ui/core/styles';

function UserColumnFilter({ column: { filterValue, setFilter } }) {
    return (
        <FormControl size="small">
            <TextField
                type="text"
                label="Type to search"
                variant="filled"
                value={filterValue || ''}
                size="small"
                placeholder="demo@sample.io"
                onChange={(e) => {
                    setFilter(e.target.value || undefined);
                }}
            />
        </FormControl>
    );
}

/*const useStyles = makeStyles((theme) => ({
    fab: {
        position: 'fixed',
        bottom: theme.spacing(4),
        right: theme.spacing(4),
    },
}));*/

function UsersList() {
    const TOKEN_TIMER = 1 * 60 * 1000;
    const { superStore } = useStores();
    const { managerTokensIndex } = superStore;
    // We'll start our table without any data
    const [data, setData] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [pageCount, setPageCount] = React.useState(0);
    const fetchIdRef = React.useRef(0);
    //const classes = useStyles();

    const impersonate = (manager, companyId) => {
        if (manager.timer) {
            clearTimeout(manager.timer);
            manager.timer = null;
        }
        let link = superStore.impersonate(manager.email, companyId);
        if (link) {
            manager.timer = setTimeout(() => {
                manager.timer = null;
                superStore.removeToken(manager.email);
            }, TOKEN_TIMER);
        }
    };

    const fetchData = React.useCallback(
        async ({ pageSize, pageIndex, filters }) => {
            // This will get called when the table needs new data
            // You could fetch your data from literally anywhere,
            // even a server. But for this example, we'll just fake it.

            // Give this fetch an ID
            const fetchId = ++fetchIdRef.current;
            setLoading(true);
            let filtersObject = {};
            filters.forEach((x) => {
                filtersObject[x.id] = x.value;
            });

            let result = await services.Users.allUsers({
                p: pageIndex,
                n: pageSize,
                s: null,
                f: null,
                fetchId,
                ...filtersObject,
            });

            if (fetchId === fetchIdRef.current) {
                setData(result.rows);

                setPageCount(result.pages);

                setLoading(false);
            }
        },
        []
    );

    const columns = [
        {
            Header: 'Email',
            id: 'userEmail',
            accessor: (x) => <Link to={`/users/${x.email}`}>{x.email}</Link>,
            Filter: UserColumnFilter,
        },
        {
            Header: 'Name',
            id: 'userName',
            accessor: (x) => <Link to={`/users/${x.email}`}>{x.name}</Link>,
            Filter: UserColumnFilter,
        },
        {
            Header: 'MY login',
            accessor: () => (
                <Button
                    variant="contained"
                    color="default"
                    startIcon={<PersonIcon />}
                >
                    MY
                </Button>
            ),
        },
        {
            Header: 'Access',
            accessor: (x) =>
                x.isPartnerAdmin && (
                    <Button variant="disabled" color="default">
                        Partner Admin
                    </Button>
                ),
        },
        {
            Header: ' ',
            id: 'isLMSAdmin',
            accessor: (x) =>
                x.isLMSAdmin && (
                    <Grid
                        container
                        direction="row"
                        alignItems="center"
                        spacing={1}
                    >
                        {!managerTokensIndex[x.email] && (
                            <Grid item>
                                <Button
                                    variant="contained"
                                    color="default"
                                    onClick={() => {
                                        impersonate(x, 1);
                                    }}
                                    startIcon={<PersonIcon />}
                                >
                                    LMS Admin
                                </Button>
                            </Grid>
                        )}

                        {managerTokensIndex[x.email] &&
                            managerTokensIndex[x.email].access_link && (
                                <Fragment>
                                    <Grid item>
                                        <Clipboard
                                            className="copy-to-clipboard-link"
                                            data-clipboard-text={
                                                managerTokensIndex[x.email]
                                                    .access_link
                                            }
                                        >
                                            <Button
                                                variant="contained"
                                                startIcon={<FileCopyIcon />}
                                            >
                                                Copy sign in link
                                            </Button>
                                        </Clipboard>
                                    </Grid>
                                    <Grid item>
                                        <TimerIcon color="primary" />
                                    </Grid>
                                    <Grid item>
                                        <Countdown
                                            date={Date.now() + TOKEN_TIMER}
                                            renderer={(props) => (
                                                <Fragment>
                                                    {props.minutes
                                                        .toString()
                                                        .padStart(2, '0') +
                                                        ':' +
                                                        props.seconds
                                                            .toString()
                                                            .padStart(2, '0')}
                                                </Fragment>
                                            )}
                                        />
                                    </Grid>
                                </Fragment>
                            )}
                    </Grid>
                ),
        },
        {
            Header: 'Integrations',
            accessor: (x) =>
                x.hasADSync && (
                    <Button variant="disabled" color="default">
                        AD
                    </Button>
                ),
        },
        {
            Header: ' ',
            id: 'hasGoogleSync',
            accessor: (x) =>
                x.hasGoogleSync && (
                    <Button variant="disabled" color="default">
                        Google
                    </Button>
                ),
        },
        {
            Header: 'On list in companies',
            accessor: 'listCompanies',
        },
        {
            Header: 'Created',
            accessor: (x) => formatDate(x.created),
        },
        {
            Header: 'Language',
            accessor: 'language',
        },
        {
            Header: 'Id',
            accessor: 'id',
        },
    ];
    return (
        <Grid
            container
            direction="row"
            justify="center"
            align="center"
            spacing={2}
        >
            <Grid item xs={12}>
                <Box mt={4}>
                    <Typography variant="h2">Users</Typography>
                </Box>
            </Grid>

            <Grid item xs={12}>
                <Table
                    columns={columns}
                    data={data}
                    fetchData={fetchData}
                    loading={loading}
                    pageCount={pageCount}
                />
            </Grid>
        </Grid>
    );
}

export default observer(UsersList);
