import React, { useState } from 'react';
import { observer } from 'mobx-react';
import ThemedSpinner from '../themed/ThemedSpinner';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { TextField } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ErrorMessage from '../errormessage';

function AddFreemium({ addingFreemium, addFreemium }) {
    const [code, setCode] = useState('');
    const [codeError, setCodeError] = useState('');
    const [validity, setValidity] = useState(30);
    const [users, setUsers] = useState(150);
    const [error, setError] = useState('');
    const [limit, setLimit] = useState(null);

    const changeCode = (e) => {
        setCode(e.target.value);
        setError('');
        if (code.length < 3) {
            // too short
            setCodeError('Name needs to be at least 4 characters.');
        } else {
            setCodeError('');
        }
    };

    const save = () => {
        if (!codeError) addFreemium(code, validity, limit, users);
    };

    return (
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={2}
        >
            <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                    Add new freemium
                </Typography>
            </Grid>
            <Grid item>
                <TextField
                    variant="filled"
                    name="freemiumCode"
                    label="Code"
                    value={code}
                    onChange={(e) => changeCode(e)}
                    type="text"
                />
            </Grid>
            <Grid item>
                <TextField
                    variant="filled"
                    name="validity"
                    label="Valid for (days)"
                    value={validity}
                    onChange={(e) => setValidity(e.target.value)}
                    type="number"
                />
            </Grid>
            <Grid item>
                <TextField
                    variant="filled"
                    name="registrationslimit"
                    label="Max registrations"
                    value={limit}
                    onChange={(e) => setLimit(e.target.value)}
                    type="number"
                    placeholder="Use 0 for unlimited"
                    fullWidth
                />
            </Grid>
            <Grid item>
                <TextField
                    variant="filled"
                    name="users"
                    label="Users per registration"
                    value={users}
                    onChange={(e) => setUsers(e.target.value)}
                    type="number"
                />
            </Grid>
            <Grid item>
                {addingFreemium ? (
                    <ThemedSpinner />
                ) : (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={save}
                        startIcon={<AddIcon />}
                        type="submit"
                    >
                        Add
                    </Button>
                )}
            </Grid>
            {error && (
                <Grid item>
                    <ErrorMessage error={error} />
                </Grid>
            )}
        </Grid>
    );
}

export default observer(AddFreemium);
