import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import moment from 'moment/moment';

function MrrChart({ partnerId, metrics }) {
    const chartOptions = useMemo(() => {
        return {
            chart: {
                type: 'spline',
                panning: false,
                height: 50,
                width: 200,
                margin: 0,
                scrollablePlotArea: {
                    minWidth: 200,
                    minHeight: 50,
                },
                backgroundColor: {
                    linearGradient: [0, 0, 0, 110],
                    stops: [
                        [0, 'rgb(240, 246, 246, 0.0)'],
                        [1, 'rgb(240, 246, 246, 0.2)'],
                    ],
                },
            },
            colors: ['#82C9AB', '#F1D47A', '#FC814A'],
            title: {
                text: '',
            },

            plotOptions: {
                series: {
                    animation: {
                        duration: 1000,
                    },
                    lineWidth: 1.5,
                },
            },
            credits: {
                enabled: false,
            },
            legend: {
                enabled: false,
            },
            yAxis: {
                title: {
                    text: '',
                },
                enabled: false,
                labels: {
                    enabled: false,
                },
            },
            xAxis: {
                enabled: false,
                labels: {
                    enabled: false,
                },
            },

            tooltip: {
                useHTML: true,
                formatter: function () {
                    const categories = metrics
                        .slice()
                        .reverse()
                        .map((x) => moment(x.timestamp).format('MMMM YYYY'));
                    return (
                        '<span style="font-size: 10px">' +
                        categories[this.point.index] +
                        '</span>' +
                        ' : <b>' +
                        this.y +
                        '</b>'
                    );
                },
            },

            series: [
                {
                    margin: 0,
                    maxPadding: 0,
                    width: 200,
                    maxWidth: 200,
                    type: 'area',
                    yAxis: 0,
                    name: '',
                    data: metrics
                        .slice()
                        .reverse()
                        .map((x) => x.mrr),
                },
            ],
        };
    }, [partnerId, metrics]);

    return <HighchartsReact highcharts={Highcharts} options={chartOptions} />;
}

export default observer(MrrChart);
