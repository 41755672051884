import React, { Fragment, useState, useEffect } from 'react';
import { useRouteMatch, useParams } from 'react-router-dom';
import ErrorMessage from '../../components/errormessage';
import ThemedSpinner from '../../components/themed/ThemedSpinner';
import { enforceSlash } from '../../utils/helpers';
import ACLEditor from './ACLEditor';
import { useStores } from '../../hooks/use-stores';
import { observer } from 'mobx-react';
import SimpleTable from '../../components/super/SimpleTable';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { firebaseTabsStylesHook } from '@mui-treasury/styles/tabs';
import AppBar from '@material-ui/core/AppBar';

function LanguageView() {
    const { superStore, commonStore } = useStores();
    let { languageId } = useParams();
    const {
        loadingCompany,
        editingLanguage,
        languageResources,
        emptyLanguageResources,
        languageSubjects,
        emptyLanguageSubjects,
        error,
    } = superStore;
    let match = useRouteMatch();
    const [tabSubjectIndex, setTabSubjectIndex] = useState(0);
    const [tabResourcesIndex, setTabResourcesIndex] = useState(0);
    const tabsStyles = firebaseTabsStylesHook.useTabs();
    const tabItemStyles = firebaseTabsStylesHook.useTabItem();

    const loadData = async () => {
        await superStore.loadLanguage(languageId);
    };

    useEffect(() => {
        loadData();
    }, [languageId]);

    const unpublish = async () => {
        commonStore.showConfirm(
            'Un-Publish Language. It  will disappear for  all users',
            'Un-Publish',
            'Un-Publish Language',
            async () => {
                await superStore.updateLanguage(editingLanguage.id, {
                    published: 0,
                });
            },
            true,
            'Confirm this unusual action'
        );
    };

    const publish = async () => {
        let confirm = null;
        if (
            editingLanguage.englishResourcesCount !==
                editingLanguage.resourcesCount ||
            emptyLanguageResources.length > 0 ||
            emptyLanguageSubjects.length > 0
        ) {
            confirm =
                'This Language have configuration errors. check this to confirm publish anyway';
        }
        commonStore.showConfirm(
            'Publish Language ' + editingLanguage.name,
            'Publish',
            'Publish Language',
            async () => {
                await superStore.updateLanguage(editingLanguage.id, {
                    published: 1,
                });
            },
            true,
            confirm
        );
    };

    const columns = React.useMemo(
        () => [
            {
                Header: 'Title',
                accessor: 'title',
            },
            {
                Header: 'Description',
                accessor: 'description',
            },
            {
                Header: 'video_id_jw',
                accessor: 'video_id_jw',
            },
            {
                Header: 'EN title',
                accessor: 'enTitle',
            },
            {
                Header: 'EN description',
                accessor: 'enDescription',
            },
        ],
        []
    );

    const columnsLanguageResources = React.useMemo(
        () => [
            {
                Header: 'Key',
                accessor: 'key',
            },
            {
                Header: 'Value',
                accessor: 'value',
            },
        ],
        []
    );

    return loadingCompany || !editingLanguage ? (
        <ThemedSpinner />
    ) : (
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            align="center"
            spacing={2}
        >
            <Grid item xs={12}>
                <Box mt={4}>
                    <Typography variant="h2" gutterBottom>
                        Language{editingLanguage && `: ${editingLanguage.name}`}
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={6} md={2}>
                <Button
                    variant="contained"
                    color="primary"
                    href={`${enforceSlash(match.url)}edit`}
                    startIcon={<EditIcon />}
                >
                    Edit
                </Button>
            </Grid>
            {editingLanguage && (
                <Fragment>
                    <Grid item xs={6} md={2}>
                        <Button
                            variant="contained"
                            color={
                                editingLanguage.published
                                    ? 'secondary'
                                    : 'primary'
                            }
                            onClick={
                                editingLanguage.published ? unpublish : publish
                            }
                        >
                            {editingLanguage.published
                                ? 'Unpublish'
                                : 'Publish'}
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h4" gutterBottom>
                            Access control
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <ACLEditor
                            acl={editingLanguage.acl}
                            unique_id={editingLanguage.unique_id}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h4" gutterBottom>
                            Resources
                        </Typography>
                    </Grid>
                    {editingLanguage.englishResourcesCount !==
                        editingLanguage.resourcesCount && (
                        <Grid item xs={12}>
                            <Typography variant="body1" gutterBottom>
                                {editingLanguage.englishResourcesCount -
                                    editingLanguage.resourcesCount}{' '}
                                resources missed
                            </Typography>
                        </Grid>
                    )}
                    {emptyLanguageResources.length > 0 && (
                        <Fragment>
                            <Grid item xs={12}>
                                <Typography variant="body1" gutterBottom>
                                    {emptyLanguageResources.length} resources
                                    with empty values
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={4} md={3}>
                                <AppBar position="static">
                                    <Tabs
                                        classes={tabsStyles}
                                        value={tabResourcesIndex}
                                        onChange={(e, index) =>
                                            setTabResourcesIndex(index)
                                        }
                                    >
                                        <Tab
                                            classes={tabItemStyles}
                                            label={'Missed'}
                                        />
                                        <Tab
                                            classes={tabItemStyles}
                                            label={'All'}
                                        />
                                    </Tabs>
                                </AppBar>
                            </Grid>
                        </Fragment>
                    )}
                    {emptyLanguageResources.length > 0 &&
                    tabResourcesIndex === 0 ? (
                        <Grid item xs={8}>
                            <SimpleTable
                                columns={columnsLanguageResources}
                                data={emptyLanguageResources}
                            />
                        </Grid>
                    ) : (
                        <Grid item xs={8}>
                            <SimpleTable
                                columns={columnsLanguageResources}
                                data={languageResources}
                            />
                        </Grid>
                    )}
                    {emptyLanguageSubjects.length > 0 && (
                        <Fragment>
                            <Grid item xs={12}>
                                <Typography variant="h4" gutterBottom>
                                    Subjects
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body1" gutterBottom>
                                    {emptyLanguageSubjects.length} subjects
                                    without translation
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={4} md={3}>
                                <AppBar position="static">
                                    <Tabs
                                        classes={tabsStyles}
                                        value={tabSubjectIndex}
                                        onChange={(e, index) =>
                                            setTabSubjectIndex(index)
                                        }
                                    >
                                        <Tab
                                            classes={tabItemStyles}
                                            label={'Missed'}
                                        />
                                        <Tab
                                            classes={tabItemStyles}
                                            label={'All'}
                                        />
                                    </Tabs>
                                </AppBar>
                            </Grid>
                            {emptyLanguageSubjects.length > 0 &&
                            tabSubjectIndex === 0 ? (
                                <Grid item xs={12}>
                                    <SimpleTable
                                        columns={columns}
                                        data={emptyLanguageSubjects}
                                        needsFilter={true}
                                    />
                                </Grid>
                            ) : (
                                <Grid item xs={12}>
                                    <SimpleTable
                                        columns={columns}
                                        data={languageSubjects}
                                        needsFilter={true}
                                    />
                                </Grid>
                            )}
                        </Fragment>
                    )}
                </Fragment>
            )}
            {error && (
                <Grid item xs={12}>
                    <ErrorMessage error={error} />
                </Grid>
            )}
        </Grid>
    );
}

export default observer(LanguageView);
