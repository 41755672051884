import { observable, makeAutoObservable } from 'mobx';
import services from '../services';
import { extractErrorMessage } from '../utils/helpers';

class VideosStore {
    constructor() {
        makeAutoObservable(this);
    }

    videos = observable([]);
    loadingVideos = false;
    error = null;

    async loadVideos(tag) {
        if (this.loadingVideos) return;
        this.error = null;
        try {
            this.loadingVideos = true;
            this.videos = await services.Videos.fetch(tag);
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.loadingVideos = false;
        }
    }
}

export default VideosStore;
