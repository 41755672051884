import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { querystring } from '../utils/helpers';
import { useStores } from '../hooks/use-stores';

function AuthRoute({ component: C, ...rest }) {
    const { authStore } = useStores();
    let redirect = querystring('redirect') || '/';
    if (!redirect.startsWith('/')) redirect = `/${redirect}`;

    return (
        <Route
            {...rest}
            render={(props) =>
                !authStore.isAuthenticated ? (
                    <C {...props} {...rest} />
                ) : (
                    <Redirect
                        to={
                            redirect === '' || redirect === null
                                ? '/'
                                : redirect
                        }
                    />
                )
            }
        />
    );
}

export default AuthRoute;
