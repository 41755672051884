import RestService from './RestService';
import Automations from './AutomationsService';
import Assessments from './AssessmentsService';
import Campaigns from './CampaignsService';
import Subjects from './SubjectsService';
import Packages from './PackagesService';
import Managers from './ManagersService';
import Lists from './ListsService';
import Users from './UsersService';
import Subscriptions from './SubscriptionsService';
import PPVService from './PPVService';
import Mails from './MailsService';
import Categories from './CategoriesService';
import Branding from './BrandingService';
import Templates from './TemplatesService';
import Integrations from './IntegrationsService';
import Settings from './SettingsService';
import Questions from './QuestionsService';

export default class Companies extends RestService {
    constructor(asSuper = false, parentService, parentId) {
        if (parentService && parentId) {
            super(`${parentService.baseUrl}/${parentId}/companies`);
        } else if (asSuper) super('/companies/super');
        else super('/companies');
    }

    myCompanies() {
        return this.get(`${this.baseUrl}/my_companies`);
    }

    dashboardData(companyId) {
        return this.get(`${this.baseUrl}/${companyId}/overview`);
    }

    switchTrackingAnonymous(companyId) {
        return this.post(
            `${this.baseUrl}/${companyId}/switchTrackingAnonymous`
        );
    }

    openManageSession(companyId) {
        return this.post(`${this.baseUrl}/${companyId}/create_portal_session`);
    }

    createPPV(companyId) {
        return this.post(`${this.baseUrl}/${companyId}/ppv`);
    }

    ppvLog(companyId) {
        return this.get(`${this.baseUrl}/${companyId}/ppv_log`);
    }

    ppvTransactions(companyId) {
        return this.get(`${this.baseUrl}/${companyId}/ppv_transactions`);
    }

    addViews(companyId, params) {
        return this.post(`${this.baseUrl}/${companyId}/views`, params);
    }

    newPPVApiKey(companyId) {
        return this.put(`${this.baseUrl}/${companyId}/ppvkey`);
    }

    sendTestEmail(companyId, params) {
        return this.post(`${this.baseUrl}/${companyId}/test_email`, params);
    }

    packagesService(companyId) {
        return new Packages(this, companyId);
    }

    integrationsService(companyId) {
        return new Integrations(this, companyId);
    }

    managersService(companyId) {
        return new Managers(this, companyId);
    }

    settingsService(companyId) {
        return new Settings(this, companyId);
    }

    campaignsService(companyId) {
        return new Campaigns(this, companyId);
    }

    subjectsService(companyId) {
        return new Subjects(this, companyId);
    }

    categoryService(companyId) {
        return new Categories(this, companyId);
    }

    automationsService(companyId) {
        return new Automations(this, companyId);
    }

    assessmentsService(companyId) {
        return new Assessments(this, companyId);
    }

    listsService(companyId) {
        return new Lists(this, companyId);
    }

    usersService(companyId) {
        return new Users(this, companyId);
    }

    subscriptionsService(companyId) {
        return new Subscriptions(this, companyId);
    }

    templatesService(companyId) {
        return new Templates(this, companyId);
    }

    questionsService(companyId) {
        return new Questions(this, companyId);
    }

    PPVService(companyId) {
        return new PPVService(this, companyId);
    }

    BrandingService(companyId) {
        return new Branding(this, companyId);
    }

    super() {
        return new Companies(true);
    }

    mailLogService(companyId) {
        return new Mails(this, companyId);
    }
}
