import React, { useState } from 'react';
import { observer } from 'mobx-react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { useStores } from '../../hooks/use-stores';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Divider, Typography } from '@material-ui/core';

function Cache() {
    const [cacheKey, setCacheKey] = useState('');
    const [companyId, setCompanyId] = useState('');
    const [accountId, setAccountId] = useState('');
    const { superStore } = useStores();
    const { invalidatingCache, readCache, cacheData } = superStore;

    const handleInvalidate = () => {
        superStore.invalidateCacheKey(cacheKey);
    };

    const handleInvalidateSystemSettings = () => {
        superStore.invalidateSystemSettings();
    };

    const handleInvalidateCompanySettings = () => {
        if (Number(accountId)) {
            superStore.invalidateCompanySettings(accountId);
        }
    };

    const handleInvalidateAccountSettings = () => {
        if (Number(accountId)) {
            superStore.invalidateAccountSettings(accountId);
        }
    };

    const handleRead = () => {
        superStore.readCacheKey(cacheKey);
    };

    const handleReadSystemSettings = () => {
        superStore.readSystemSettings();
    };

    const handleReadCompanySettings = () => {
        if (Number(companyId)) {
            superStore.readCompanySettings(companyId);
        }
    };

    const handleReadAccountSettings = () => {
        if (Number(accountId)) {
            superStore.readAccountSettings(accountId);
        }
    };

    const handleChange = (e) => {
        setCacheKey(e.target.value);
    };

    const handleCompanyIdChange = (e) => {
        setCompanyId(e.target.value);
    };

    const handleAccountIdChange = (e) => {
        setAccountId(e.target.value);
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={9}>
                <Typography variant="subtitle1">System Settings</Typography>
            </Grid>
            <Grid item xs={9}>
                {invalidatingCache ? (
                    <CircularProgress />
                ) : (
                    <Button
                        variant={'contained'}
                        color={'primary'}
                        onClick={handleInvalidateSystemSettings}
                    >
                        Invalidate System Settings
                    </Button>
                )}

                {readCache ? (
                    <CircularProgress />
                ) : (
                    <Button
                        variant={'contained'}
                        color={'primary'}
                        onClick={handleReadSystemSettings}
                    >
                        Read System Settings
                    </Button>
                )}
            </Grid>
            <Grid item xs={9}>
                <Divider />
            </Grid>
            <Grid item xs={9}>
                <Typography variant="subtitle1">Company settings</Typography>
            </Grid>
            <Grid item xs={9}>
                <TextField
                    value={companyId}
                    type={'number'}
                    placeholder={'company Id'}
                    onChange={handleCompanyIdChange}
                ></TextField>
                {invalidatingCache ? (
                    <CircularProgress />
                ) : (
                    <Button
                        variant={'contained'}
                        color={'primary'}
                        onClick={handleInvalidateCompanySettings}
                    >
                        Invalidate
                    </Button>
                )}

                {readCache ? (
                    <CircularProgress />
                ) : (
                    <Button
                        variant={'contained'}
                        color={'primary'}
                        onClick={handleReadCompanySettings}
                    >
                        Read
                    </Button>
                )}
            </Grid>{' '}
            <Grid item xs={9}>
                <Divider />
            </Grid>
            <Grid item xs={9}>
                <Typography variant="subtitle1">Account settings</Typography>
            </Grid>
            <Grid item xs={9}>
                <TextField
                    value={accountId}
                    type={'number'}
                    placeholder={'account Id'}
                    onChange={handleAccountIdChange}
                ></TextField>
                {invalidatingCache ? (
                    <CircularProgress />
                ) : (
                    <Button
                        variant={'contained'}
                        color={'primary'}
                        onClick={handleInvalidateAccountSettings}
                    >
                        Invalidate
                    </Button>
                )}

                {readCache ? (
                    <CircularProgress />
                ) : (
                    <Button
                        variant={'contained'}
                        color={'primary'}
                        onClick={handleReadAccountSettings}
                    >
                        Read
                    </Button>
                )}
            </Grid>
            <Grid item xs={9}>
                <Divider />
            </Grid>
            <Grid item xs={9}>
                <Typography variant="subtitle1">
                    Any raw cache key, typically its include app env, for
                    example: account_setting-$APP_ENV-$accountId
                </Typography>
            </Grid>
            <Grid item xs={9}>
                <TextField
                    value={cacheKey}
                    placeholder={'cache key'}
                    onChange={handleChange}
                ></TextField>
                {invalidatingCache ? (
                    <CircularProgress />
                ) : (
                    <Button
                        variant={'contained'}
                        color={'primary'}
                        onClick={handleInvalidate}
                    >
                        Invalidate
                    </Button>
                )}

                {readCache ? (
                    <CircularProgress />
                ) : (
                    <Button
                        variant={'contained'}
                        color={'primary'}
                        onClick={handleRead}
                    >
                        Read
                    </Button>
                )}
            </Grid>
            <Grid item xs={9}>
                <pre>{cacheData}</pre>
            </Grid>
        </Grid>
    );
}

export default observer(Cache);
