import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import SimpleTable from '../../components/super/SimpleTable';

function SettingsOverview() {
    const systemSettings = [
        {
            category: 'Slack',
            name: 'ui.slack_allowed',
            description:
                'Allow Slack integration - Activated on integration page',
            example: '0 | 1',
        },
        {
            category: 'Slack',
            name: 'slack.welcomeMessage',
            description:
                'If Welcome message for initial Slack communication should be sent',
            example: '0 | 1',
        },
        {
            category: 'Slack',
            name: 'ui.slackOnly',
            description:
                '(DEPRECATED) Only show option to send messages to slack',
            example: '0 | 1',
        },
        {
            category: 'Slack',
            name: 'slack.noEmails',
            description: '(DEPRECATED) Only send messages to slack',
            example: '0 | 1',
        },
        {
            category: 'Teams',
            name: 'msteams.bot_id',
            description: 'Id of bot used for Teams',
            example: '',
        },
        {
            category: 'Teams',
            name: 'msteams.bot_pwd',
            description: 'Password for bot used for Teams',
            example: '',
        },
        {
            category: 'Teams',
            name: 'teams.enabled',
            description: 'If Teams has been initalized',
            example: '0 | 1',
        },
        {
            category: 'Teams',
            name: 'msteams.show_welcome',
            description:
                'If Welcome message for initial Teams communication should be sent',
            example: '0 | 1',
        },
        {
            category: 'Azure/Active Directory',
            name: 'azure.tenant',
            description: 'Tenant id  - usually entered by customer',
            example: '',
        },
        {
            category: 'Azure/Active Directory',
            name: 'azure.clientId',
            description: 'Client id from Azure - usually entered by customer',
            example: '',
        },
        {
            category: 'Azure/Active Directory',
            name: 'azure.clientSecret',
            description:
                'Secret string from Azure - usually entered by customer',
            example: '',
        },
        {
            category: 'Azure/Active Directory',
            name: 'azure.testMode',
            description: '(DEPRECATED) Enable test mode in AD integration',
            example: '',
        },
        {
            category: 'Azure/Active Directory',
            name: 'azure.enabled',
            description: 'Azure is enabled',
            example: '0 | 1',
        },
        {
            category: 'Azure/Active Directory',
            name: 'azure.groupBehaviour',
            description: '(DEPRECATED) Active directory behaviour',
            example: 'include | exclude',
        },
        {
            category: 'Azure/Active Directory',
            name: 'ui.adi_allowed',
            description:
                'Show Active Directory option in UI - on integration page',
            example: '0 | 1',
        },
        {
            category: 'Google Workspace',
            name: 'google.customerId',
            description: 'CustomerId from Google',
            example: '',
        },
        {
            category: 'Google Workspace',
            name: 'google.tokens',
            description: 'Token from Google',
            example: '',
        },
        {
            category: 'Google Workspace',
            name: 'google.googleId',
            description: 'GoogleId from Google',
            example: 'awarego.net',
        },
        {
            category: 'Google Workspace',
            name: 'google.testMode',
            description: '(DEPRECATED) Enable test mode in Google integration',
            example: '',
        },
        {
            category: 'Google Workspace',
            name: 'google.enabled',
            description: 'Google Workspace integration is enabled',
            example: '0 | 1',
        },
        {
            category: 'Google Workspace',
            name: 'google.groupBehaviour',
            description: '(DEPRECATED) Google behaviour',
            example: 'include | exclude',
        },
        {
            category: 'Google Workspace',
            name: 'ui.google_allowed',
            description: 'Show Google option in UI - on integration page',
            example: '0 | 1',
        },
        {
            category: 'Azure/SSO',
            name: 'azure.sso.domain',
            description:
                'Domains used by client in emails - usually entered by customer',
            example: 'awarego.com',
        },
        {
            category: 'Azure/SSO',
            name: 'azure.sso.app_name',
            description:
                'Name of app in client azure portal - usually entered by customer',
            example: 'awarego-sso',
        },
        {
            category: 'Azure/SSO',
            name: 'azure.sso.cert',
            description: 'Certificate string - usually entered by customer',
            example: '',
        },
        {
            category: 'Azure/SSO',
            name: 'azuresso.enabled',
            description: 'Is SSO enabled',
            example: '0 | 1',
        },
        {
            category: 'Azure/SSO',
            name: 'azure.sso.<system>.domain',
            description:
                'Domains used by client in emails for specified system (system can be: my,lms,super)',
            example: 'awarego.com',
        },
        {
            category: 'Azure/SSO',
            name: 'azure.sso.<system>.app_name',
            description:
                'Name of app in client azure portal for specified system (system can be: my,lms,super)',
            example: 'awarego-sso',
        },
        {
            category: 'Azure/SSO',
            name: 'azure.sso.<system>.cert',
            description:
                'Certificate string for specified system (system can be: my,lms,super)',
            example: '',
        },
        {
            category: 'Azure/SSO',
            name: 'azuresso.<system>.enabled',
            description:
                'Is SSO enabled for specified system (system can be: my,lms,super)',
            example: '0 | 1',
        },
        {
            category: 'Azure/SSO',
            name: 'azure.sso.tenant',
            description: '(DEPRECATED) Use azure.tenant',
            example: '',
        },
        {
            category: 'Azure/SSO',
            name: 'azure.sso.scope',
            description:
                'Which system is using SSO (Default is all if not set)',
            example: 'my | lms | super',
        },
        {
            category: 'Azure/SSO',
            name: 'azure.emailProps',
            description:
                'Specify which property should be used to get email from profile',
            example:
                'email | http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress | http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name | nameID',
        },
        {
            category: 'Azure/SSO',
            name: 'ui.azure_sso_allowed',
            description: 'Show SSO option in UI - on integration page',
            example: '0 | 1',
        },
        {
            category: 'Email/Messaging',
            name: 'ui.email.header',
            description: '(DEPRECATED) HTML to show in heading of emails',
            example: '',
        },
        {
            category: 'Email/Messaging',
            name: 'ui.email.mrkdwn_header',
            description:
                '(DEPRECATED) HTML to show in heading of emails/messages - markdown version, used for slack, teams etc',
            example: '',
        },
        {
            category: 'Email/Messaging',
            name: 'ui.email.footer',
            description: '(DEPRECATED) HTML to show in footer of emails',
            example: '',
        },
        {
            category: 'Email/Messaging',
            name: 'ui.email.mrkdwn_footer',
            description:
                '(DEPRECATED) HTML to show in footer of emails/messages - markdown version, used for slack, teams etc',
            example: '',
        },
        {
            category: 'Email/Messaging',
            name: 'ui.email.certificate',
            description:
                '(DEPRECATED) HTML to show in certificate emails/messages',
            example: '',
        },
        {
            category: 'Email/Messaging',
            name: 'ui.email.mrkdwn_certificate',
            description:
                '(DEPRECATED) HTML to show in certificate emails/messages - markdown version, used for slack, teams etc',
            example: '',
        },
        {
            category: 'Email/Messaging',
            name: 'ui.email.trainingHeader',
            description: 'HTML to show in training emails/messages headers',
            example: '',
        },
        {
            category: 'Email/Messaging',
            name: 'ui.email.mrkdwn_trainingHeader',
            description:
                'HTML to show in training emails/messages header - markdown version, used for slack, teams etc',
            example: '',
        },
        {
            category: 'Email/Messaging',
            name: 'ui.email.trainingFooter',
            description: 'HTML to show in training emails/messages footer',
            example: '',
        },
        {
            category: 'Email/Messaging',
            name: 'ui.email.mrkdwn_trainingFooter',
            description:
                'HTML to show in training emails/messages footer - markdown version, used for slack, teams etc',
            example: '',
        },
        {
            category: 'Email/Messaging',
            name: 'ui.email.reminderHeader',
            description: 'HTML to show in reminder emails/messages header',
            example: '',
        },
        {
            category: 'Email/Messaging',
            name: 'ui.email.mrkdwn_reminderHeader',
            description:
                'HTML to show in reminder emails/messages header - markdown version, used for slack, teams etc',
            example: '',
        },
        {
            category: 'Email/Messaging',
            name: 'ui.email.reminderFooter',
            description: 'HTML to show in reminder emails/messages footer',
            example: '',
        },
        {
            category: 'Email/Messaging',
            name: 'ui.email.mrkdwn_reminderFooter',
            description:
                'HTML to show in reminder emails/messages footer - markdown version, used for slack, teams etc',
            example: '',
        },
        {
            category: 'Email/Messaging',
            name: 'ui.email.assessmentHeader',
            description: 'HTML to show in assessment emails/messages header',
            example: '',
        },
        {
            category: 'Email/Messaging',
            name: 'ui.email.mrkdwn_assessmentHeader',
            description:
                'HTML to show in assessment emails/messages header - markdown version, used for slack, teams etc',
            example: '',
        },
        {
            category: 'Email/Messaging',
            name: 'ui.email.assessmentFooter',
            description: 'HTML to show in assessment emails/messages footer',
            example: '',
        },
        {
            category: 'Email/Messaging',
            name: 'ui.email.mrkdwn_assessmentFooter',
            description:
                'HTML to show in assessment emails/messages footer - markdown version, used for slack, teams etc',
            example: '',
        },
        {
            category: 'Email/Messaging',
            name: 'ui.email.banner_footer',
            description: 'Banner image to show in emails/messages footer',
            example: '',
        },
        {
            category: 'Email/Messaging',
            name: 'ui.email.banner_header',
            description: 'Banner image to show in emails/messages header',
            example: '',
        },
        {
            category: 'Email/Messaging',
            name: 'ui.email.training_subject',
            description: 'Subject text of training emails/messages',
            example: '',
        },
        {
            category: 'Email/Messaging',
            name: 'ui.email.assessment_subject',
            description: 'Subject text of assessment emails/messages',
            example: '',
        },
        {
            category: 'Email/Messaging',
            name: 'ui.email.certificate.subject',
            description:
                '(DEPRECATED) Subject text of certificate emails/messages',
            example: '',
        },
        {
            category: 'Email/Messaging',
            name: 'ui.email.show_social',
            description:
                'If AwareGO social media links should be shown in emails/messages',
            example: '0 | 1',
        },
        {
            category: 'Email/Messaging',
            name: 'ui.email.override_account_name',
            description:
                'Use company name instead of account name in emails/messages',
            example: '0 | 1',
        },

        {
            category: 'Email/Mailgun',
            name: 'mail.from_email',
            description: 'The email address to send from',
            example: '',
        },
        {
            category: 'Email/Mailgun',
            name: 'mail.from_name',
            description: 'The name of sender for email',
            example: '',
        },
        {
            category: 'Email/Mailgun',
            name: 'mail.mailgun_domain',
            description: 'Domain used for Mailgun',
            example: 'mg.awarego.com',
        },
        {
            category: 'Email/Mailgun',
            name: 'mail.mailgun_host',
            description: 'Mailgun api url - based on region set in mailgun ',
            example: 'api.mailgun.net | api.eu.mailgun.net',
        },
        {
            category: 'Email/Mailgun',
            name: 'mail.mailgun_key',
            description: 'Mailgun key - should always be the same key',
            example: '',
        },
        {
            category: 'Certificate customization',
            name: 'certificate.center_image_url',
            description: 'Path to image to show in center of certificate',
            example: '',
        },
        {
            category: 'Certificate customization',
            name: 'certificate.footer_image_url',
            description: 'Path to image to show in footer of certificate',
            example: '',
        },
        {
            category: 'Certificate customization',
            name: 'certificate.from_name',
            description: 'Name of certifier that appears on certificate',
            example: '',
        },
        {
            category: 'Certificate customization',
            name: 'certificate.use_company_name',
            description:
                'Use company name, instead of account name, as name of certifier that appears on certificate',
            example: '',
        },
        {
            category: 'Development flags',
            name: 'ui.api_allowed',
            description: 'Show open API feature in settings page',
            example: '0 | 1',
        },
        {
            category: 'Development flags',
            name: 'ui.assessment.recommendations',
            description: 'Show recommendations page in assessment',
            example: '0 | 1',
        },
        {
            category: 'Development flags',
            name: 'ui.assessments',
            description: 'Show assessments page',
            example: '0 | 1',
        },
        {
            category: 'Development flags',
            name: 'ui.auto_reminders',
            description: 'Show auto reminders options',
            example: '0 | 1',
        },
        {
            category: 'Development flags',
            name: 'ui.cmi5.enabled',
            description:
                'Show scorm/cmi5 generation options for trainings and assessments',
            example: '0 | 1',
        },
        {
            category: 'Development flags',
            name: 'ui.feedback_enabled',
            description:
                'Show scorm/cmi5 generation options for trainings and assessments',
            example: '0 | 1',
        },
        {
            category: 'Development flags',
            name: 'ui.assessment_dashboard',
            description: 'Show assessments on dashboard',
            example: '0 | 1',
        },
        {
            category: 'Development flags',
            name: 'ui.assessment_manual_schedule',
            description: 'Enable sending assessment manually/get signin link',
            example: '0 | 1',
        },
        {
            category: 'Development flags',
            name: 'ui.certificate_name_change_disabled',
            description:
                '(DEPRECATED) Disable changing user name for certificate',
            example: '0 | 1',
        },
        {
            category: 'Development flags',
            name: 'ui.posters_url',
            description: '(DEPRECATED) Url for custom poster resources',
            example: '0 | 1',
        },
        {
            category: 'Development flags',
            name: 'ui.stickers_url',
            description: '(DEPRECATED) Url for custom sticker resources',
            example: '0 | 1',
        },

        {
            category: 'Customizations and settings',
            name: 'billing.custom',
            description:
                'Usually set for accounts. Whether company/account is billed seperately',
            example: '0 | 1',
        },
        {
            category: 'Customizations and settings',
            name: 'lms_domain',
            description: 'Custom domain for lms page',
            example: 'lms.awarego.com',
        },
        {
            category: 'Customizations and settings',
            name: 'my_domain',
            description: 'Custom domain for my page',
            example: 'my.awarego.com',
        },
        {
            category: 'Customizations and settings',
            name: 'ui.colors.primary',
            description: 'Custom primary color',
            example: '#20A26B',
        },
        {
            category: 'Customizations and settings',
            name: 'ui.colors.secondary',
            description: 'Custom secondary color',
            example: '#AD2931',
        },
        {
            category: 'Customizations and settings',
            name: 'ui.favicon',
            description: 'Url to custom favicon',
            example: '',
        },
        {
            category: 'Customizations and settings',
            name: 'ui.logo',
            description:
                'Url to custom logo - not same value as in company settings, this overrides account logo',
            example: '',
        },
        {
            category: 'Customizations and settings',
            name: 'ui.captions_settings',
            description: 'Custom setting for ppv/embed captions',
            example: '{"color": "yellow"}',
        },
        {
            category: 'Customizations and settings',
            name: 'ui.preset_caption_language',
            description:
                'Make certain captions always show by default (ppv/embed)',
            example: 'Bosnian',
        },
        {
            category: 'Customizations and settings',
            name: 'ui.sendEnrolment',
            description:
                'If new employee enrolment should be on (off by default)',
            example: '0 | 1',
        },
        {
            category: 'Customizations and settings',
            name: 'RUSTICI_ACCOUNT_ID',
            description:
                'Automaticly created when company starts using Scorm/Cmi5 content. Id for company in Rustici system',
            example: '',
        },

        {
            category: 'Scim',
            name: 'scim.secret',
            description: 'Secret for scim integration',
            example: '',
        },
        {
            category: 'Scim',
            name: 'scim.email',
            description: 'Email setting for scim integration',
            example: 'emails.type=work',
        },
        {
            category: 'Google/SSO',
            name: 'sso.my.entry_point',
            description: 'Entry point url for google SSO',
            example: '',
        },
        {
            category: 'Google/SSO',
            name: 'sso.my.issuer',
            description: 'Issuer url for google SSO',
            example: '',
        },
        {
            category: 'Google/SSO',
            name: 'sso.protocol',
            description: 'Protocol for google SSO',
            example: '',
        },
        {
            category: 'Queues',
            name: 'queues.<queue-type-specifics>',
            description:
                'Custom queue for account/company for specific queue type, example name: queues.notificationCreated.training_schedule',
            example: '',
        },
        {
            category: 'Assessment customizations',
            name: 'ui.assessments.additional_answer_text.<questionId>',
            description: 'Custom answer text for specific question',
            example:
                '<p>Please report phishing emails by clicking the Report Phish button in Outlook.</p> | mdlz_additional_email_info',
        },
        {
            category: 'Assessment customizations',
            name: 'ui.assessments.intro_text',
            description: 'Custom intro text for assessments',
            example: '',
        },
        {
            category: 'Assessment customizations',
            name: 'ui.assessments.intro_text.<languagecode>',
            description:
                'Custom intro text for assessments for specific language',
            example: '',
        },
        {
            category: 'Assessment customizations',
            name: 'assessment.additional_info.<threat-area>.link_target',
            description:
                'Url for link in assessment result screen for specified threat area',
            example: '',
        },
        {
            category: 'Assessment customizations',
            name: 'assessment.additional_info.<threat-area>.link_text',
            description:
                'Text for link in assessment result screen for specified threat area',
            example: '',
        },
        {
            category: 'Assessment customizations',
            name: 'assessment.additional_info.<threat-area>.text',
            description:
                'Text for assessment result screen for specified threat area',
            example: '',
        },
        {
            category: 'Assessment customizations',
            name: 'assessment.<assessmentId>.autojoin',
            description:
                'Make assessment with specified id auto-joinable by any user',
            example: '0 | 1',
        },
        {
            category: 'Assessment customizations',
            name: 'assessment.duration',
            description:
                'Customize default duration for scorm/cmi5 assessments',
            example: '',
        },
    ];

    const columns = [
        {
            Header: 'Category',
            accessor: 'category',
        },
        {
            Header: 'Name',
            accessor: 'name',
        },
        {
            Header: 'Description',
            accessor: 'description',
        },
        {
            Header: 'Example values',
            accessor: 'example',
        },
    ];

    return (
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            align="center"
            spacing={2}
        >
            <Fragment>
                <Grid item xs={12}>
                    <Typography variant="h2" gutterBottom>
                        Settings Overview
                    </Typography>
                    <SimpleTable
                        columns={columns}
                        data={systemSettings}
                        needsFilter={true}
                    />
                </Grid>
            </Fragment>
        </Grid>
    );
}
export default observer(SettingsOverview);
