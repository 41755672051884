import React, {useEffect} from 'react';
import { observer } from 'mobx-react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { useStores } from '../../hooks/use-stores';
import CircularProgress from '@material-ui/core/CircularProgress';
import Errormessage from "../../components/errormessage";

function TrialPlan() {
    const { superStore } = useStores();
    const { trialPlan, readingTrialPlan, updatingTrialPlan } = superStore;

    useEffect(()=>{
        superStore.readTrialPlan();
    }, [] )

    const handleUpdate = () => {
        superStore.updateTrialPlan(trialPlan);
    };

    const handleChange = (e) => {
        superStore.setTrialPlan(e.target.value);
    };


    return (
        <Grid>
            <Errormessage error={superStore.trialPlanError} errorType="error" />
            <TextField
                value={trialPlan}
                placeholder={'trial plan'}
                onChange={handleChange}
            ></TextField>
            {(readingTrialPlan || updatingTrialPlan) ? (
                <CircularProgress />
            ) : (
                <Button
                    variant={'contained'}
                    color={'primary'}
                    onClick={handleUpdate}
                >
                    Update
                </Button>
            )}
        </Grid>
    );
}

export default observer(TrialPlan);
