import RestService from './RestService';

export default class Subscriptions extends RestService {
    constructor(parentService, parentId) {
        if (parentService && parentId) {
            super(`${parentService.baseUrl}/${parentId}/subscriptions`);
        } else super('/subscriptions');
    }

    // These are old endpoints that were no longer in use, so commented them out
    /*collect() {
        return this.post(`${this.baseUrl}/collect`);
    }

    cancel() {
        return this.post(`${this.baseUrl}/cancel`);
    }

    plans() {
        return this.get(`${this.baseUrl}/plans`);
    }

    braintreetoken() {
        return this.get(`${this.baseUrl}/braintreetoken`);
    }*/

    listAll() {
        return this.get(`${this.baseUrl}/plansForAdmin`);
    }

    createSubscriptionPlan(body) {
        return this.post(`${this.baseUrl}/plans`, body);
    }

    updateSubscriptionPlan(code, active) {
        return this.put(`${this.baseUrl}/plans/${code}`, { active });
    }
}
