import React, { Fragment, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { enforceSlash, formatDate } from '../../utils/helpers';
import { useStores } from '../../hooks/use-stores';
import ThemedSpinner from '../../components/themed/ThemedSpinner';
import SimpleTable from '../../components/super/SimpleTable';
import { Typography } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { Box } from '@material-ui/core';
import { Fab } from '@material-ui/core';
import { Tooltip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    fab: {
        position: 'fixed',
        bottom: theme.spacing(4),
        right: theme.spacing(4),
    },
}));

function ScheduledTasksList() {
    const { scheduledTasksStore } = useStores();
    const { loadingScheduledTasks, scheduledTasks } = scheduledTasksStore;
    const classes = useStyles();
    let match = useRouteMatch();

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        scheduledTasksStore.loadScheduledTasks();
    };

    const columns = React.useMemo(
        () => [
            {
                Header: 'Id',
                accessor: 'id',
                Cell: (x) => (
                    <Link to={`${enforceSlash(match.url)}${x.value}/edit`}>
                        {x.value}
                    </Link>
                ),
            },
            {
                Header: 'Name',
                accessor: 'name',
            },
            {
                Header: 'Created',
                accessor: 'created',
                Cell: (x) => formatDate(x.value),
            },
            {
                Header: 'Next run',
                accessor: 'scheduledAt',
                Cell: (x) => formatDate(x.value),
            },
        ],
        []
    );

    return (
        <Grid
            container
            direction="row"
            justify="center"
            align="center"
            spacing={2}
        >
            <Grid item xs={12}>
                <Box mt={4}>
                    <Typography variant="h2">Scheduled Tasks</Typography>
                </Box>
            </Grid>
            {loadingScheduledTasks ? (
                <ThemedSpinner />
            ) : (
                <Fragment>
                    <Tooltip
                        title="Create partner"
                        aria-label="add"
                        placement="left"
                    >
                        <Fab
                            color="primary"
                            href="/scheduledTasks/create"
                            className={classes.fab}
                        >
                            <AddIcon />
                        </Fab>
                    </Tooltip>
                    {scheduledTasks && scheduledTasks.length > 0 && (
                        <Grid item xs={12}>
                            <SimpleTable
                                columns={columns}
                                data={scheduledTasks}
                                needsFilter={true}
                            />
                        </Grid>
                    )}
                </Fragment>
            )}
        </Grid>
    );
}

export default observer(ScheduledTasksList);
