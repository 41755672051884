import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import ErrorMessage from '../../components/errormessage';
import PartnerForm from '../../components/super/partnerEdit';
import ThemedSpinner from '../../components/themed/ThemedSpinner';
import { useStores } from '../../hooks/use-stores';
import { observer } from 'mobx-react';
import { useParams, useRouteMatch } from 'react-router';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

function PartnerEdit() {
    let { partnerId } = useParams();
    const { superStore } = useStores();
    let history = useHistory();
    const { savingPartner, error, partnerEditForm: partnerForm } = superStore;
    let match = useRouteMatch();

    const loadData = async () => {
        if (partnerId) {
            await superStore.loadPartner(partnerId);
        }
    };

    useEffect(() => {
        superStore.startEditPartner(partnerId, () => {
            return history.push('/partners');
        });
        loadData();
    }, [partnerId]);

    return (
        <Grid
            container
            direction="row"
            justify="center"
            align="center"
            spacing={2}
        >
            <Grid item xs={12}>
                <Box mt={4}>
                    <Typography variant="h2" gutterBottom>
                        {match.url.includes('create') ? 'Create' : 'Edit'}{' '}
                        partner
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={12}>
                {partnerForm && <PartnerForm form={partnerForm} />}
            </Grid>
            <Grid item xs={12}>
                {savingPartner ? (
                    <ThemedSpinner />
                ) : (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={(e) => partnerForm.onSubmit(e)}
                    >
                        Save
                    </Button>
                )}
            </Grid>
            {error && (
                <Grid item xs={12}>
                    <ErrorMessage error={error} />
                </Grid>
            )}
        </Grid>
    );
}

export default observer(PartnerEdit);
