import React, { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { useStores } from '../hooks/use-stores';
import { observer } from 'mobx-react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

function EmailSignInError() {
    const { authStore } = useStores();
    let history = useHistory();
    const { isAuthenticated, currentUser } = authStore;

    const handleSignout = () => {
        authStore.logout();
        history.push('/');
    };

    return (
        <Grid
            container
            direction="row"
            justify="center"
            align="center"
            spacing={2}
        >
            {isAuthenticated ? (
                <Fragment>
                    <Grid item xs={12}>
                        <Box mt={4}>
                            <Typography variant="h6" gutterBottom>
                                You are signed in as{' '}
                                <strong>{currentUser.email}</strong>
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item>
                        <Button color="primary" variant="contained" href="/">
                            Go to frontpage
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            color="secondary"
                            variant="contained"
                            onClick={handleSignout}
                        >
                            Sign in as another user
                        </Button>
                    </Grid>
                </Fragment>
            ) : (
                <Fragment>
                    <Grid item xs={12}>
                        <Box mt={4}>
                            <Typography variant="h2" gutterBottom>
                                Unable to sign in
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body1" gutterBottom>
                            The link you tried to use to sign in was not valid..
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            color="primary"
                            variant="contained"
                            href="/auth/signin"
                        >
                            Request a new sign in link
                        </Button>
                    </Grid>
                </Fragment>
            )}
        </Grid>
    );
}

export default observer(EmailSignInError);
