import { observer } from 'mobx-react';
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

function LanguageEdit({ form }) {
    const name = form.$('name');
    const englishName = form.$('english_name');
    const code = form.$('code');
    const version = form.$('version');
    const icon = form.$('icon');
    const flagImage = form.$('flag_image');

    return (
        <Grid
            container
            direction="row"
            justify="center"
            align="center"
            spacing={2}
        >
            <Grid item xs={12}>
                <TextField
                    type="text"
                    name="languageName"
                    onChange={name.onChange}
                    value={name.value}
                    label={name.label}
                    variant="outlined"
                    required
                    error={name.error && name.error.length > 0 ? true : false}
                    helperText={name.error}
                    placeholder={name.placeholder}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    type="text"
                    name="englishName"
                    onChange={englishName.onChange}
                    value={englishName.value}
                    label={englishName.label}
                    variant="outlined"
                    required
                    error={
                        englishName.error && englishName.error.length > 0
                            ? true
                            : false
                    }
                    helperText={englishName.error}
                    placeholder={englishName.placeholder}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    type="text"
                    name="code"
                    onChange={code.onChange}
                    value={code.value}
                    label={code.label}
                    variant="outlined"
                    required
                    error={code.error && code.error.length > 0 ? true : false}
                    helperText={code.error}
                    placeholder={code.placeholder}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    type="text"
                    name="version"
                    onChange={version.onChange}
                    value={version.value}
                    label={version.label}
                    variant="outlined"
                    required
                    error={
                        version.error && version.error.length > 0 ? true : false
                    }
                    helperText={version.error}
                    placeholder={version.placeholder}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    type="text"
                    name="icon"
                    onChange={icon.onChange}
                    value={icon.value}
                    label={icon.label}
                    variant="outlined"
                    error={icon.error && icon.error.length > 0 ? true : false}
                    helperText={icon.error}
                    placeholder={icon.placeholder}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    type="text"
                    name="flagImage"
                    onChange={flagImage.onChange}
                    value={flagImage.value}
                    label={flagImage.label}
                    variant="outlined"
                    error={
                        flagImage.error && flagImage.error.length > 0
                            ? true
                            : false
                    }
                    helperText={flagImage.error}
                    placeholder={flagImage.placeholder}
                />
            </Grid>
        </Grid>
    );
}

export default observer(LanguageEdit);
