import { observer } from 'mobx-react';
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import ErrorMessage from '../errormessage';

function partnerEdit({ form }) {
    const nameField = form.$('name');

    return (
        <Grid
            container
            direction="row"
            justify="center"
            align="center"
            spacing={2}
        >
            <Grid item xs={12}>
                <TextField
                    type="text"
                    name="partnerName"
                    onChange={nameField.onChange}
                    value={nameField.value}
                    label={nameField.label}
                    variant="outlined"
                />
            </Grid>
            {nameField.error && (
                <Grid item xs={12}>
                    <ErrorMessage error={nameField.error} />
                </Grid>
            )}
        </Grid>
    );
}

export default observer(partnerEdit);
