import React from 'react';
import { Grid } from '@material-ui/core';
import { Checkbox, FormControlLabel } from '@material-ui/core';

function AddRemoveList({
    addItem,
    removeItem,
    itemList,
    activeItemList,
    idColumn,
    nameColumn,
}) {
    const isItemActive = (itemId) => {
        return activeItemList.find((x) => x[idColumn] === itemId);
    };

    const onItemChange = async (itemEvent) => {
        if (itemEvent.target.checked) {
            await addItem(itemEvent.target.name);
        } else {
            await removeItem(itemEvent.target.name);
        }
    };

    return (
        <Grid
            container
            direction="column"
            justify="center"
            align="center"
            spacing={2}
        >
            {itemList.map((item, k) => {
                return (
                    <Grid item key={k} xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={isItemActive(item[idColumn])}
                                    onChange={onItemChange}
                                    name={item[idColumn]}
                                />
                            }
                            label={item[nameColumn]}
                        />
                    </Grid>
                );
            })}
        </Grid>
    );
}

export default AddRemoveList;
