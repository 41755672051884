import React, { Fragment, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import ErrorMessage from '../../components/errormessage';
import ThemedSpinner from '../../components/themed/ThemedSpinner';
import { useStores } from '../../hooks/use-stores';
import { useParams, useRouteMatch } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import clsx from 'clsx';
import { Avatar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ACLEditor from './ACLEditor';

const useStyles = makeStyles(() => ({
    language: {
        filter: 'grayscale(1)',
        opacity: 0.3,
        cursor: 'pointer',
    },

    selectedLanguage: {
        filter: 'grayscale(0)',
        opacity: 1,
    },
}));

function Material() {
    const classes = useStyles();
    const { superStore } = useStores();
    let { materialId } = useParams();
    const {
        sortedlanguages: languages,
        loadingMaterial,
        editingMaterial,
        materialsError,
        creatingMaterial,
        updatingMaterials,
    } = superStore;
    const [nameError, setNameError] = useState(false);
    const selectedLanguages = editingMaterial
        ? editingMaterial.languages || ['en']
        : [];

    const match = useRouteMatch();

    const loadData = async () => {
        await superStore.loadLanguages();

        materialId
            ? await superStore.loadMaterial(materialId)
            : (superStore.editingMaterial = {
                  title: '',
                  definition: { slug: '', threatAreas: [], tags: [] },
                  files: {},
                  languages: [],
              });
    };

    const toggleLanguage = (code) => {
        if (!editingMaterial.languages) {
            editingMaterial.languages = selectedLanguages;
        }
        if (editingMaterial.languages.includes(code)) {
            editingMaterial.languages = editingMaterial.languages.filter(
                (lang) => lang !== code
            );
        } else {
            editingMaterial.languages.push(code);
        }
    };

    useEffect(() => {
        loadData();
    }, [materialId]);

    const setFileName = (lang, prop, value) => {
        if (!editingMaterial) return;
        if (!editingMaterial.files) editingMaterial.files = {};
        if (!editingMaterial.files[lang]) editingMaterial.files[lang] = {};
        editingMaterial.files[lang][prop] = value;
    };

    const handleSave = async () => {
        if (!superStore.editingMaterial.title) {
            setNameError(true);
            return;
        }
        setNameError(false);
        let result;
        materialId
            ? (result = await superStore.updateMaterial(
                  superStore.editingMaterial.id,
                  {
                      ...superStore.editingMaterial,
                  }
              ))
            : (result = await superStore.createMaterial(
                  superStore.editingMaterial
              ));
        if (result) {
            materialId
                ? history.push(`/content/materials/${materialId}/edit`)
                : history.push('/content/materials');
        }
    };

    return loadingMaterial || !editingMaterial ? (
        <ThemedSpinner />
    ) : (
        editingMaterial && (
            <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
                align="center"
                spacing={2}
            >
                <Grid item xs={12}>
                    <Box mt={4}>
                        <Typography variant="h2" gutterBottom>
                            Material: {editingMaterial.name}
                        </Typography>
                    </Box>
                </Grid>

                {materialsError && (
                    <Grid item xs={12}>
                        <ErrorMessage error={materialsError} />
                    </Grid>
                )}
                <Grid item xs={10}>
                    <TextField
                        type="text"
                        name="questionName"
                        value={editingMaterial.title || ''}
                        onChange={(e) => {
                            editingMaterial.title = e.target.value;
                        }}
                        error={nameError}
                        helperText={nameError ? 'Name is required' : ''}
                        variant="outlined"
                        label="Material name"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={10}>
                    <TextField
                        type="text"
                        name="thumbnail"
                        value={editingMaterial.thumbnail || ''}
                        onChange={(e) => {
                            editingMaterial.thumbnail = e.target.value;
                        }}
                        variant="outlined"
                        label="Thumbnail"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={10}>
                    <TextField
                        type="text"
                        name="image"
                        value={editingMaterial.image || ''}
                        onChange={(e) => {
                            editingMaterial.image = e.target.value;
                        }}
                        variant="outlined"
                        label="Full image"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={4} justify={'flex-start'}>
                    <TextField
                        type="number"
                        name="order"
                        value={editingMaterial.order || ''}
                        onChange={(e) => {
                            editingMaterial.order = e.target.value;
                        }}
                        variant="outlined"
                        label="Order"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={10}>
                    <TextField
                        type="text"
                        name="slug"
                        value={editingMaterial.definition.slug || ''}
                        onChange={(e) => {
                            editingMaterial.definition.slug = e.target.value;
                        }}
                        variant="outlined"
                        label="Slug"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={10}>
                    <TextField
                        type="text"
                        name="areas"
                        value={
                            editingMaterial.definition.threatAreas.join(', ') ||
                            ''
                        }
                        onChange={(e) => {
                            editingMaterial.definition.threatAreas = (
                                e.target.value || ''
                            )
                                .split(',')
                                .map((a) => a.trim());
                        }}
                        variant="outlined"
                        label="Threat areas (comma separated)"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={10}>
                    <TextField
                        type="text"
                        name="tags"
                        value={editingMaterial.definition.tags.join(', ') || ''}
                        onChange={(e) => {
                            editingMaterial.definition.tags = (
                                e.target.value || ''
                            )
                                .split(',')
                                .map((a) => a.trim());
                        }}
                        variant="outlined"
                        label="Tags (comma separated)"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={10} style={{ textAlign: 'left' }}>
                    <FormControl variant="filled" fullWidth>
                        <InputLabel>Type</InputLabel>
                        <Select
                            variant="filled"
                            name="difficulty"
                            value={editingMaterial.subType || ''}
                            onChange={(e) => {
                                editingMaterial.subType = e.target.value;
                            }}
                        >
                            <MenuItem value="poster">poster</MenuItem>
                            <MenuItem value="sticker">sticker</MenuItem>
                            <MenuItem value="book">book</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={10}>
                    <TextField
                        type="textarea"
                        multiline
                        rows={3}
                        cols={70}
                        name="questionDefinition"
                        value={editingMaterial.description || ''}
                        onChange={(e) => {
                            editingMaterial.description = e.target.value;
                        }}
                        variant="outlined"
                        label="Description"
                        fullWidth
                    />
                </Grid>
                <Grid
                    item
                    xs={10}
                    container
                    spacing={2}
                    alignItems="center"
                    justify="flex-start"
                >
                    {languages.map((l) => {
                        return (
                            <>
                                <Grid
                                    key={l.id}
                                    item
                                    xs={2}
                                    className={clsx(classes.language, {
                                        [classes.selectedLanguage]:
                                            selectedLanguages.includes(l.code),
                                    })}
                                    onClick={() => toggleLanguage(l.code)}
                                >
                                    <Avatar
                                        alt="country flag"
                                        src={`${l.flag_image}`}
                                    />
                                    {l.name}
                                </Grid>
                                <Grid item xs={7}>
                                    <TextField
                                        fullWidth={true}
                                        name={'file-' + l.code}
                                        value={
                                            (editingMaterial.files[l.code] &&
                                                editingMaterial.files[l.code]
                                                    .file) ||
                                            ''
                                        }
                                        onChange={(e) => {
                                            setFileName(
                                                l.code,
                                                'file',
                                                e.target.value
                                            );
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        fullWidth={true}
                                        name={'file-' + l.code}
                                        value={
                                            (editingMaterial.files[l.code] &&
                                                editingMaterial.files[l.code]
                                                    .originalName) ||
                                            ''
                                        }
                                        onChange={(e) => {
                                            setFileName(
                                                l.code,
                                                'originalName',
                                                e.target.value
                                            );
                                        }}
                                    />
                                </Grid>
                            </>
                        );
                    })}
                </Grid>
                {match.path.includes('edit') ? (
                    <>
                        <Grid item xs={12}>
                            <Box mt={2}>
                                <Typography variant="h4" gutterBottom>
                                    Access control
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <ACLEditor
                                acl={editingMaterial.acl}
                                unique_id={editingMaterial.unique_id}
                            />
                        </Grid>
                    </>
                ) : (
                    ''
                )}

                {materialsError && (
                    <Grid item xs={12}>
                        <ErrorMessage error={materialsError} />
                    </Grid>
                )}
                <Grid item xs={12}>
                    <Box mb={match.path.includes('edit') ? 0 : 4}>
                        {creatingMaterial ||
                        (materialId &&
                            updatingMaterials.includes(materialId)) ? (
                            <ThemedSpinner />
                        ) : (
                            <Button
                                onClick={handleSave}
                                color="primary"
                                variant="contained"
                            >
                                Save
                            </Button>
                        )}
                    </Box>
                </Grid>
            </Grid>
        )
    );
}

export default observer(Material);
