import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import { NavMenu, NavItem } from '@mui-treasury/components/menu/navigation';
import { useFloatNavigationMenuStyles } from '@mui-treasury/styles/navigationMenu/float';
import { Link } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import { Divider } from '@material-ui/core';
import { version } from '../package.json';

const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1,
    },
    marginRight: {
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2),
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
}));

export default function Header({
    isAuthenticated,
    currentUserInitials,
    currentUser,
    selectedSection,
    handleSignout,
}) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const menuId = 'user-menu';
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={menuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMenuOpen}
            onClose={handleMenuClose}
            useStyles={useFloatNavigationMenuStyles}
        >
            <a href={import.meta.env.REACT_APP_LMS_URL}>
                <MenuItem onClick={handleMenuClose}>
                    <p>Admin view</p>
                </MenuItem>
            </a>
            <a href="/">
                <MenuItem
                    selected
                    onClick={handleMenuClose}
                    component={Link}
                    to="/"
                >
                    <p>Super view</p>
                </MenuItem>
            </a>
            <a href={import.meta.env.REACT_APP_MY_URL}>
                <MenuItem onClick={handleMenuClose}>
                    <p>Employee view</p>
                </MenuItem>
            </a>
            <MenuItem onClick={handleMenuClose}>
                <Button type="submit" color="secondary" onClick={handleSignout}>
                    Sign out
                </Button>
            </MenuItem>
        </Menu>
    );

    const mobileMenuId = 'menu-mobile';
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            <MenuItem
                selected={selectedSection === 1}
                component={Link}
                onClick={handleMobileMenuClose}
                to="/companies"
            >
                <p>Companies</p>
            </MenuItem>
            <MenuItem
                selected={selectedSection === 3}
                component={Link}
                to="/partners"
                onClick={handleMobileMenuClose}
            >
                <p>Partners</p>
            </MenuItem>
            <MenuItem
                selected={selectedSection === 4}
                component={Link}
                to="/freemium"
                onClick={handleMobileMenuClose}
            >
                <p>Freemiums</p>
            </MenuItem>
            <MenuItem
                selected={selectedSection === 5}
                component={Link}
                to="/subscriptions"
                onClick={handleMobileMenuClose}
            >
                <p>Subscriptions</p>
            </MenuItem>
            <MenuItem
                selected={selectedSection === 6}
                component={Link}
                to="/languages"
                onClick={handleMobileMenuClose}
            >
                <p>Languages</p>
            </MenuItem>
            <MenuItem
                selected={selectedSection === 7}
                component={Link}
                to="/content"
                onClick={handleMobileMenuClose}
            >
                <p>Content</p>
            </MenuItem>
            <MenuItem
                selected={selectedSection === 7}
                component={Link}
                to="/trainings"
                onClick={handleMobileMenuClose}
            >
                <p>Trainings</p>
            </MenuItem>
            <MenuItem
                selected={selectedSection === 8}
                component={Link}
                to="/videos/S01E01,english"
                onClick={handleMobileMenuClose}
            >
                <p>Videos</p>
            </MenuItem>
            <MenuItem
                selected={selectedSection === 9}
                component={Link}
                to="/notifications"
                onClick={handleMobileMenuClose}
            >
                <p>Notifications</p>
            </MenuItem>
            <MenuItem
                selected={selectedSection === 11}
                component={Link}
                to="/stats"
                onClick={handleMobileMenuClose}
            >
                <p>Stats</p>
            </MenuItem>
        </Menu>
    );

    return (
        <div className={classes.flexGrow}>
            {/* color for stage: #FCB5B5 */}
            <AppBar
                position="sticky"
                style={{ backgroundColor: import.meta.env.REACT_APP_HEADER_BG }}
            >
                <Toolbar>
                    {isAuthenticated ? (
                        <div className={classes.sectionMobile}>
                            <IconButton
                                aria-label="show more"
                                aria-controls={mobileMenuId}
                                aria-haspopup="true"
                                onClick={handleMobileMenuOpen}
                                color="secondary"
                            >
                                <MenuIcon />
                            </IconButton>
                        </div>
                    ) : (
                        ''
                    )}
                    <div style={{}}>
                        <Link to="/">
                            <img
                                src="/static/img/logo_super.png"
                                width="100"
                                alt="SUPER AwareGO"
                            />
                        </Link>
                        <span style={{ color: 'red' }}>{version}</span>
                    </div>
                    {isAuthenticated ? (
                        <Fragment>
                            <div className={classes.sectionDesktop}>
                                <NavMenu
                                    gutter={1}
                                    useStyles={useFloatNavigationMenuStyles}
                                >
                                    <NavItem
                                        active={selectedSection === 1}
                                        as={Link}
                                        to="/companies"
                                    >
                                        Companies
                                    </NavItem>
                                    <NavItem
                                        active={selectedSection === 3}
                                        as={Link}
                                        to="/partners"
                                    >
                                        Partners
                                    </NavItem>
                                    <NavItem
                                        active={selectedSection === 4}
                                        as={Link}
                                        to="/freemium"
                                    >
                                        Freemiums
                                    </NavItem>
                                    <NavItem
                                        active={selectedSection === 5}
                                        as={Link}
                                        to="/subscriptions"
                                    >
                                        Subscriptions
                                    </NavItem>
                                    <NavItem
                                        active={selectedSection === 6}
                                        as={Link}
                                        to="/languages"
                                    >
                                        Languages
                                    </NavItem>
                                    <NavItem
                                        active={selectedSection === 7}
                                        as={Link}
                                        to="/content"
                                    >
                                        Content
                                    </NavItem>
                                    <NavItem
                                        active={selectedSection === 8}
                                        as={Link}
                                        to="/trainings"
                                    >
                                        Trainings
                                    </NavItem>
                                    <NavItem
                                        active={selectedSection === 9}
                                        as={Link}
                                        to="/videos/S01E01,english"
                                    >
                                        Videos
                                    </NavItem>
                                    <NavItem
                                        active={selectedSection === 10}
                                        as={Link}
                                        to="/notifications"
                                    >
                                        Notifications
                                    </NavItem>
                                    <NavItem
                                        active={selectedSection === 11}
                                        as={Link}
                                        to="/admins"
                                    >
                                        Admins
                                    </NavItem>
                                    <NavItem
                                        active={selectedSection === 11}
                                        as={Link}
                                        to="/stats"
                                    >
                                        Stats
                                    </NavItem>
                                </NavMenu>
                            </div>
                            <div className={classes.grow} />
                            <IconButton
                                edge="end"
                                aria-label="account of current user"
                                aria-controls={menuId}
                                aria-haspopup="true"
                                onClick={handleProfileMenuOpen}
                                color="inherit"
                            >
                                <div className={classes.sectionDesktop}>
                                    <Divider orientation="vertical" flexItem />
                                    <Typography
                                        className={classes.marginRight}
                                        color="primary"
                                    >
                                        {currentUser.name || currentUser.email}
                                    </Typography>
                                </div>
                                {currentUserInitials &&
                                currentUserInitials !== '' ? (
                                    <Avatar>{currentUserInitials}</Avatar>
                                ) : (
                                    <Avatar
                                        alt="Faceless user"
                                        src="/static/img/icon-user-simple.svg"
                                    />
                                )}
                            </IconButton>
                        </Fragment>
                    ) : (
                        ''
                    )}
                </Toolbar>
            </AppBar>
            {renderMobileMenu}
            {renderMenu}
        </div>
    );
}
