import React, { useEffect } from 'react';
import Waiting from '../components/waiting';
import { querystring } from '../utils/helpers';
import { useStores } from '../hooks/use-stores';
import { useHistory, useRouteMatch } from 'react-router';

function TokenSignIn() {
    const { authStore } = useStores();
    let match = useRouteMatch();
    let history = useHistory();

    useEffect(() => {
        let { token } = match.params;
        authStore.setToken(token);
        let redirect = querystring('redirect') || '/';
        if (!redirect.startsWith('/')) redirect = `/${redirect}`;
        history.push(redirect);
    }, []);

    return <Waiting waitingActive={true} fullScreen={true} />;
}

export default TokenSignIn;
