import { makeAutoObservable, runInAction } from 'mobx';
import services from '../services';
import {
    generateCreateEntity,
    generateDeleteEntity,
    generateLoadEntity,
    generateUpdateEntity,
} from '../utils/mobx';

class IntegrationsStore {
    constructor() {
        makeAutoObservable(this);
    }
    loadingIntegrations = false;
    integrations = null;
    error = null;

    setError(type, error) {
        this.error = error;
    }

    loadStats = generateLoadEntity(
        'integrations',
        this,
        'loadingIntegrations',
        async () => {
            return services.Integrations.list();
        },
        'integrations'
    );
}

export default IntegrationsStore;
