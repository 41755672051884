import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import stores from './stores';
import i18n from './tools/i18n';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter as Router } from 'react-router-dom';
import { storesContext } from './contexts';
import {
    ThemeProvider,
    createMuiTheme,
    responsiveFontSizes,
} from '@material-ui/core/styles';

//to window only for debug
window.__stores = stores;
window.__i18n = i18n;

let theme = createMuiTheme({
    palette: {
        primary: {
            main: '#20A36A',
        },
        secondary: {
            main: '#AD2931',
        },
    },
});

theme = responsiveFontSizes(theme);

ReactDOM.render(
    <I18nextProvider i18n={i18n}>
        <storesContext.Provider value={stores}>
            <ThemeProvider theme={theme}>
                <Router>
                    <App />
                </Router>
            </ThemeProvider>
        </storesContext.Provider>
    </I18nextProvider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
