import RestService from './RestService';

export default class Subjects extends RestService {
    constructor(parentService, parentId) {
        if (parentService && parentId) {
            super(`${parentService.baseUrl}/${parentId}/categories`);
        } else super('/categories');
    }

    all() {
        return this.get(`${this.baseUrl}/all`);
    }

    superGet(id) {
        return this.get(`${this.baseUrl}/all/${id}`);
    }

    superGetSubjects(id) {
        return this.get(`${this.baseUrl}/all/${id}/subjects`);
    }

    addSubject(categoryId, subjectId) {
        return this.post(`${this.baseUrl}/${categoryId}/subjects`, {
            subjectId,
        });
    }

    deleteSubject(categoryId, subjectId) {
        return this.del(`${this.baseUrl}/${categoryId}/subjects/${subjectId}`);
    }
}
