import * as validatorjs from 'validatorjs';
import MobxReactForm from 'mobx-react-form';

const plugins = {
    dvr: {
        package: validatorjs,
        extend: ($validator) => {
            var messages = $validator.getMessages('en');
            messages.required = 'You need to enter some information to save';
            $validator.setMessages('en', messages);
        },
    },
};

const fields = {
    name: {
        label: 'Company name',
        rules: 'string|required|between:4,150',
        placeholder: 'Company name',
    },
};

export default function create(hooks) {
    return new MobxReactForm({ fields }, { plugins, hooks });
}
