import React, { useEffect } from 'react';
import './css/index.scss';
import NotFound from './components/NotFound';
import PrivateRoute from './hocs/PrivateRoute';
import AuthRoute from './hocs/AuthRoute';
import Waiting from './components/waiting';
import SignIn from './containers/SignIn';
import CheckEmail from './containers/CheckEmail';
import EmailSignIn from './containers/EmailSignIn';
import TokenSignIn from './containers/TokenSignIn';
import EmailSignInError from './containers/EmailSignInError';
import ConfirmationModal from './containers/ConfirmationModal';

import CompaniesList from './containers/super/CompaniesList';
import CompanyEdit from './containers/super/CompanyEdit';
import PackageEdit from './containers/super/PackageEdit';
import Mailhooks from './containers/super/MailHooks';
import Content from './containers/super/Content';
import LanguageEdit from './containers/super/LanguageEdit';
import Notifications from './containers/super/Notifications';
import NotificationTargets from './containers/super/NotificationTargets';
import Languages from './containers/super/Languages';
import LanguageView from './containers/super/LanguageView';
import CompanyView from './containers/super/CompanyView';
import SettingsOverview from './containers/super/SettingsOverview';
import CompanyPPVLogView from './containers/super/CompanyPPVLogView';
import CompanyPPVTransactionsView from './containers/super/CompanyPPVTransactionsView';
import PartnerEdit from './containers/super/PartnerEdit';
import PartnerView from './containers/super/PartnerView';
import PartnersList from './containers/super/PartnersList';
import FreemiumList from './containers/super/FreemiumList';
import SubscriptionList from './containers/super/SubscriptionList';
import Payments from './containers/super/Payments';
import VideosList from './containers/super/VideosList';
import Cache from './containers/super/Cache';
import Schedule from './containers/super/Schedule';
import UsersList from './containers/super/UsersList';

import Snackbar from './components/Snackbar';
import { useStores } from './hooks/use-stores';
import { ThemeProvider } from 'styled-components';
import { awareGoTheme as theme } from './theme';
import { useLocation, Route, Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { observer, Observer } from 'mobx-react';
import Header from './Header';
import Grid from '@material-ui/core/Grid';
import Builds from './containers/super/builds';
import Stats from './containers/super/Stats';
import TrialPlan from './containers/super/trialplan';
import ScheduledTasksList from './containers/super/ScheduledTasksList';
import ServiceTasksList from './containers/super/ServiceTasksList';
import ScheduledTask from './containers/super/ScheduledTask';
import ServiceTask from './containers/super/ServiceTask';
import Integrations from './containers/Integrations';
import Admins from './containers/super/admins';

function App() {
    const { commonStore, authStore } = useStores();
    const { appLoaded, messageShown } = commonStore;
    const { isAuthenticated, currentUser } = authStore;
    let location = useLocation();

    let date = new Date();

    const selectedNavSection = () => {
        let pathname = location.pathname;
        if (pathname.toLowerCase().startsWith('/companies')) return 1;
        else if (pathname.toLowerCase().startsWith('/partners')) return 3;
        else if (pathname.toLowerCase().startsWith('/freemium')) return 4;
        else if (pathname.toLowerCase().startsWith('/subscriptions')) return 5;
        else if (pathname.toLowerCase().startsWith('/languages')) return 6;
        else if (pathname.toLowerCase().startsWith('/content')) return 7;
        else if (pathname.toLowerCase().startsWith('/trainings')) return 8;
        else if (pathname.toLowerCase().startsWith('/videos')) return 9;
        else if (pathname.toLowerCase().startsWith('/notifications')) return 10;
        else if (pathname.toLowerCase().startsWith('/stats')) return 11;
        return 1;
    };
    const selectedSection = selectedNavSection();

    const getInitials = (name) => {
        if (!name) {
            return '';
        }
        return name.split(' ').map((n) => n[0]);
    };
    const currentUserInitials = currentUser
        ? getInitials(currentUser.name)
        : '';

    const loadApp = async () => {
        if (authStore.token) {
            try {
                await Promise.all([
                    authStore.pullUser(),
                    commonStore.loadLanguages(),
                ]);
            } finally {
                await commonStore.setAppLoaded();
            }
        } else {
            await commonStore.loadLanguages();
            await commonStore.setAppLoaded();
        }
    };

    useEffect(() => {
        loadApp();
    }, []);

    const closeSnackbar = () => {
        commonStore.hideMessage();
    };

    const handleSignout = () => {
        authStore.logout();
    };

    return (
        <ThemeProvider theme={theme}>
            <Switch>
                <Route
                    path="/auth/email/signin/:token"
                    exact
                    component={EmailSignIn}
                />
                <Route
                    path="/auth/jwt/signin/:token"
                    exact
                    component={TokenSignIn}
                />
                <Route
                    render={() => {
                        return (
                            <Observer>
                                {() => {
                                    return appLoaded ? (
                                        <div className="layout_wrapper">
                                            <Helmet
                                                titleTemplate={
                                                    '%s | Super AwareGO'
                                                }
                                                defaultTitle="Super AwareGO"
                                            />
                                            <Header
                                                isAuthenticated={
                                                    isAuthenticated
                                                }
                                                currentUserInitials={
                                                    currentUserInitials
                                                }
                                                currentUser={currentUser}
                                                selectedSection={
                                                    selectedSection
                                                }
                                                handleSignout={handleSignout}
                                            />
                                            <main className="container_wrapper">
                                                <Grid
                                                    container
                                                    direction="row"
                                                    justify="center"
                                                    className="Container Flipped"
                                                    spacing={2}
                                                >
                                                    <Grid
                                                        item
                                                        xs={11}
                                                        md={11}
                                                        lg={11}
                                                        xl={10}
                                                        className="main_grid_wrapper Content"
                                                    >
                                                        <Switch>
                                                            <PrivateRoute
                                                                path={`/`}
                                                                exact
                                                                component={
                                                                    CompaniesList
                                                                }
                                                            />
                                                            <PrivateRoute
                                                                path={`/builds`}
                                                                component={
                                                                    Builds
                                                                }
                                                            />
                                                            <PrivateRoute
                                                                path={`/admins`}
                                                                component={
                                                                    Admins
                                                                }
                                                            />
                                                            <PrivateRoute
                                                                path={`/companies`}
                                                                exact
                                                                component={
                                                                    CompaniesList
                                                                }
                                                            />
                                                            <PrivateRoute
                                                                path={`/companies/create`}
                                                                exact
                                                                component={
                                                                    CompanyEdit
                                                                }
                                                            />
                                                            <PrivateRoute
                                                                path={`/companies/settings`}
                                                                exact
                                                                component={
                                                                    SettingsOverview
                                                                }
                                                            />
                                                            <PrivateRoute
                                                                path={`/companies/:companyId`}
                                                                exact
                                                                component={
                                                                    CompanyView
                                                                }
                                                            />
                                                            <PrivateRoute
                                                                path={`/companies/:companyId/ppv_log`}
                                                                exact
                                                                component={
                                                                    CompanyPPVLogView
                                                                }
                                                            />
                                                            <PrivateRoute
                                                                path={`/companies/:companyId/ppv_transactions`}
                                                                exact
                                                                component={
                                                                    CompanyPPVTransactionsView
                                                                }
                                                            />

                                                            {/* omitting it for now, because there are different ways to see trainings */}
                                                            {/* <PrivateRoute path={`/companies/:companyId/automations`} exact component={Automations}/> */}

                                                            <PrivateRoute
                                                                path={`/companies/:companyId/edit`}
                                                                exact
                                                                component={
                                                                    CompanyEdit
                                                                }
                                                            />
                                                            <PrivateRoute
                                                                path={`/companies/:companyId/editPackage/:packageId`}
                                                                exact
                                                                component={
                                                                    PackageEdit
                                                                }
                                                            />
                                                            <PrivateRoute
                                                                path={`/mailhooks`}
                                                                exact
                                                                component={
                                                                    Mailhooks
                                                                }
                                                            />

                                                            {/* fix Content inner routes, it is not working now */}
                                                            <PrivateRoute
                                                                path={`/integrations`}
                                                                component={
                                                                    Integrations
                                                                }
                                                            />

                                                            {/* fix Content inner routes, it is not working now */}
                                                            <PrivateRoute
                                                                path={`/content`}
                                                                component={
                                                                    Content
                                                                }
                                                            />

                                                            <PrivateRoute
                                                                path={`/stats`}
                                                                component={
                                                                    Stats
                                                                }
                                                            />

                                                            <PrivateRoute
                                                                path={`/notifications`}
                                                                exact
                                                                component={
                                                                    Notifications
                                                                }
                                                            />
                                                            <PrivateRoute
                                                                path={`/notifications/:reasonId`}
                                                                exact
                                                                component={
                                                                    NotificationTargets
                                                                }
                                                            />
                                                            <PrivateRoute
                                                                path={`/languages`}
                                                                exact
                                                                component={
                                                                    Languages
                                                                }
                                                            />
                                                            <PrivateRoute
                                                                path={`/languages/create`}
                                                                exact
                                                                component={
                                                                    LanguageEdit
                                                                }
                                                            />
                                                            <PrivateRoute
                                                                path={`/languages/:languageId`}
                                                                exact
                                                                component={
                                                                    LanguageView
                                                                }
                                                            />
                                                            <PrivateRoute
                                                                path={`/languages/:languageId/edit`}
                                                                exact
                                                                component={
                                                                    LanguageEdit
                                                                }
                                                            />
                                                            <PrivateRoute
                                                                path={`/partners/create`}
                                                                exact
                                                                component={
                                                                    PartnerEdit
                                                                }
                                                            />
                                                            <PrivateRoute
                                                                path={`/partners/:partnerId/edit`}
                                                                exact
                                                                component={
                                                                    PartnerEdit
                                                                }
                                                            />
                                                            {/* CompaniesList needs to be readjusted to work for Partner page too */}
                                                            <PrivateRoute
                                                                path={`/partners/:partnerId/companies`}
                                                                exact
                                                                component={
                                                                    CompaniesList
                                                                }
                                                            />
                                                            <PrivateRoute
                                                                path={`/partners/:partnerId/companies/:companyId`}
                                                                exact
                                                                component={
                                                                    CompanyView
                                                                }
                                                            />
                                                            <PrivateRoute
                                                                path={`/partners/:partnerId`}
                                                                exact
                                                                component={
                                                                    PartnerView
                                                                }
                                                            />
                                                            <PrivateRoute
                                                                path={`/partners`}
                                                                exact
                                                                component={
                                                                    PartnersList
                                                                }
                                                            />
                                                            <PrivateRoute
                                                                path={`/scheduledTasks/:scheduledTaskId/edit`}
                                                                exact
                                                                component={
                                                                    ScheduledTask
                                                                }
                                                            />

                                                            <PrivateRoute
                                                                path={`/scheduledTasks/create`}
                                                                exact
                                                                component={
                                                                    ScheduledTask
                                                                }
                                                            />

                                                            <PrivateRoute
                                                                path={`/scheduledTasks`}
                                                                exact
                                                                component={
                                                                    ScheduledTasksList
                                                                }
                                                            />

                                                            <PrivateRoute
                                                                path={`/serviceTasks/create`}
                                                                exact
                                                                component={
                                                                    ServiceTask
                                                                }
                                                            />

                                                            <PrivateRoute
                                                                path={`/serviceTasks`}
                                                                exact
                                                                component={
                                                                    ServiceTasksList
                                                                }
                                                            />
                                                            <PrivateRoute
                                                                path={`/freemium`}
                                                                exact
                                                                component={
                                                                    FreemiumList
                                                                }
                                                            />
                                                            <PrivateRoute
                                                                path={`/subscriptions`}
                                                                exact
                                                                component={
                                                                    SubscriptionList
                                                                }
                                                            />
                                                            <PrivateRoute
                                                                path={`/payments`}
                                                                exact
                                                                component={
                                                                    Payments
                                                                }
                                                            />
                                                            <PrivateRoute
                                                                path={`/videos/:tags`}
                                                                exact
                                                                component={
                                                                    VideosList
                                                                }
                                                            />
                                                            <PrivateRoute
                                                                path={`/cache`}
                                                                exact
                                                                component={
                                                                    Cache
                                                                }
                                                            />
                                                            <PrivateRoute
                                                                path={`/trial`}
                                                                exact
                                                                component={
                                                                    TrialPlan
                                                                }
                                                            />
                                                            <PrivateRoute
                                                                path={`/trainings`}
                                                                exact
                                                                component={
                                                                    Schedule
                                                                }
                                                            />
                                                            <PrivateRoute
                                                                path={`/users`}
                                                                exact
                                                                component={
                                                                    UsersList
                                                                }
                                                            />

                                                            <AuthRoute
                                                                path="/auth/signin"
                                                                exact
                                                                component={
                                                                    SignIn
                                                                }
                                                            />
                                                            <AuthRoute
                                                                path="/auth/check-email"
                                                                exact
                                                                component={
                                                                    CheckEmail
                                                                }
                                                            />
                                                            <Route
                                                                path="/auth/error/email"
                                                                exact
                                                                component={
                                                                    EmailSignInError
                                                                }
                                                            />

                                                            {/* Finally, catch all unmatched routes */}
                                                            <Route
                                                                component={
                                                                    NotFound
                                                                }
                                                            />
                                                        </Switch>
                                                    </Grid>
                                                </Grid>
                                            </main>
                                            <footer>
                                                <a
                                                    href="https://www.awarego.com/privacy"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    Privacy Policy
                                                </a>{' '}
                                                &nbsp;&#183; ©{' '}
                                                {date.getFullYear()} AwareGO
                                            </footer>
                                        </div>
                                    ) : (
                                        <Waiting
                                            waitingActive={!appLoaded}
                                            fullScreen={true}
                                        />
                                    );
                                }}
                            </Observer>
                        );
                    }}
                />
            </Switch>
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                autoHideDuration={6000}
                variant={commonStore.messageType}
                message={commonStore.message || ''}
                open={messageShown}
                onClose={closeSnackbar}
            ></Snackbar>

            <ConfirmationModal />
        </ThemeProvider>
    );
}

export default observer(App);
