import { observer } from 'mobx-react';
import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import { Fragment } from 'react';

function packageEdit({ form, subscriptions }) {
    const setExternalId = form.$('set_external_id');
    const subscriptionField = form.$('external_id');
    const packageNameField = form.$('package_name');
    const usersField = form.$('users');
    const validField = form.$('valid_to');
    const trialField = form.$('trial');

    return (
        <Grid
            container
            direction="row"
            justify="center"
            align="center"
            spacing={2}
        >
            {subscriptionField.value &&
                (subscriptionField.value.startsWith('partner') ||
                    subscriptionField.value.startsWith('company')) && (
                    <Fragment>
                        <Grid item xs={12}>
                            <TextField
                                type="text"
                                name="set_external_id"
                                onChange={setExternalId.onChange}
                                value={setExternalId.value}
                                label={setExternalId.label}
                                variant="outlined"
                                error={
                                    setExternalId.error &&
                                    setExternalId.error.length > 0
                                        ? true
                                        : false
                                }
                                helperText={setExternalId.error}
                                placeholder={setExternalId.placeholder}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl variant="filled">
                                <InputLabel>Subscription plan</InputLabel>
                                <Select
                                    label={subscriptionField.label}
                                    required
                                    error={
                                        subscriptionField.error &&
                                        subscriptionField.error.length > 0
                                            ? true
                                            : false
                                    }
                                    helperText={subscriptionField.error}
                                    placeholder={subscriptionField.placeholder}
                                    variant="filled"
                                    name="external_id"
                                    value={subscriptionField.value}
                                    onChange={subscriptionField.onChange}
                                >
                                    {subscriptions &&
                                        subscriptions.map((s) => (
                                            <MenuItem
                                                key={s.code}
                                                value={s.code}
                                            >
                                                {s.code}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Fragment>
                )}
            {packageNameField.value &&
                (packageNameField.value.startsWith('company-') ||
                    packageNameField.value.startsWith('lms-')) && (
                    <Grid item xs={12}>
                        <FormControl variant="filled">
                            <InputLabel>Subscription plan</InputLabel>
                            <Select
                                label={packageNameField.label}
                                required
                                error={
                                    packageNameField.error &&
                                    packageNameField.error.length > 0
                                        ? true
                                        : false
                                }
                                helperText={packageNameField.error}
                                placeholder={packageNameField.placeholder}
                                variant="filled"
                                name="package_name"
                                value={packageNameField.value}
                                onChange={packageNameField.onChange}
                            >
                                {subscriptions &&
                                    subscriptions.map((s) => (
                                        <MenuItem
                                            key={s.code}
                                            value={s.externalId}
                                        >
                                            {s.code}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    </Grid>
                )}
            <Grid item xs={12}>
                <TextField
                    type="text"
                    name="users"
                    onChange={usersField.onChange}
                    value={usersField.value}
                    label={usersField.label}
                    variant="outlined"
                    required
                    error={
                        usersField.error && usersField.error.length > 0
                            ? true
                            : false
                    }
                    helperText={usersField.error}
                    placeholder={usersField.placeholder}
                />
            </Grid>
            <Grid item xs={12}>
                <FormControl variant="filled">
                    <InputLabel>Trial</InputLabel>
                    <Select
                        variant="filled"
                        name="trial"
                        value={trialField.value}
                        onChange={trialField.onChange}
                    >
                        <MenuItem value="0">No</MenuItem>
                        <MenuItem value="1">Yes</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    variant="filled"
                    name="valid_to"
                    label="Valid to"
                    value={moment(validField.value).format('YYYY-MM-DD')}
                    onChange={validField.onChange}
                    type="date"
                />
            </Grid>
        </Grid>
    );
}

export default observer(packageEdit);
