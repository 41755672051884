import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import ThemedSpinner from '../../components/themed/ThemedSpinner';
import { useStores } from '../../hooks/use-stores';
import { Link, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { enforceSlash, formatDate, formatStatus } from '../../utils/helpers';
import SimpleTable from '../../components/super/SimpleTable';
import moment from 'moment';
import commonStore from '../../stores/commonStore';
import { CircularProgress } from '@material-ui/core';

function ScheduledTask() {
    let { scheduledTaskId } = useParams();
    const { commonStore, scheduledTasksStore } = useStores();
    const {
        loadingScheduledTask,
        editingScheduledTask,
        editingTaskDefinition,
        editingTaskRrule,
        updatingTasks,
        creatingTask,
        runningReport,
    } = scheduledTasksStore;

    const [nameError, setNameError] = useState(false);
    const [definitionError, setDefinitionError] = useState(false);
    const [rruleError, setRruleError] = useState(false);
    let history = useHistory();
    let match = useRouteMatch();

    const loadData = async () => {
        scheduledTaskId
            ? await scheduledTasksStore.loadScheduledTask(scheduledTaskId)
            : (scheduledTasksStore.editingScheduledTask = {
                  name: '',
                  definition: '',
                  tags: [],
              });
    };

    useEffect(() => {
        loadData();
    }, [scheduledTaskId]);

    const handleChangeDefinition = (e) => {
        scheduledTasksStore.setDefinition(e.target.value);
    };

    const handleChangeRrule = (e) => {
        scheduledTasksStore.setRrule(e.target.value);
    };

    const scheduleNext = (e) => {
        scheduledTasksStore.scheduleNext(scheduledTaskId);
    };

    const handleSave = async () => {
        if (!scheduledTasksStore.editingScheduledTask.name) {
            setNameError(true);
            return;
        }
        setNameError(false);
        try {
            JSON.parse(editingTaskDefinition);
            setDefinitionError(false);
        } catch (e) {
            setDefinitionError(true);
        }

        try {
            if (editingTaskRrule && editingTaskRrule.length > 0)
                setRruleError(true);
        } catch (e) {
            setRruleError(false);
        }

        let result;
        scheduledTaskId
            ? (result = await scheduledTasksStore.updateTask(
                  scheduledTasksStore.editingScheduledTask.id,
                  {
                      ...scheduledTasksStore.editingScheduledTask,
                      definition: editingTaskDefinition,
                      rrule: editingTaskRrule,
                  }
              ))
            : (result = await scheduledTasksStore.createTask({
                  ...scheduledTasksStore.editingScheduledTask,
                  definition: editingTaskDefinition,
                  rrule: editingTaskRrule,
              }));
        if (result) {
            scheduledTaskId
                ? history.push(`/scheduledTasks/${scheduledTaskId}/edit`)
                : history.push('/scheduledTasks');
        }
    };
    const runReport = (x) => {
        scheduledTasksStore.runReport(x.value);
    };
    const showDetails = (x) => {
        commonStore.showConfirm(
            <pre>
                {typeof x.value === 'string'
                    ? x.value
                    : JSON.stringify(x.value, null, 2)}
            </pre>,
            'Close',
            'Error Details',
            null,
            false
        );
    };

    const columns = React.useMemo(
        () => [
            {
                Header: 'Id',
                accessor: 'id',
                Cell: (x) => (
                    <Link to={`${enforceSlash(match.url)}${x.value}/edit`}>
                        {x.value}
                    </Link>
                ),
            },
            {
                Header: 'Name',
                accessor: 'name',
            },
            {
                Header: 'scheduledAt',
                accessor: 'scheduledAt',
                Cell: (x) => moment(x.value).format('LLL'),
            },
            {
                Header: ' Status',
                accessor: 'status',
                Cell: (x) => formatStatus(x.value),
            },
            {
                Header: ' Error',
                accessor: 'error',
                Cell: (x) => (
                    <span
                        onClick={() => {
                            showDetails(x);
                        }}
                    >
                        {' '}
                        {(x.value || '-').substring(0, 50)}
                    </span>
                ),
            },
            {
                Header: 'Artifacts',
                accessor: 'artifacts',
                Cell: (x) => (
                    <span
                        onClick={() => {
                            showDetails(x);
                        }}
                    >
                        {' '}
                        {JSON.stringify(x.value).substring(0, 50)}
                    </span>
                ),
            },
            {
                id: 'action',
                accessor: 'id',
                Cell: (x) =>
                    x.row.original.status === 0 ? (
                        runningReport ? (
                            <CircularProgress />
                        ) : (
                            <Button
                                onClick={() => {
                                    runReport(x);
                                }}
                                color="secondary"
                                variant="contained"
                            >
                                Run Now
                            </Button>
                        )
                    ) : null,
            },
        ],
        []
    );

    return loadingScheduledTask || !editingScheduledTask ? (
        <ThemedSpinner />
    ) : (
        editingScheduledTask && (
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                align="center"
                spacing={2}
            >
                {scheduledTaskId && (
                    <SimpleTable
                        columns={columns}
                        data={editingScheduledTask.executions}
                        pageSize={5}
                    />
                )}

                {scheduledTaskId &&
                    (!editingScheduledTask.executions ||
                        !editingScheduledTask.executions.length > 0) && (
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={scheduleNext}
                        >
                            Schedule Next
                        </Button>
                    )}

                <Grid item xs={12}>
                    <Box mt={4}>
                        <Typography variant="h2" gutterBottom>
                            Task: {editingScheduledTask.name}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={10}>
                    <TextField
                        type="text"
                        name="taskName"
                        value={editingScheduledTask.name || ''}
                        onChange={(e) => {
                            editingScheduledTask.name = e.target.value;
                        }}
                        error={nameError}
                        helperText={nameError ? 'Name is required' : ''}
                        variant="outlined"
                        label="Task name"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={10}>
                    <TextField
                        type="text"
                        name="accountId"
                        value={editingScheduledTask.accountId || ''}
                        onChange={(e) => {
                            editingScheduledTask.accountId =
                                e.target.value || '';
                        }}
                        variant="outlined"
                        label="accountId"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={10}>
                    <TextField
                        type="text"
                        name="companyId"
                        value={editingScheduledTask.companyId || ''}
                        onChange={(e) => {
                            editingScheduledTask.companyId =
                                e.target.value || '';
                        }}
                        variant="outlined"
                        label="companyId"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={10}>
                    <TextField
                        type="textarea"
                        multiline
                        rows={15}
                        cols={70}
                        name="questionDefinition"
                        value={editingTaskDefinition || ''}
                        onChange={handleChangeDefinition}
                        error={definitionError}
                        helperText={
                            definitionError
                                ? 'Should be a valid JSON: ' + definitionError
                                : ''
                        }
                        variant="outlined"
                        label="Definition"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={10}>
                    <TextField
                        type="textarea"
                        multiline
                        rows={15}
                        cols={70}
                        name="questionRrule"
                        value={editingTaskRrule || ''}
                        onChange={handleChangeRrule}
                        error={rruleError}
                        helperText={definitionError ? 'Reuired field ' : ''}
                        variant="outlined"
                        label="Rrule"
                        fullWidth
                    />
                </Grid>

                <Grid item xs={12}>
                    <Box mb={match.path.includes('edit') ? 0 : 4}>
                        {creatingTask ||
                        (scheduledTaskId &&
                            updatingTasks.includes(scheduledTaskId)) ? (
                            <ThemedSpinner />
                        ) : (
                            <Button
                                onClick={handleSave}
                                color="primary"
                                variant="contained"
                            >
                                Save
                            </Button>
                        )}
                    </Box>
                </Grid>
            </Grid>
        )
    );
}

export default observer(ScheduledTask);
