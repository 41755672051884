import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import SimpleTable from './SimpleTable';
import ThemedSpinner from '../themed/ThemedSpinner';

function ContentGroupAccess({
    aclGroups,
    aclRules,
    principalUniqueId,
    addToGroup,
    showLoader,
    removeFromGroup,
}) {
    const findAclRule = (unique_id) =>
        aclRules.find(
            (aclRule) =>
                aclRule.object_unique_id === unique_id &&
                aclRule.principal_unique_id === principalUniqueId
        );

    const groupIsInActiveRules = (unique_id) => {
        const rule = findAclRule(unique_id);
        return rule && rule.rule === 1;
    };

    const columns = [
        {
            Header: 'Group name',
            accessor: 'name',
        },
        {
            id: 'addToGroup',
            accessor: (x) => (
                <Grid container direction="row" alignItems="center" spacing={1}>
                    <Grid item>
                        {groupIsInActiveRules(x.unique_id) ? (
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => {
                                    const rule = findAclRule(x.unique_id);
                                    rule && removeFromGroup(rule.id);
                                }}
                                startIcon={<DeleteIcon />}
                            >
                                Remove from group
                            </Button>
                        ) : (
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    addToGroup(
                                        principalUniqueId,
                                        x.unique_id,
                                        1
                                    );
                                }}
                                startIcon={<AddIcon />}
                            >
                                Add to group
                            </Button>
                        )}
                    </Grid>
                </Grid>
            ),
        },
    ];

    return (
        <>
            {showLoader ? (
                <ThemedSpinner />
            ) : (
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    spacing={2}
                >
                    <Grid item xs={12}>
                        <SimpleTable columns={columns} data={aclGroups} />
                    </Grid>
                </Grid>
            )}
        </>
    );
}

export default ContentGroupAccess;
