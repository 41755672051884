import { action, observable, runInAction } from 'mobx';
import { extractErrorMessage } from './helpers';

export function generateLoadList(name, store, loadFlag, loaderFn, targetProp) {
    if (store[loadFlag] === undefined) {
        store[loadFlag] = observable(observable.box(false));
        store[loadFlag] = false;
    }
    if (store[targetProp] === undefined) store[targetProp] = observable([]);

    return action(async (options) => {
        if (store[loadFlag]) return;
        store[loadFlag] = true;
        try {
            const result = await loaderFn(options);
            runInAction(() => {
                store[targetProp].replace(result);
            });
            return result;
        } catch (e) {
            console.error(e);
            store.setError(name, extractErrorMessage(e));
        } finally {
            runInAction(() => {
                store[loadFlag] = false;
            });
        }
    });
}

export function generateLoadEntity(
    name,
    store,
    loadFlag,
    loaderFn,
    targetProp
) {
    return action(async (options) => {
        if (store[loadFlag]) return;
        store[loadFlag] = true;
        try {
            const result = await loaderFn(options);
            runInAction(() => {
                if (typeof targetProp === 'function')
                    targetProp(options, result);
                else store[targetProp] = result;
            });
            return true;
        } catch (e) {
            runInAction(() => {
                store.setError(name, extractErrorMessage(e));
            });
        } finally {
            runInAction(() => {
                store[loadFlag] = false;
            });
        }
    });
}

export function generateCreateEntity(
    name,
    store,
    creatingFlag,
    createFn,
    targetProp
) {
    return action(async function () {
        if (store[creatingFlag]) return;
        store[creatingFlag] = true;
        try {
            const result = await createFn.apply(store, arguments);
            if (targetProp)
                runInAction(() => {
                    store[targetProp] = result;
                });
            return result;
        } catch (e) {
            runInAction(() => {
                store.setError(name, extractErrorMessage(e));
            });
        } finally {
            runInAction(() => {
                store[creatingFlag] = false;
            });
        }
    });
}

export function generateUpdateEntity(
    name,
    store,
    updatingArray,
    updateFn,
    onUpdated
) {
    if (store[updatingArray] === undefined)
        store[updatingArray] = observable([]);

    return action(async function (key) {
        if (Array.isArray(store[updatingArray])) {
            if (store[updatingArray].includes(key)) return;
            store[updatingArray].push(key);
        } else {
            if (store[updatingArray]) return;
            store[updatingArray] = true;
        }
        try {
            const result = await updateFn.apply(store, arguments);
            if (onUpdated) onUpdated(result);
            return result;
        } catch (e) {
            runInAction(() => {
                store.setError(name, extractErrorMessage(e));
            });
        } finally {
            runInAction(() => {
                if (Array.isArray(store[updatingArray])) {
                    store[updatingArray].remove(key);
                } else store[updatingArray] = false;
            });
        }
    });
}

export function generateDeleteEntity(
    name,
    store,
    removingArray,
    deleteFn,
    keyAccessor
) {
    if (store[removingArray] === undefined)
        store[removingArray] = observable([]);

    return action(async function (key, onDeleted) {
        if (keyAccessor) key = keyAccessor(key);
        if (store[removingArray].includes(key)) return;
        store[removingArray].push(key);
        try {
            const result = await deleteFn.apply(store, arguments);
            if (onDeleted) onDeleted(result);
            return result;
        } catch (e) {
            console.error(e);
            runInAction(() => {
                store.setError(name, extractErrorMessage(e));
            });
        } finally {
            runInAction(() => {
                store[removingArray].remove(key);
            });
        }
    });
}
