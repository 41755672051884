import React, { Fragment } from 'react';
import { Row, Col } from 'reactstrap';
import ThemedSpinner from './themed/ThemedSpinner';

function Waiting({ waitingActive, fullScreen }) {
    return !waitingActive ? (
        ''
    ) : (
        <Fragment>
            {!fullScreen ? (
                <Row>
                    <Col md="12" xs="12" className="content">
                        <ThemedSpinner
                            message={'One moment. We are doing some stuff...'}
                        />
                    </Col>
                </Row>
            ) : (
                <div className="loading-fullscreen">
                    <ThemedSpinner
                        fullscreen
                        message={'One moment. We are doing some stuff...'}
                    />
                </div>
            )}
        </Fragment>
    );
}

export default Waiting;
