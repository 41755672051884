import RestService from './RestService';

export default class Users extends RestService {
    constructor(parentService, parentId) {
        if (parentService && parentId) {
            super(`${parentService.baseUrl}/${parentId}/users`);
        } else super('/users');
    }

    allUsers(params) {
        return this.get(`${this.baseUrl}/usersForAdmin`, params);
    }

    userStatus(email) {
        return this.get(`${this.baseUrl}/status/${encodeURIComponent(email)}`);
    }

    userDetails(email) {
        return this.get(
            `${this.baseUrl}/campaignDetails/${encodeURIComponent(email)}`
        );
    }
}
