import React, { Fragment, useEffect } from 'react';
import { observer } from 'mobx-react';
import ErrorMessage from '../../components/errormessage';
import ThemedSpinner from '../../components/themed/ThemedSpinner';
import { useStores } from '../../hooks/use-stores';
import { useParams } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import AddRemoveList from '../../components/super/add-remove-list';

function SubjectTemplates() {
    const { superStore } = useStores();
    let { subjectId } = useParams();
    const {
        loadingSubject,
        editingSubject,
        templatesErrorError,
        loadingTemplates,
        templates,
        //loadCategorySubjects,
        editingSubjectTemplates,
        addTemplateSubject,
        deleteTemplateSubject,
    } = superStore;

    const loadData = async () => {
        await superStore.loadTemplates();
        await superStore.loadSubject(subjectId);
        await superStore.loadSubjectTemplates(subjectId);
    };

    useEffect(() => {
        loadData();
    }, [subjectId]);

    /*const mapSubjects = async (subjects) => {
        return await subjects.map((item) => {
            item.name = `[${item.episode_number}] ${item.title}`;
        });
    };*/

    return (
        <Grid
            container
            direction="row"
            justify="center"
            align="center"
            spacing={2}
        >
            {loadingSubject || loadingTemplates || !editingSubjectTemplates ? (
                <ThemedSpinner />
            ) : (
                <Fragment>
                    {templatesErrorError && (
                        <Grid item>
                            <ErrorMessage error={templatesErrorError} />
                        </Grid>
                    )}
                    {editingSubject && editingSubjectTemplates && (
                        <Fragment>
                            <Grid item xs={12}>
                                <Box mt={4}>
                                    <Typography variant="h2">
                                        Subject{`: ${editingSubject.title}`}
                                    </Typography>
                                </Box>
                            </Grid>
                            {!(
                                editingSubjectTemplates &&
                                editingSubjectTemplates.length > 0
                            ) ? (
                                <ThemedSpinner />
                            ) : (
                                <Fragment>
                                    <Grid item xs={12}>
                                        <Typography variant="h4" gutterBottom>
                                            Templates
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <AddRemoveList
                                            addItem={async (templateId) => {
                                                await addTemplateSubject(
                                                    templateId,
                                                    subjectId
                                                );
                                                await superStore.loadSubjectTemplates(
                                                    subjectId
                                                );
                                            }}
                                            removeItem={async (templateId) => {
                                                await deleteTemplateSubject(
                                                    templateId,
                                                    subjectId
                                                );
                                                await superStore.loadSubjectTemplates(
                                                    subjectId
                                                );
                                            }}
                                            itemList={templates}
                                            activeItemList={
                                                editingSubjectTemplates
                                            }
                                            idColumn={'id'}
                                            nameColumn={'title'}
                                        />
                                    </Grid>
                                </Fragment>
                            )}
                        </Fragment>
                    )}
                </Fragment>
            )}
        </Grid>
    );
}

export default observer(SubjectTemplates);
