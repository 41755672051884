import { reaction } from 'mobx';
import { backOffDelay, extractErrorMessage } from '../utils/helpers';
import services from '../services';
import { makeAutoObservable } from 'mobx';

class AuthStore {
    constructor(commonStore) {
        makeAutoObservable(this);
        this.commonStore = commonStore;
        reaction(
            () => this.token,
            (token) => {
                if (token) {
                    window.localStorage.setItem('jwt', token);
                } else {
                    window.localStorage.removeItem('jwt');
                }
            }
        );
    }

    token = window.localStorage.getItem('jwt');
    inProgress = false;
    creatingTrial = false;
    currentUser = null;

    loginError = undefined;
    emailLoginError = undefined;
    trialError = undefined;
    signupError = undefined;
    profileError = undefined;
    companyError = undefined;
    company_id = undefined;
    campaign_id = undefined;
    subject_id = undefined;
    email = undefined;
    suggestTrial = false;
    extraMessage = false;

    setError(error, type = 'login') {
        if (error instanceof Error) {
            error = extractErrorMessage(error);
        }
        this.loginError = type === 'login' ? error : null;
        this.emailLoginError = type === 'emailLogin' ? error : null;
        this.trialError = type === 'trial' ? error : null;
        this.signupError = type === 'signup' ? error : null;
        this.profileError = type === 'profile' ? error : null;
    }

    async logout() {
        this.inProgress = false;
        this.forgetUser();
        this.setToken(null);
    }

    setToken(token) {
        this.token = token;
    }

    setEmail(email) {
        this.email = email;
    }

    get displayName() {
        if (!this.currentUser) return '';
        return this.currentUser.name || this.currentUser.email;
    }

    get isAuthenticated() {
        return this.currentUser != null;
    }

    get isPartnerAdmin() {
        return (
            this.currentUser &&
            this.currentUser.superForAccounts &&
            this.currentUser.superForAccounts.length > 0
        );
    }

    /*isPartnerAdminOfCompany(companyId) {
        this.currentUser.superForAccounts;
    }*/

    get isTeamManager() {
        if (!this.currentUser) return false;
        return this.currentUser.permissions.indexOf('manageUsers') > -1;
    }

    setUser(user) {
        this.currentUser = user;
    }

    setExtraMessage(message) {
        this.extraMessage = message;
    }

    forgetUser() {
        this.currentUser = undefined;
    }

    async signin(email, company_id, campaign_id, subject_id, redirect, scope) {
        this.suggestTrial = false;
        this.setError(null);
        try {
            this.setExtraMessage('');
            let result = await services.Auth.signin(
                email,
                company_id,
                campaign_id,
                subject_id,
                redirect,
                scope
            );
            if (result.url) {
                window.location = result.url;
                return 'redirect';
            }
            if (result.status) return true;
            else {
                this.suggestTrial = true;
            }
        } catch (e) {
            this.setError(e);
        }
    }

    async signinFromEmail(token) {
        this.setError(null);
        try {
            let jwtToken = await services.Auth.emailsignin(token);
            this.setToken(jwtToken);
            this.pullUser();
            return true;
        } catch (e) {
            this.setError(e, 'emailLogin');
        }
    }

    async registerTrial(data, simple) {
        this.creatingTrial = true;
        try {
            let result = await services.Auth.registerTrial(data, simple);
            if (result && result.success && result.token) {
                this.setToken(result.token);
                this.pullUser();
                return 'signedin';
            }
            if (result.login) return 'email';
            return 'registered';
        } catch (ex) {
            this.setError(ex, 'trial');
        } finally {
            this.creatingTrial = false;
        }
    }

    async registerGdrp(data) {
        this.creatingTrial = true;
        //TODO: FIGURE OUT  error handling in this 2 steps operation. Should it be single request and make both on server?
        // in original version it was hiding error from  registerHubSpot call if registerDB goes good
        try {
            await services.Auth.registerFreemium(data);
            return true;
        } catch (ex) {
            this.setError(ex, 'trial');
        } finally {
            this.creatingTrial = false;
        }
    }

    async registerPPVTrial(data) {
        this.creatingTrial = true;
        data.ppv = true;
        //TODO: FIGURE OUT  error handling in this 2 steps operation. Should it be single request and make both on server?
        // in original version it was hiding error from  registerHubSpot call if registerDB goes good
        try {
            await services.Auth.registerTrial(data);
            return true;
        } catch (ex) {
            this.setError(ex, 'trial');
        } finally {
            this.creatingTrial = false;
        }
    }

    async profileUpdate(values) {
        this.setError(null, 'profile');
        try {
            let user = await services.Users.update({
                _id: this.currentUser._id,
                ...values,
            });
            Object.assign(this.currentUser, user);
            this.commonStore.success('Your profile successful updated');
        } catch (e) {
            this.setError(
                (e.response && e.response.data && e.response.data.error) ||
                    e.message,
                'profile'
            );
        }
    }

    async pullUser(counter = 0) {
        this.inProgress = true;
        try {
            if (this.token) {
                let user = await services.Auth.current();
                if (user) this.setUser(user);
            } else {
                this.logout();
            }
        } catch (e) {
            this.inProgress = false;
            if (
                counter < 10 &&
                e &&
                (!e.response ||
                    e.response.status !== 403 ||
                    e.response.status !== 401)
            ) {
                setTimeout(async () => {
                    await this.pullUser(counter + 1);
                }, backOffDelay(1500, counter));
            } else {
                //return this.logout();
            }
        } finally {
            this.inProgress = false;
        }
    }
}

export default AuthStore;
