import i18n from 'i18next';
import XHR from 'i18next-xhr-backend';

const backendOpts = {
    loadPath: `/static/locales/{{lng}}/{{ns}}.json`,
};
i18n.use(XHR).init({
    lng: window.localStorage.getItem('language') || 'en',
    fallbackLng: 'en',
    load: 'languageOnly',
    ns: ['common'],
    defaultNS: 'common',
    backend: backendOpts,
    debug: false,
});

export default i18n;
