import React from 'react';
import Alert from '@material-ui/lab/Alert';

function Errormessage({ error, errorType, children }) {
    return (!error || typeof error !== 'string') && !children ? (
        ''
    ) : (
        <Alert severity={errorType}>{children || error}</Alert>
    );
}

export default Errormessage;
