import RestService from './RestService';
import Managers from './ManagersService';
import Settings from './SettingsService';
import Companies from './CompaniesService';
import Subscriptions from './SubscriptionsService';

export default class Partners extends RestService {
    constructor() {
        super('/partners');
    }

    companiesService(partnerId) {
        return new Companies(false, this, partnerId);
    }

    subscriptionsService(partnerId) {
        return new Subscriptions(this, partnerId);
    }

    managersService(partnerId) {
        return new Managers(this, partnerId);
    }

    settingsService(partnerId) {
        return new Settings(this, partnerId);
    }

    openManageSession(partnerId) {
        return this.post(`${this.baseUrl}/${partnerId}/create_portal_session`);
    }

    togglePartner(partnerId, enabled) {
        return this.post(`${this.baseUrl}/${partnerId}/toggle`, { enabled });
    }

    export(params, options) {
        return this.get(`${this.baseUrl}/export`, params, options);
    }

    addLogo(partnerId, formData) {
        const result = this.put(`${this.baseUrl}/${partnerId}/logo`, formData, {
            headers: {
                ...this.commonHeaders().headers,
                'Content-Type': 'multipart/form-data',
            },
        });
        return result;
    }

    addFavicon(partnerId, formData) {
        const result = this.put(
            `${this.baseUrl}/${partnerId}/favicon`,
            formData,
            {
                headers: {
                    ...this.commonHeaders().headers,
                    'Content-Type': 'multipart/form-data',
                },
            }
        );
        return result;
    }

    removeLogo(partnerId) {
        return this.delete(`${this.baseUrl}/${partnerId}/logo`);
    }

    removeFavicon(partnerId) {
        return this.delete(`${this.baseUrl}/${partnerId}/favicon`);
    }
}
