import React, { useEffect } from 'react';
import { useParams, useRouteMatch } from 'react-router-dom';
import ErrorMessage from '../../components/errormessage';
import ThemedSpinner from '../../components/themed/ThemedSpinner';
import { useStores } from '../../hooks/use-stores';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import LanguageForm from '../../components/super/languageEdit';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

function LanguageEdit() {
    let { languageId } = useParams();
    const { superStore } = useStores();
    let history = useHistory();
    const { savingLanguage, languageError } = superStore;
    let match = useRouteMatch();

    const { languageEditForm: languageForm } = superStore;

    const loadData = async () => {
        if (languageId) {
            await superStore.loadLanguage(languageId);
        }
    };

    useEffect(() => {
        superStore.startEditLanguage(languageId, () => {
            return history.push('/languages');
        });
        loadData();
    }, [languageId]);

    return (
        <Grid
            container
            direction="row"
            justify="center"
            align="center"
            spacing={2}
        >
            <Grid item xs={12}>
                <Box mt={4}>
                    <Typography variant="h2" gutterBottom>
                        {match.url.includes('create') ? 'Create' : 'Edit'}{' '}
                        language
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={12}>
                {languageForm && <LanguageForm form={languageForm} />}
            </Grid>
            <Grid item xs={12}>
                <Box mb={4}>
                    {savingLanguage ? (
                        <ThemedSpinner />
                    ) : (
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={(e) => languageForm.onSubmit(e)}
                        >
                            Save
                        </Button>
                    )}
                </Box>
            </Grid>
            {languageError && (
                <Grid item xs={12}>
                    <ErrorMessage error={languageError} />
                </Grid>
            )}
        </Grid>
    );
}

export default observer(LanguageEdit);
