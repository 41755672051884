import React, { Fragment, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Link, useRouteMatch } from 'react-router-dom';
import { enforceSlash } from '../../utils/helpers';
import { useStores } from '../../hooks/use-stores';
import ThemedSpinner from '../../components/themed/ThemedSpinner';
import SimpleTable from '../../components/super/SimpleTable';
import { CircularProgress, Typography } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { Box } from '@material-ui/core';
import { Fab } from '@material-ui/core';
import { Tooltip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core/styles';
import Errormessage from '../../components/errormessage';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
    fab: {
        position: 'fixed',
        bottom: theme.spacing(4),
        right: theme.spacing(4),
    },
}));

function ServiceTasksList() {
    const { commonStore, serviceTasksStore } = useStores();
    const {
        preparingReport,
        abandoningReport,
        executingReport,
        loadingTasks,
        serviceTasks,
        error,
    } = serviceTasksStore;
    const classes = useStyles();
    let match = useRouteMatch();

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        void serviceTasksStore.loadServiceTasks();
    };

    const showDetails = (x) => {
        commonStore.showConfirm(
            <pre>
                {typeof x.value === 'string'
                    ? x.value
                    : JSON.stringify(x.value, null, 2)}
            </pre>,
            'Close',
            'Error Details',
            null,
            false
        );
    };

    const runPrepare = async (x) => {
        await serviceTasksStore.runPrepare(x.value);
        loadData();
    };

    const showFailedList = async (x) => {
        const failedList = await serviceTasksStore.loadFailedList(
            x.row.original.id
        );
        commonStore.showConfirm(
            <pre>{failedList.join('\n')}</pre>,
            'Close',
            'List of failed id ( first 20 ) ',
            null,
            false
        );
    };
    const runExecute = async (x) => {
        await serviceTasksStore.runExecute(x.value);
        loadData();
    };
    const runAbandon = async (x) => {
        await serviceTasksStore.runAbandon(x.value);
        loadData();
    };
    const runReset = async (x) => {
        await serviceTasksStore.runReset(x.value);
        loadData();
    };
    const reRunFailed = async (x) => {
        await serviceTasksStore.reRunFailed(x.value);
        loadData();
    };

    const columns = React.useMemo(
        () => [
            {
                Header: 'Id',
                accessor: 'id',
                Cell: (x) => (
                    <Link to={`${enforceSlash(match.url)}${x.value}/edit`}>
                        {x.value}
                    </Link>
                ),
            },
            {
                Header: 'Name',
                accessor: 'name',
            },
            {
                Header: 'itemsDone',
                accessor: 'itemsDone',
            },
            {
                Header: 'itemsFailed',
                accessor: 'itemsFailed',
                Cell: (x) => (
                    <span
                        onClick={() => {
                            showFailedList(x);
                        }}
                    >
                        {x.value}
                    </span>
                ),
            },
            {
                Header: 'itemsToRun',
                accessor: 'itemsToRun',
            },
            {
                Header: ' Error',
                accessor: 'error',
                Cell: (x) => (
                    <span
                        onClick={() => {
                            showDetails(x);
                        }}
                    >
                        {' '}
                        {(x.value || '-').substring(0, 50)}
                    </span>
                ),
            },
            {
                Header: 'Action',
                id: 'action',
                accessor: 'id',
                Cell: (x) => {
                    if (preparingReport || abandoningReport || executingReport)
                        return <CircularProgress />;

                    if (x.row.original.status === 0)
                        return (
                            <Button
                                variant={'contained'}
                                color={'primary'}
                                onClick={() => {
                                    runPrepare(x);
                                }}
                            >
                                Prepare
                            </Button>
                        );

                    if (x.row.original.status === 1)
                        return (
                            <Button
                                variant={'contained'}
                                color={'primary'}
                                onClick={() => {
                                    runExecute(x);
                                }}
                            >
                                Execute
                            </Button>
                        );

                    if (x.row.original.status === 2)
                        return (
                            <>
                                <Button
                                    variant={'contained'}
                                    color={'primary'}
                                    onClick={() => {
                                        runExecute(x);
                                    }}
                                >
                                    Continue
                                </Button>{' '}
                                <Button
                                    variant={'contained'}
                                    color={'primary'}
                                    onClick={() => {
                                        runAbandon(x);
                                    }}
                                >
                                    Abandon
                                </Button>
                            </>
                        );

                    if (x.row.original.status === 4)
                        return (
                            <>
                                <Button
                                    variant={'contained'}
                                    color={'primary'}
                                    onClick={() => {
                                        runExecute(x);
                                    }}
                                >
                                    Continue
                                </Button>
                                <Button
                                    variant={'contained'}
                                    color={'primary'}
                                    onClick={() => {
                                        runReset(x);
                                    }}
                                >
                                    Reset
                                </Button>
                                <Button
                                    variant={'contained'}
                                    color={'primary'}
                                    onClick={() => {
                                        reRunFailed(x);
                                    }}
                                >
                                    Re-run failed
                                </Button>
                            </>
                        );

                    if (x.row.original.status === 3)
                        return (
                            <>
                                <Button
                                    variant={'contained'}
                                    color={'primary'}
                                    onClick={() => {
                                        runReset(x);
                                    }}
                                >
                                    Reset
                                </Button>
                                {x.row.original.itemsFailed > 0 && (
                                    <Button
                                        variant={'contained'}
                                        color={'primary'}
                                        onClick={() => {
                                            reRunFailed(x);
                                        }}
                                    >
                                        Re-run failed
                                    </Button>
                                )}
                            </>
                        );

                    return (
                        <Button
                            variant={'contained'}
                            color={'primary'}
                            onClick={() => {
                                runReset(x);
                            }}
                        >
                            Reset
                        </Button>
                    );
                },
            },
        ],
        []
    );

    return (
        <Grid
            container
            direction="row"
            justify="center"
            align="center"
            spacing={2}
        >
            <Errormessage error={error} errorType={error} />
            <Grid item xs={12}>
                <Box mt={4}>
                    <Typography variant="h2">Service Tasks</Typography>
                </Box>
            </Grid>
            {loadingTasks ? (
                <ThemedSpinner />
            ) : (
                <Fragment>
                    <Tooltip
                        title="Create partner"
                        aria-label="add"
                        placement="left"
                    >
                        <Fab
                            color="primary"
                            href="/serviceTasks/create"
                            className={classes.fab}
                        >
                            <AddIcon />
                        </Fab>
                    </Tooltip>
                    {serviceTasks && serviceTasks.length > 0 && (
                        <Grid item xs={12}>
                            <SimpleTable
                                columns={columns}
                                data={serviceTasks}
                                needsFilter={true}
                            />
                        </Grid>
                    )}
                </Fragment>
            )}
        </Grid>
    );
}

export default observer(ServiceTasksList);
