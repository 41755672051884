import React from 'react';
import { useStores } from '../hooks/use-stores';
import { observer } from 'mobx-react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import { Helmet } from 'react-helmet';

function CheckEmail() {
    const { authStore } = useStores();
    const { extraMessage } = authStore;

    return (
        <Grid
            container
            direction="row"
            justify="center"
            align="center"
            spacing={2}
        >
            <Helmet>
                <title>Email is on its way</title>
            </Helmet>
            <Grid item xs={12}>
                <Box mt={4}>
                    <Typography variant="h2" gutterBottom>
                        Email is on its way!
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <video autoPlay muted>
                    <source
                        src="/static/img/icon-anim-email.mp4"
                        type="video/mp4"
                    />
                </video>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body1">
                    We've sent you an email with a login link. <br />
                    Please check your inbox.
                </Typography>
            </Grid>
            {extraMessage && (
                <Grid item xs={12}>
                    <Typography variant="body1">{extraMessage}</Typography>
                </Grid>
            )}
            <Grid item xs={12}>
                <Box mb={4}>
                    <Typography variant="body1">
                        Not receiving our email? <br />
                        <Link href="/auth/signin/">Resend email</Link>
                    </Typography>
                </Box>
            </Grid>
        </Grid>
    );
}

export default observer(CheckEmail);
