import React, { Fragment, useEffect } from 'react';
import { useStores } from '../../hooks/use-stores';
import CircularProgress from '@material-ui/core/CircularProgress';
import { observer } from 'mobx-react';
import { Card, CardHeader } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: {
        minWidth: 275,
        maxWidth: 375,
        minHeight: 200,
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
});

function Integrations() {
    const classes = useStyles();
    const { integrationsStore } = useStores();
    const { loadingIntegrations, integrations } = integrationsStore;
    useEffect(() => {
        integrationsStore.loadStats();
    }, []);

    return (
        <>
            {loadingIntegrations ? (
                <CircularProgress />
            ) : (
                <>
                    {integrations &&
                        Object.keys(integrations).map((integration) => (
                            <Card className={classes.root} key={integration}>
                                <CardHeader title={integration} />
                                Connected: {integrations[integration]}
                            </Card>
                        ))}
                </>
            )}
        </>
    );
}

export default observer(Integrations);
