import React, { useEffect } from 'react';
import { useStores } from '../../../hooks/use-stores';
import { observer } from 'mobx-react';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Clipboard from 'react-clipboard.js';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Input from '@material-ui/core/Input';

const useStyles = makeStyles(() => ({
    label: {
        fontSize: 12,
        fontWeight: 800,
        paddingRight: 2,
    },

    keyValue: {
        whiteSpace: 'nowrap',
        flexWrap: 'nowrap',
    },

    value: {
        fontSize: 12,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        fontFamily: 'monospace',
    },
    divider: {
        marginTop: 10,
        marginBottom: 10,
    },
}));

function renderProduct(classes, title, product) {
    if (!product) return null;
    return (
        <Grid container item xs={4} alignItems={'flex-start'}>
            <Grid container justify={'center'} item xs={12}>
                <Typography variant="h6">{title}</Typography>
            </Grid>
            <Grid container item xs={12}>
                <Typography variant="subtitle1">Current:</Typography>
            </Grid>
            <Grid container item xs={12} className={classes.keyValue}>
                <span className={classes.label}>Branch: </span>
                <span className={classes.value}>{product.currentBranch}</span>
            </Grid>
            <Grid container item xs={12} className={classes.keyValue}>
                <span className={classes.label}>Commit: </span>
                <a
                    className={classes.value}
                    target="_blank"  rel="noreferrer"
                    href={`https://bitbucket.org/awarego/awarego-my/commits/${product.currentCommit}`}
                >
                    {product.currentCommit}
                </a>
            </Grid>
            <Grid container item xs={12} className={classes.keyValue}>
                <span className={classes.label}>Description: </span>
                <span className={classes.value}>
                    {product.currentDescription}
                </span>
            </Grid>
            <Grid item xs={12}>
                <Divider className={classes.divider} />
            </Grid>
            <Grid item xs={12}>
                <FormControl className={classes.formControl} fullWidth>
                    <InputLabel shrink>Branch</InputLabel>
                    <Select
                        fullWidth
                        label={'Branch'}
                        value={product.selectedBranch}
                        onChange={(e) => {
                            product.setBranch(e.target.value);
                        }}
                    >
                        {product.branches.map((x) => (
                            <MenuItem key={x} value={x}>
                                {x}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            {product.selectedBranch && (
                <Grid item xs={12}>
                    <FormControl className={classes.formControl} fullWidth>
                        <InputLabel shrink>Commit</InputLabel>

                        <Select
                            fullWidth
                            label={'Commit'}
                            value={product.selectedCommit}
                            onChange={(e) => {
                                product.setCommit(e.target.value);
                            }}
                        >
                            {product.buildsByBranch.map((x) => (
                                <MenuItem key={x.commit} value={x.commit}>
                                    {x.commit.substr(0, 10)} - {x.description}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            )}

            {product.deployCommand && (
                <Grid item xs={12}>
                    <FormControl fullWidth variant={'outlined'}>
                        <InputLabel htmlFor="standard-adornment-password">
                            Password
                        </InputLabel>
                        <Input
                            id="standard-adornment-password"
                            type="text"
                            value={product.deployCommand}
                            endAdornment={
                                <InputAdornment position="end">
                                    <Clipboard
                                        className="copy-to-clipboard-link"
                                        data-clipboard-text={
                                            product.deployCommand
                                        }
                                    >
                                        <IconButton aria-label="toggle password visibility">
                                            <FileCopyIcon />
                                        </IconButton>
                                    </Clipboard>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                </Grid>
            )}
        </Grid>
    );
}

function Builds() {
    const { buildsStore } = useStores();
    const classes = useStyles();
    const {  error, my, lms, api } = buildsStore;

    useEffect(() => {
        buildsStore.loadBuilds();
    }, []);

    return (
        <Grid container direction="row" spacing={2}>
            <Grid container item xs={12}>
                {' '}
                {error && (
                    <Alert variant={'filled'} color={'error'}>
                        {error}
                    </Alert>
                )}
            </Grid>
            <Grid container justify={'center'} item xs={12}>
                <Typography variant="h5">Beta Builds</Typography>
            </Grid>

            {renderProduct(classes, 'MY', my)}
            {renderProduct(classes, 'LMS', lms)}
            {renderProduct(classes, 'API', api)}
        </Grid>
    );
}

export default observer(Builds);
