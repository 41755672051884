import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import Grid from '@material-ui/core/Grid';
import { useStores } from '../../hooks/use-stores';
import { Link } from 'react-router-dom';
import ThemedSpinner from '../../components/themed/ThemedSpinner';
import SimpleTable from '../../components/super/SimpleTable';

function Stats() {
    const { superStore } = useStores();
    const { loadingPartnerStats, partnerStats } = superStore;

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        superStore.loadPartnerSeatsData();
    };

    const columns = React.useMemo(
        () => [
            {
                Header: 'Partner name',
                accessor: 'account_name',
                Cell: (x) => (
                    <Link to={`/partners/${x.row.original.account_id}`}>
                        {x.value}
                    </Link>
                ),
            },
            {
                Header: 'Name',
                accessor: 'name',
                Cell: (x) => (
                    <Link to={`/companies/${x.row.original.company_id}`}>
                        {x.value}
                    </Link>
                ),
            },
            {
                Header: 'Seats',
                accessor: 'available_users',
            },
            {
                Header: 'Seats used',
                accessor: 'actual_users',
            },
        ],
        []
    );

    return (
        <Grid
            container
            direction="row"
            justify="center"
            align="center"
            spacing={2}
        >
            {loadingPartnerStats && partnerStats && partnerStats.length > 0 ? (
                <ThemedSpinner />
            ) : (
                <Grid item xs={12}>
                    <SimpleTable columns={columns} data={partnerStats} />
                </Grid>
            )}
        </Grid>
    );
}

export default observer(Stats);
