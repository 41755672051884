import RestService from './RestService';
import ScheduleService from './ScheduleService';

export default class Automations extends RestService {
    constructor(parentService, parentId) {
        if (parentService && parentId) {
            super(`${parentService.baseUrl}/${parentId}/automations`);
        } else super('/automations');
    }

    eventStatus(automationId, eventId) {
        return this.get(`${this.baseUrl}/${automationId}/status/${eventId}`);
    }

    eventUserStatus(automationId, eventId, email) {
        return this.get(
            `${
                this.baseUrl
            }/${automationId}/status/${eventId}/user/${encodeURIComponent(
                email
            )}`
        );
    }

    userStatus(automationId, email) {
        return this.get(
            `${this.baseUrl}/${automationId}/status2/user/${encodeURIComponent(
                email
            )}`
        );
    }

    ScheduleService(automationId) {
        return new ScheduleService(this, automationId);
    }

    resendEventMail(automationId, eventId, email) {
        return this.post(
            `${this.baseUrl}/${automationId}/status/${eventId}/resend`,
            { email }
        );
    }

    loadingRemindersCounter(automationId, reminderOption) {
        return this.post(`${this.baseUrl}/${automationId}/count-reminders`, {
            reminderOption,
        });
    }

    sendReminders(automationId, reminderOption) {
        return this.post(`${this.baseUrl}/${automationId}/reminders`, {
            reminderOption,
        });
    }

    sendPreview(data) {
        return this.post(`${this.baseUrl}/sendPreview`, data);
    }

    resumeAutomation(automationId) {
        return this.post(`${this.baseUrl}/${automationId}/resumeAutomation`);
    }

    loadUsersDetails(automationId) {
        return this.get(`${this.baseUrl}/${automationId}/perUsersDetails`);
    }

    pauseAutomation(automationId) {
        return this.post(`${this.baseUrl}/${automationId}/pauseAutomation`);
    }

    updateStandards(trainingId, standards) {
        return this.put(`${this.baseUrl}/${trainingId}/standards`, standards);
    }
}
