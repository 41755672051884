import RestService from './RestService';

export default class Resources extends RestService {
    constructor() {
        super(import.meta.env.REACT_APP_RESOURCES_URL);
    }

    posters() {
        return this.get(`${this.baseUrl}/posters.json`);
    }

    addLogo(companyId, formData) {
        const result = this.put('companies/' + companyId + '/logo', formData, {
            headers: {
                ...this.commonHeaders().headers,
                'Content-Type': 'multipart/form-data',
            },
        });
        return result;
    }

    removeLogo(companyId) {
        return this.post('companies/' + companyId + '/remove_logo');
    }
}
