import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import services from '../../services';
import { useParams } from 'react-router';
import ErrorIcon from '@material-ui/icons/Error';
import Table from '../../components/super/Table';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { useStores } from '../../hooks/use-stores';
import CircularProgress from '@material-ui/core/CircularProgress';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { observer } from 'mobx-react';

function NotificationTargets() {
    const { reasonId } = useParams();
    const [modalError, setModalError] = useState(null);
    const [showingContext, setShowingContext] = useState(false);
    const { superStore, commonStore, notificationsStore } = useStores();
    const { loadingReason, reason } = notificationsStore;
    const reSendNotification = (record) => {
        commonStore.showConfirm(
            `Resent notification to  ${record.email}? `,
            'Resend',
            '',
            async () => {
                let result = await superStore.resendNotification(
                    record.reason_id,
                    record.id
                );
                Object.assign(record, result);
            }
        );
    };
    const columns = React.useMemo(
        () => [
            {
                Header: 'Created',
                accessor: (x) => {
                    return (
                        <div className="timespan">
                            {x.failed ? (
                                <>
                                    <ErrorIcon
                                        className={'error-mark'}
                                        onClick={() => {
                                            setModalError(x);
                                        }}
                                    />
                                </>
                            ) : null}
                            {moment(x.created).format('llll')}
                        </div>
                    );
                },
            },
            {
                Header: 'Status',
                accessor: (x) => {
                    if (x.failed) return 'Failed';
                    switch (x.status) {
                        case 0:
                            return 'Cancelled';
                        case 1:
                            return 'Planned';
                        case 2:
                            return 'Sent';
                    }
                },
            },
            {
                Header: 'Email',
                accessor: 'email',
            },
            {
                Header: 'Attempts',
                accessor: 'attempts',
            },
            {
                Header: 'Mailgun id',
                accessor: 'mailgun_id',
            },
            {
                Header: 'Delivery Code',
                accessor: 'deliveryCode',
            },
            {
                Header: 'Delivered',
                accessor: (x) => {
                    return (
                        x.deliveryDate && moment(x.deliveryDate).format('llll')
                    );
                },
            },
            {
                Header: 'Opened',
                accessor: (x) => {
                    return x.openDate && moment(x.openDate).format('llll');
                },
            },
            {
                Header: '',
                id: 'actions',
                accessor: (x) => {
                    return x.failed ? (
                        <Button
                            color={'primary'}
                            onClick={() => {
                                reSendNotification(x);
                            }}
                        >
                            Resend
                        </Button>
                    ) : null;
                },
            },
        ],
        []
    );

    useEffect(() => {
        if (reasonId) notificationsStore.loadReason(reasonId);
    }, [reasonId]);

    const showContext = () => {
        setShowingContext(true);
    };
    const hideContext = () => {
        setShowingContext(false);
    };

    const updateStatus = useCallback(
        (newStatus) => {
            commonStore.showConfirm(
                'Switch status to ' + newStatus,
                'Change',
                'Switch status',
                async () => {
                    notificationsStore.update(reasonId, { status: newStatus });
                }
            );
        },
        [reasonId]
    );

    // We'll start our table without any data
    const [data, setData] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [pageCount, setPageCount] = React.useState(0);
    const fetchIdRef = React.useRef(0);

    const fetchData = React.useCallback(async ({ pageSize, pageIndex }) => {
        // This will get called when the table needs new data
        // You could fetch your data from literally anywhere,
        // even a server. But for this example, we'll just fake it.

        // Give this fetch an ID
        const fetchId = ++fetchIdRef.current;
        setLoading(true);

        let result = await services.Notifications.targetsService(reasonId).list(
            {
                p: pageIndex,
                n: pageSize,
                s: null,
                f: null,
                fetchId,
            }
        );

        if (fetchId === fetchIdRef.current) {
            setData(result.rows);

            setPageCount(result.pages);

            setLoading(false);
        }
    }, []);

    return (
        <>
            <Grid
                container
                direction="row"
                justify="center"
                align="center"
                spacing={2}
            >
                <Grid item xs={12}>
                    <Box mt={4}>
                        <Typography variant="h2">
                            Notification detail
                        </Typography>
                    </Box>
                </Grid>

                {loadingReason ? (
                    <CircularProgress />
                ) : (
                    reason && (
                        <Grid container item xs={12}>
                            <Grid item xs={4}>
                                Status:
                                <ButtonGroup
                                    color="primary"
                                    variant={'contained'}
                                >
                                    <Button
                                        disabled={reason.status === 0}
                                        color={
                                            reason.status === 0
                                                ? 'secondary'
                                                : 'primary'
                                        }
                                        onClick={() => {
                                            updateStatus(0);
                                        }}
                                    >
                                        Disabled
                                    </Button>
                                    <Button
                                        disabled={reason.status === 1}
                                        color={
                                            reason.status === 1
                                                ? 'secondary'
                                                : 'primary'
                                        }
                                        onClick={() => {
                                            updateStatus(1);
                                        }}
                                    >
                                        Planned
                                    </Button>
                                    <Button
                                        disabled={reason.status === 2}
                                        color={
                                            reason.status === 2
                                                ? 'secondary'
                                                : 'primary'
                                        }
                                        onClick={() => {
                                            updateStatus(2);
                                        }}
                                    >
                                        Processed
                                    </Button>
                                </ButtonGroup>
                            </Grid>

                            <Grid item xs={4}>
                                {showingContext ? (
                                    <>
                                        <Button
                                            color="primary"
                                            variant={'contained'}
                                            onClick={hideContext}
                                        >
                                            Hide Context
                                        </Button>
                                        <pre style={{ textAlign: 'left' }}>
                                            {JSON.stringify(
                                                reason.contextJson,
                                                null,
                                                2
                                            )}
                                        </pre>
                                    </>
                                ) : (
                                    <Button
                                        color="primary"
                                        variant={'contained'}
                                        onClick={showContext}
                                    >
                                        Show Context
                                    </Button>
                                )}
                            </Grid>
                            <Grid item xs={4}>
                                Reason: {reason.reason}
                            </Grid>
                        </Grid>
                    )
                )}
                <Grid item xs={12}>
                    <Table
                        columns={columns}
                        data={data}
                        fetchData={fetchData}
                        loading={loading}
                        pageCount={pageCount}
                    />
                </Grid>
            </Grid>
            {modalError && (
                <Dialog
                    open={modalError != null}
                    onClose={() => setModalError(null)}
                >
                    <DialogTitle>
                        Error happens when sent to {modalError.email}
                    </DialogTitle>
                    <DialogContent>
                        <pre>{modalError.last_error}</pre>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setModalError(null)}>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
}

export default observer(NotificationTargets);
