import React from 'react';
import { Alert } from 'reactstrap';

function Snackbar({
    anchorOrigin = { vertical: 'top', horizontal: 'center' },
    variant,
    message,
    open,
    onClose,
}) {
    const variant2color = (variant) => {
        if (variant === 'error') {
            return 'danger';
        } else {
            return variant;
        }
    };

    return (
        <div
            className={`
                snackbar 
                snackbar-${anchorOrigin.horizontal}-${anchorOrigin.vertical}
                `}
        >
            <Alert
                className={`snackbar-content`}
                color={variant2color(variant)}
                isOpen={open}
                toggle={onClose}
            >
                {message}
            </Alert>
        </div>
    );
}

export default Snackbar;
